import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Slider, InputNumber } from "antd";

const [rule_min, rule_max] = [-20, 30];
export const [def_min, def_max] = [4, 15];

const marks = {
  [rule_min]: {
    style: { color: "#05f" },
    label: <strong>{rule_min}°C</strong>
  }, 
/*
  "0": {
    style: { color: "#05f" },
    label: <strong>0°C</strong>
  },
*/
  [rule_max]: {
    style: { color: "#f50" },
    label: <strong>{rule_max}°C</strong>
  }
};

export const int4range = (v) => {
 //debugger;
 let ret = [null,null], p;
 if (typeof v === 'string' || v instanceof String ) {
  p = v.match(/([\[\(])([0-9,\-]*)([\]\)])/);
  if (p?.length === 4) {
    ret = p[2].split(',').map((v) => parseInt(v));
    // Exclusive Bounds ?
    if (p[1] === '(') ret[0]--;
    if (p[3] === ')') ret[1]--;
  }
 }
 else if (typeof v === 'object' || v instanceof Object ) {
  ret[0] = v.min; ret[1] = v.max
 }
 //console.log('int4range: ret: ', ret);
 let o = {min: ret[0], max: ret[1]};
 //console.log('int4range: o: ', o);
 return o;
}

//const TemperatureRange = ({ value = {}, onChange }) => {
const TemperatureRange = ({ value, onChange }) => {
  const [raw, setRaw] = useState(value);
  const [state, setState] = useState({min: def_min, max: def_max});

  useEffect(() => {
   //console.log("TemperatureRange.useEffect: ", value);
   if (value) {
    let s = int4range(value);
    setState(s);
   }
  }, [value]);

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };
  
  const handleChange = (value) => {
    console.log("onChange: ", value);
    if (value[0] < value[1]) {
      setState({ min: value[0], max: value[1] });
      triggerChange(
        //{number: newNumber,}
        // value: how use array in hasura?
       `[${value}]`
      );
    }
  };

  const onChangeMin = (value) => {
    if (state.max > value) {
      setState((s) => ({ max: s.max, min: value }));
    }
  };

  const onChangeMax = (value) => {
    if (state.min < value) {
      setState((s) => ({ max: value, min: s.min }));
    }
  };

  return (
    <>
{/*
      <p>raw: {raw}, state: [{state.min}, {state.max}]</p>
      <button>{formattedText}</button>
*/}
      <Slider
        range={true}
        marks={marks}
        className="slider-main-div"
        min={rule_min}
        max={rule_max}
        onChange={handleChange}
        _defaultValue={[state.min, state.max]}
        value={[state.min, state.max]}
      />
      <div className="range-input-number-main">
        <InputNumber
          className="min-input-main"
          min={rule_min}
          max={rule_max}
          value={state.min}
          onChange={onChangeMin}
        />
        <span className="range-span"> to </span>
        <InputNumber
          className="min-input-main"
          min={rule_min}
          max={rule_max}
          value={state.max}
          onChange={onChangeMax}
        />
      </div>
    </>
  );
};

export default TemperatureRange;

