import React from "react";
import { message, Popconfirm, Button, Tooltip } from "antd";
import { gql, useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";

const TransactionUnlinkBtn = ({disabled, ...props}) => {
  const [unlink_transaction] = useMutation(UNLINK_TRANSACTION, {
    refetchQueries: ["Transactions"],
    //onCompleted: () => closeForm(),
    onError: (e) => processError(e)
  });
  const perms = {...(disabled && { disabled })};

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         // could analyze constrain name:
	 // "users_company_id_fkey"
	 // "warehouses_company_id_fkey"
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
	  if (error.message.includes("users_company_id_fkey"))
          	message.error('Company has attached users');
	  else if (error.message.includes("warehouses_company_id_fkey"))
          	message.error('Company has attached warehouses');
	  else
         	message.error('Deletion is impossible due to the availability of information about the company\'s actions');

         } else
         	message.error('Server error, contact your administrator');
        });
  }

  // const action = props.type_transaction == "IGN"?"clear":"unlink";
  const action = ['IGN', 'CNV'].includes(props.type_transaction) ? "clear" : "unlink";

  return (
    <Popconfirm
      title={"Are you sure "+action+" this transaction?"}
      onConfirm={e => {
        e.preventDefault();
        unlink_transaction({ variables: { id: props.id } });
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
     <Tooltip placement="left" mouseLeaveDelay="0" title={action.charAt(0).toUpperCase() + action.slice(1)+" this transaction"} >
        <Button
          {...props}
          type="link"
          _size="small"
          _danger="true"
          icon={<DeleteOutlined />}
        />
      </Tooltip>

    </Popconfirm>
  );
};

const UNLINK_TRANSACTION = gql`
  mutation UpdateTransaction($id: uuid!) {
    update_p_transactions_by_pk (
      pk_columns: {id: $id}
      _set: { initiator_id: null, type_transaction: null, link_id: null, linked_user: null, linked_at: null  }
    ) {
      id
    },
    delete_p_transactions (
      where: { link_id: { _eq: $id } }
    ) {
     affected_rows
    }
  }
`;

export default TransactionUnlinkBtn;
