import { useStoreon } from "storeon/react";

// hardcoded due public (florunner) and schema name (primeflower) difference
const tenant_prefix = "primeflower";
const tenant_default = "florunner";

const tenant_tables = [
"^bot_queue.*",
"^companies.*",  
"^p_accounts.*",
"^p_exchange.+", 
"p_synchronizations",
"^p_transactions.*"
];

// if true, we must add new operations in list: 
// useMutation options refetchQueries: []
const RENAME_OPERATIONS = false;

//// ADD TENANT ///////////////////////////////////////////////////////////////
export const operation_add_tenant = (operation, tenant) => {
//debugger;
  if (operation.query) {
   console.log(`mockLink: QUERY [add][${operation.operationName}] :`, JSON.stringify(operation));
   var found = false;
   operation.query.definitions.forEach((d) => {
    // j1.query.definitions[0].typeCondition.name;

//// ADD TENANT ////
    if (d.kind === "FragmentDefinition" && d.typeCondition) {
      const s = d.typeCondition;
      const table = {...s.name}; 
      if (tenant_tables.some((t) => t.charAt(0) === '^'? table.value.match(t) : table.value === t)) {
          console.log(`mockLink: [add] FragmentDefinition [${operation.operationName}] :`, JSON.stringify(operation));
          console.log(`mockLink: try to change [${table.value}] => [${tenant_prefix+"_"+table.value}]`);
          s.name.value = tenant_prefix+"_"+table.value;
          found = true;
      }
    } 
    else
//// ADD TENANT ////
    if (d.kind === "OperationDefinition") { 
      if (d.variableDefinitions) {
        // j1.query.definitions[0].variableDefinitions[1].type.type.name
        // p_transactions_rules_insert_input
        d.variableDefinitions.forEach((v) => {
          // save original (not ref)
          let p = v;
          while(p && p.type && !p.name) p = p.type; //kind: "Name"?
          console.log('mockLink: [add] mutation, variableDefinitions', p.name);
          const type = {...p.name}; 
          // 2023.12.16
          // const tab_prefix = type.value.match(/^(.*)_(set|insert)_input$/);
          const tab_prefix = type.value.match(/^(.*)_((set|insert)_input|bool_exp)$/);
  
          if (tab_prefix) {           
            const table = { value: tab_prefix[1] }; 
  
            if (tenant_tables.some((t) => t.charAt(0) === '^'? table.value.match(t) : table.value === t)) {
              console.log(`mockLink: UPDATE, try to change [${type.value}] => [${tenant_prefix+"_"+type.value}]`);
              p.name.value = tenant_prefix+"_"+type.value;
  
              found = true;  
            }
          }
        });
      } 

//// ADD TENANT ////
     if (d.operation === "mutation" && d.selectionSet) {

      d.selectionSet.selections.forEach((s) => {
        console.log('mockLink: [add] mutation, selectionSet.selections', s.name);
        // save original (not ref)
        const type = {...s.name};
//        const tab_prefix = type.value.match(/^update_(.*)$/);
        const tab_prefix = type.value.match(/^(update|insert|delete)_(.*?)(_by_pk){0,1}$/);

        if (tab_prefix) {
          const table = { value: tab_prefix[2] }; // first match from regexp if any  
          const op = tab_prefix[1];

          if (tenant_tables.some((t) => t.charAt(0) === '^'? table.value.match(t) : table.value === t)) {

            if (tab_prefix[3]) table.value += tab_prefix[3]; 
            console.log(`mockLink: UPDATE, try to change [${table.value}] => [${op}_${tenant_prefix+"_"+table.value}]`);
            s.name.value = op+"_"+tenant_prefix+"_"+table.value;

//            if (!s.alias) {
//              s.alias = { kind: "Name", value: table.value };
//            }
            found = true;
         }
       }
      });
    } else
//// ADD TENANT ////
    if (d.operation === "query" && d.selectionSet) {
      d.selectionSet.selections.forEach((s) => {
        console.log('mockLink: [add] query, selectionSet.selections', s.name);
        // save original (not ref)
        const table = {...s.name}; 
        if (tenant_tables.some((t) => t.charAt(0) === '^'? table.value.match(t) : table.value === t)) {
          console.log(`mockLink: QUERY, try to change [${table.value}] => [${tenant_prefix+"_"+table.value}]`);
          s.name.value = tenant_prefix+"_"+table.value;
          if (!s.alias) {
            s.alias = { kind: "Name", value: table.value };
          }
          found = true;
       }
      });
    }
//// ADD TENANT ////
   if (found) { 
     console.log("mockLink: NEW QUERY:", JSON.stringify(operation));
     // cache.reset() does not release ROOT
     if (RENAME_OPERATIONS) {
       operation.operationName += "_"+tenant_prefix;
       operation.query.definitions.forEach((d) => {
         if (d.kind == "OperationDefinition") {
           d.name.value += "_"+tenant_prefix;
         }
       });
     } 
    }
   }
  });
 }
}

//// DELETE TENANT ////////////////////////////////////////////////////////////
export const operation_del_tenant = (operation, tenant) => {
//debugger;
  if (operation.query) {
   console.log(`mockLink: QUERY [del][${operation.operationName}] :`, JSON.stringify(operation));
   var found = false;
   operation.query.definitions.forEach((d) => {
    // j1.query.definitions[0].typeCondition.name;

//// DELETE TENANT ////
    if (d.kind === "FragmentDefinition" && d.typeCondition) {
      const s = d.typeCondition;
      const table = {...s.name}; 
      if (table.value.substr(0,tenant.length+1) == tenant+"_") {
        console.log(`mockLink: [del] FragmentDefinition [${operation.operationName}] :`, JSON.stringify(operation));
        s.name.value = table.value.substr(tenant.length+1);
        found = true;
      }
    } 
    else
//// DELETE TENANT ////          
    if (d.kind === "OperationDefinition") {
      if (d.variableDefinitions) {
        // j1.query.definitions[0].variableDefinitions[1].type.type.name
        d.variableDefinitions.forEach((v) => {
          // save original (not ref)
          let p = v;
          while(p && p.type && !p.name) p = p.type; //kind: "Name"?
          console.log('mockLink: [del] mutation, variableDefinitions', p.name);
          const type = {...p.name}; 

          // 2023.12.16
          // const tab_prefix = type.value.match(/^(.*)_(set|insert)_input$/); //{0,1}?
          // const tab_prefix = type.value.match(/^(.*?)(_set_input|_insert_input)$/); // last 
          const tab_prefix = type.value.match(/^(.*)_((set|insert)_input|bool_exp)$/);

          if (tab_prefix) {
            const table = { value: tab_prefix[1] }; // first match from regexp if any  
            if (table.value.substr(0,tenant.length+1) == tenant+"_") {           
              p.name.value = table.value.substr(tenant.length+1);
              if (tab_prefix[2]) p.name.value += '_' + tab_prefix[2]; 
              found = true;
            }
          }
        });
      }
//// DELETE TENANT ////
      // 4ex: "update_primeflower_p_transactions_by_pk"
      // j1.query.definitions[0].selectionSet.selections[0].name;
      // update_companies
  if (d.operation === "mutation" && d.selectionSet) {

      d.selectionSet.selections.forEach((s) => {
        console.log('mockLink: [del] mutation, selectionSet.selections', s.name);
        // save original (not ref)
        const type = {...s.name};
        const tab_prefix = type.value.match(/^(update|insert|delete)_(.*?)(_by_pk){0,1}$/);

        if (tab_prefix) {
          const table = { value: tab_prefix[2] }; // first match from regexp if any  
          if (table.value.substr(0,tenant.length+1) == tenant+"_") {           
            s.name.value = tab_prefix[1]+"_"+table.value.substr(tenant.length+1);
            if (tab_prefix[3]) s.name.value += tab_prefix[3]; 
            found = true;
          }
       }

      });
    }
    else
//// DELETE TENANT ////
    if (d.operation === "query" && d.selectionSet) {
      d.selectionSet.selections.forEach((s) => {
        console.log('mockLink: [del] query, selectionSet.selections', s.name);
        // save original (not ref)
        const table = {...s.name};
        if (table.value.substr(0,tenant.length+1) == tenant+"_") {
          s.name.value = table.value.substr(tenant.length+1);
          found = true;
        }
      });
    }
//// DELETE TENANT ////
   if (found) { 
     console.log("mockLink: NEW QUERY:", JSON.stringify(operation));
     // cache.reset() does not release ROOT
     if (RENAME_OPERATIONS) {
       operation.operationName += "_"+tenant_prefix;
       operation.query.definitions.forEach((d) => {
         if (d.kind == "OperationDefinition") {
           d.name.value += "_"+tenant_prefix;
         }
      });
     }
    }
   }
  });
 }
}

// TODO: [tenant - currency] move to config?
export const get_currency_list_by_tenant = (tenant) => {
  // same order as CURRENCY at CurrencyRates.js
  const currency_base = ['EUR', 'USD'];
  const currency_extra = tenant === tenant_prefix?  ['HUF'] : ['CNY', 'AED'];
  return [...currency_base, ... currency_extra];
}
