import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Drawer, Alert, Button } from "antd";
import { Col, Row, Statistic, Collapse, Table } from 'antd';

import useFetch from "../utils/utils";

const yesno = (v) => <>{ (v? 'yes': 'no') }</>;

const columns = [
  {
    title: 'IBAN',
    dataIndex: 'iban',
    key: 'iban',
    render: (text) => <i>{text}</i>,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: 'Account',
    dataIndex: 'accounts',
    key: 'accounts',
    render: yesno,
  },
  {
    title: 'Balance',
    dataIndex: 'balances',
    key: 'balances',
    render: yesno,
  },
  {
    title: 'Transactions',
    dataIndex: 'transactions',
    key: 'Transactions',
    render: yesno,
  },
];
 
const ConsentStatus = ({id, ...otherProps}) => {
  console.log('ConsentStatus: props:', otherProps);

  const api_url = process.env.REACT_APP_SERVER_URL + "/auth/raiffeisen/status?consent_id="+id;

  console.log('ConsentStatus: api: ', api_url);
  const [consentLoading, consentError, consentData] = useFetch( api_url, "GET");

  if (consentLoading) return 'loading ...';
  if (consentError) return `error ... [${consentError}]`;

  const data = consentData.data;
  const data_access = [];

//  data.access.accounts.forEach((a) => data_access.push({...a, accounts: true }));
//  data.access.balances.forEach((a) => data_access.push({...a, balances: true }));
//  data.access.transactions.forEach((a) => data_access.push({...a, transactions: true }));

//  data.access.accounts.forEach((a) => data_access[a.iban] = {...a, accounts: true });
//  data.access.balances.forEach((a) => data_access[a.iban] = {...a, balances: true });
//  data.access.transactions.forEach((a) => data_access[a.iban] = {...a, transactions: true });

  // group by acounts 
  data.access.accounts.forEach((a) => data_access.push({...a, accounts: true }));
  data.access.balances.forEach((a) => {
   const o = {...a, balances: true };
   const i = data_access.findIndex((b) => b.iban === a.iban);
   if (i !== -1) data_access[i] = {...data_access[i], ...o}; else data_access.push(o)
  });
  data.access.transactions.forEach((a) => {
   const o = {...a, transactions: true };
   const i = data_access.findIndex((b) => b.iban === a.iban);
   if (i !== -1) data_access[i] = {...data_access[i], ...o}; else data_access.push(o)
  });
  console.log('data_access:', data_access);

 return (
 <>
 <br/>Current consent [{id}]
 <Row gutter={16}>
    <Col span={12}>
      <Statistic title="Recurring" value={data.recurringIndicator} />
    </Col>
    <Col span={12}>
      <Statistic
          title="Valid until" 
          value={data.validUntil}
          valueStyle={{ color: data.consentStatus === 'valid'? '#3f8600' : 'red' }}
      />
    </Col>
    <Col span={12}>
      <Statistic title="Frequency per day" value={data.frequencyPerDay} />
    </Col>
    <Col span={12}>
      <Statistic title="Last action date" value={data.lastActionDate} />
    </Col>
    <Col span={12}>
      <Statistic title="Status" value={data.consentStatus} valueStyle={{ color: data.consentStatus === 'valid'? '#3f8600' : 'red' }} />
    </Col>
  </Row>
  <Row>
    <Col>
      <Table columns={columns} dataSource={data_access} />
    </Col>
  </Row>
  </>
  );
 
};

export default ConsentStatus;
