import React, { Component } from "react";
import { Select } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const Option = Select.Option;

const Counterparts = (props) =>  {

  console.log("Counterparts.props: ", props);

  // condition": { type_transaction: type_transaction? {_eq: type_transaction} : {}, ...
  // val: 2023.07.18: for Counterparts type_transaction == "OUT" always
  const { currency, initiator_id, dat1, dat2, ...otherProps } = props;

  // create $condition: p_transactions_bool_exp
  const where = {condition: {
    type_transaction: { _eq: "OUT"},
    initiator_id: initiator_id? {_eq: initiator_id} : {},
    currency: currency && ['ALL', 'MIX'].indexOf(currency) === -1 ? {_eq: currency} : {},
  }}; 
  if (dat1 && dat2) 
   where.condition._and = [
    {created_at: {_gt: dat1}},
    {created_at: {_lte: dat2}}   
   ]
  else if (dat1) 
    where.condition.created_at = {_gt: dat1};
  else
    where.condition.created_at = {_lte: dat2};

  /////////////////////////////////////////////

  console.log("Counterparts.where: ", where);

  const { loading, error, data } = useQuery(GET_COUNTERPARTS, {
    variables: where
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  console.log("return ...");
    return (
            <Select {...props}>
              {data.counterparts.map((item, key) => {
                return (
                  <Option key={key} value={item.counterpart}>
                    {item.counterpart}
                  </Option>
                );
              })}
            </Select>
    );
}

const GET_COUNTERPARTS = gql`
query Counterparts ($condition: p_transactions_bool_exp!) {
  counterparts: p_transactions (
    where: $condition, 
    order_by: {counterpart_calc: asc},
    distinct_on: counterpart_calc) 
  {
    counterpart: counterpart_calc
  }
}`;

export default Counterparts;
