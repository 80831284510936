import React, { useState, useContext } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { Input, Tag, Space, Table, Tabs } from "antd";
import FormBtn from "../shared/FormBtn";
import DeleteCompanyBtn from "./CompanyDeleteBtn";
import OperCompanyBtn from "./CompanyOperBtn";
import WarehousesTable from "../warehouses/WarehousesTable";
import UsersTable from "../users/UsersTable";
import { FilesIcon } from "../shared/FilesIcon";
import { isSupplier, isAdmin } from "../../auth/authHelper";

// import { ApolloProvider2 } from "../../ApolloProvider2";

const TabPane = Tabs.TabPane;

const columns = [
  {
    title: "Role",
    dataIndex: "role",
    width: "100px"
  },
  {
    title: "Files",
    width: "3%",
    render: record => <FilesIcon id={record.id} dataModel="company" count={record.files.aggregate.count}/>
  },
  {
    title: "Name",
    //dataIndex: "name",
    render: (rec) =>
<>
<Space>    
{rec.name}
{rec.users_aggregate?.aggregate?.count > 0 &&
 <Tag color="#2db7f5">users {rec.users_aggregate?.aggregate?.count}</Tag>
}
{rec.warehouses_aggregate?.aggregate?.count > 0 &&
<Tag color="#87d068">warehouses {rec.warehouses_aggregate?.aggregate?.count}</Tag>
}
</Space>
</>
  },
/*
 * TODO: remove
  {
    dataIndex: ["users_aggregate", "aggregate", "count"]
  },
  {
    dataIndex: ["warehouses_aggregate", "aggregate", "count"]
  },
*/
  {
    title: "Country",
    dataIndex: ["country", "name"]
  },
  {
    title: "Status",
    dataIndex: "active",
    render: (status) => <>{!status?"not active":""}</>
  }
];

const CompaniesTable = () => {
  const [filter, setFilter] = useState('');
  const { dispatch, auth } = useStoreon("selection", "auth");

/*
  const client2 = useContext(ApolloProvider2);
  const { tab } = useStoreon("tab");
  const opts = tab.tenant === 'primeflower'? { client: client2 } : {};
  alert('tab.tenant:'+tab.tenant+" opts:"+(opts.client?'yes':'no'));
*/
  const opts = {};

  const { loading, error, data } = useQuery(GET_COMPANIES, opts);

/*
  useEffect(() => {
    debugger;
    console.log("CompaniesTable: useEffect, data: ", data);
  }, [data]);
*/

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const filteredData = filter
     ? data.companies.filter((company) =>
        company
        ? company.name.toLowerCase().includes(filter.toLowerCase()) ||
          company.short_name.toLowerCase().includes(filter.toLowerCase())
        : false,
     )
     :data.companies;

  const doSearch = (value) => {
        setFilter(value);
        // refetch();
  };

  const perms = !isAdmin()? {disabled: true} : {};
  // Input.Search
  return (
    <div>
      {isAdmin() &&
      <Row type="flex" justify="start" gutter={8}>
        <Col span={2}>
          <FormBtn component="company" action="add" />
        </Col>
        <Col span={3}>
                  <Input
                    placeholder="search by name ..."
                    value={filter}
                    onChange={(event) => doSearch(event.target.value)}
                  />
        </Col>
        <Col span={19} />
      </Row>
      }
      <Row>
        <Col span={24}>
          <Table
            pagination={isAdmin() && filteredData?.length > 1}
            rowKey="id"
            columns={columns}
            //dataSource={data && data.companies}
            dataSource={filteredData}
            size="small"
            rowClassName={(record) => record.active ? '' : 'not-active-row' }
            onExpand={(_, r) => {
              dispatch("select", { model: "company", id: r.id });
            }}
            expandedRowRender={record => (
              <Tabs defaultActiveKey="1" tabPosition="left">
                <TabPane tab="Company" key="1">
                  <h2> {record.role}</h2>
                  <p> {record.name}</p>
                  <p>{`${record.street || ""} ${record.house_no || ""}`}</p>
                  <p>{`${record.post_code || ""} ${record.city || ""}`}</p>
                  <p> {`${record.country?.name||""}`}</p>
                  <Space>
                  <FormBtn component="company" action="edit" />
                  <DeleteCompanyBtn id={record.id} /*style={{ float: "right" }}*/ {...perms}/>
                  <OperCompanyBtn operation="active" id={record.id} defaultChecked={record.active} active={record.active} {...perms}/>
                  </Space>
                </TabPane>
                <TabPane tab="Warehouses" key="2">
                  <div>
                    <WarehousesTable company_id={record.id}/>
                  </div>
                </TabPane>
                <TabPane tab="Users" key="3">
                  <UsersTable company_id={record.id}/>
                </TabPane>
              </Tabs>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

const GET_COMPANIES = gql`
  query Companies {
    companies: companies_view (
	order_by: [{ name: asc }]
    ) {
      id
      role
      email
      name
      short_name
      street
      house_no
      city
      post_code
      vat_nr
      registration_nr
      active
      country {
        id
        name
      }
      users_aggregate {
        aggregate { count }
      }
      warehouses_aggregate {
        aggregate { count }
      }
      files: files_aggregate(where: { hidden: { _eq: false } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  }
`;

export default CompaniesTable;
