import React from "react";
import { Select } from "antd";

const Option = Select.Option;

const OrderTypes = props => {
  return (
    <Select {...props}>
      <Option value="E">Export</Option>
      <Option value="T">Transit</Option>
    </Select>
  );
};

export default OrderTypes;
