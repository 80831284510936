import React from "react";
import { Select } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const Option = Select.Option;

export const CargoTypes = props => {
  const { loading, error, data } = useQuery(GET_CARGO_TYPES);

  if (loading) return "Loading...";
  if (error) return `Error!`;

  return (
    <Select {...props} allowClear placeholder="Select cargo type">
      {data.types.map((item, key) => {
        return (
          <Option key={key} value={item.type}>
            {item.type}
          </Option>
        );
      })}
    </Select>
  );
};

const GET_CARGO_TYPES = gql`
  query CargoTypes {
    types: cargo_types(order_by: { type: asc }) {
      type
      temperature
    }
  }
`;

export default CargoTypes
