import React, { useState, useEffect } from "react";
import { Space, Breadcrumb } from "antd";

import { pickerDateFormat } from "./ChartParams";

const ChartNav = ({ onChange, value, ...otherProps }) => {

  useEffect(() => {
    console.log("ChartNav.useEffect: fired");
  }, []);

  console.log("ChartNav: value:", value);
  if (!value) return "empty path";

  const onClickHandler = (i) => {
    console.log("ChartNav.onClickHandler: go to history: "+i); //JSON.stringify(o?o:{}));
    if (onChange) onChange(i);
    return false;    
  };

  const getNavText = (b, inedx, array) => {
    return <>{b.params.picker}{b.params.picker === "month"? "["+b.params.sub_picker+"]":""}:{b.params.from?.date.format(pickerDateFormat[b.params.picker])}</>;
  }

  //[{"data":{"currency":"EUR","step":"07","sum":1427262.11},"params":{"picker":"month","sub_picker":"week","range_type":"none","format":"DD.MM.YYYY","picker_format":"MM.YYYY","company_id":null,"counterpart":null,"from":{"date":"2023-02-18T11:04:11.016Z","dat1":"2023-01-31T17:00:00.000Z","dat2":"2023-02-28T16:59:59.999Z"},"to":null,"currency":"EUR","transaction_type":"INC","is_fill_empty":true,"is_show_trend":false,"is_show_grouped":false}}]
  //return (JSON.stringify(value));

  //   
  return (
    <Breadcrumb separator=">" style={{backgroundColor: "#DDDDDD"}}>
      {value.map((b, index, array) => (
        <Breadcrumb.Item>
          {index < array.length - 1 ? (            
            <a href="#" onClick={(e) => onClickHandler(index)} >
              {getNavText(b, index, array)}
            </a>
          ) : (
            <>{getNavText(b, index, array)}</>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default ChartNav;
