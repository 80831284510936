import React from "react";

const Address = props => {
  const { record, mode } = props;

  //console.log("Address: ");
  //console.dir(props);
  return (record ? <>
	<p>{`${record.street || ""} ${record.house_no || ""}`}, {`${record.post_code || ""}, ${record.city || ""}, ${record.country?.alpha3 || ""}`}</p>
  </>: <></>);

};

export default Address;
