import React, { useEffect, useState } from "react";
import { Input, Select } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const { Option } = Select;
const CURRENCY = ['USD', 'EUR', 'CNY'];
const FEE_ACCURACY = 4;

const CompanyFee = props => {
  const { value, onChange, company_id, ...otherProps } = props;
  const [ feeInfo, setFeeInfo ] = useState();

  console.log("CompanyFee: ", JSON.stringify(company_id));

/*
  useEffect(() => {
      var rate;
      console.log("CompanyFee.useEffect: ", currency, JSON.stringify(feeInfo));
      if (data) {
        console.log("CompanyFee.useEffect: got data");
 	if (currency.from == currency.to) rate = 1.0;
        else
 	if (currency.from == 'EUR') rate = data?.p_exchange_rates[0][currency.to];
        else
 	if (currency.to   == 'EUR') rate = data?.p_exchange_rates[0][currency.from]? 1 / data?.p_exchange_rates[0][currency.from] : null;

        const info = {
           date: data?.p_exchange_rates[0]?.exchange_date, 
           rate: rate 
        };
        setRateInfo(info);
        if (onChange && data) {
          console.log('CompanyFee.useEffect: call onChange '+JSON.stringify(info));
          onChange(info);
        }
      }
  }, [currency]);
*/

  const { loading, error, data } = useQuery(GET_COMPANY_FEE, {
    skip: !company_id,
    variables: {id: company_id}, 
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && onChange) onChange(data.company?.fee); 
  }, [data]);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  //const data_amounts = Array.from(CURRENCY, x => data.p_exchange_rates.find(y => y.currency == x)?.sum);
  console.log("CompanyFee: data:", JSON.stringify(data));

  return (data? <>Fee: <b>{(data.company?.fee||0.0).toFixed(FEE_ACCURACY)}</b></> : <></>);
};

// due to permisions
const GET_COMPANY_FEE = gql`
query CompanyFee ($id: Int!){
 company: companies_by_pk (id: $id){
  name
  fee
 }
}`;

export default CompanyFee;
