import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ChartParamsCrt, { DEFAULT_PARAMS, customStartEndFormat, getStep, dateFormat_db, pickerDateFormat } from "./ChartParams";
import Chart from "./Chart";
import ChartNav from "./ChartNav.js";
import { Divider } from "antd";
import { useStoreon } from "storeon/react";

import { getUserTenant } from "../../auth/authHelper";
import { get_currency_list_by_tenant }  from "../../tenants";

// start week from Monday
moment.locale("en-us", { week: { dow: 1 } });

const url_api = process.env.REACT_APP_SERVER_URL+"/bot/data_crt";

const ChartsCrtPage = props => {
  const { tab } = useStoreon("tab");

  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(DEFAULT_PARAMS);
  const [data, setData] = useState();
  const [dataTrend, setDataTrend] = useState();

  // TODO: incapsulate into ChartNav?
  const [chartNavStack, setChartNavStack] = useState([]);
  const [chartClick, setChartClick] = useState();
  const chartRef = React.useRef();

  chartRef.current = setChartClick;


/*
546546546546
546546546546546
  useEffect(() => {
    console.log("ChartsPage.useEffect: tab changed");
    setParams({...params, 
      currency_list: get_currency_list_by_tenant((tab && tab.tenant)||getUserTenant())
    });
  }, [tab]);

  useEffect(() => {
    console.log("useEffect: fired");

    if (!params.currency || !params.from?.date) return;

    if (params.currency === "ALL") {
      asyncFetchWrapper("ALL").then((d) => {
        console.log("useEffect: got data all: ", d);

        setData(d.filter((v) => v.currency !== "ALL"));
        setDataTrend(d.filter((v) => v.currency === "ALL"));
      });
    } else if (params.currency === "MIX") {
      const data_ = [];
      params.currency_list.forEach((c) => {
        data_.push(asyncFetchWrapper(c));
      });
      console.log("foreach: done: data: ", data_);
      Promise.all(data_).then((values) => {
        console.log("Promise.all: done: values: ", values);
        setData(values.flat());
        setDataTrend();
      });
    } else {
      asyncFetchWrapper(params.currency).then((d) => {
        console.log("useEffect: got data currency: ", d);
        setData(d);
        setDataTrend();
      });
    }
    
    if (params) setChartNavStack([...chartNavStack.splice(0, chartNavStack.length-1), {params: params }]);

  }, [params]);

54654654654654654
546546546546546546
*/


  //////////////////////////////////////////////////
  useEffect(() => {
    console.log("ChartsPage.useEffect: tab changed");
    // tenant  swithced?
    // fake change params for trigger useEffect with ([params])
    setParams({...params}); 
  }, [tab]);

  useEffect(() => {
    console.log("useEffect: fired");

    if (!params.from?.date) return;

    asyncFetchWrapper(params).then((d) => {
        console.log("useEffect: got data certs: ", d);
        setData(d);
        setDataTrend();
    });
    
    if (params) setChartNavStack([...chartNavStack.splice(0, chartNavStack.length-1), {params: params }]);

  }, [params]);

  useEffect(() => {
    console.log("useEffect.addon.chartClick: fired");
    console.log("chartClick:", chartClick);
    if (chartClick) doNavigation(chartClick);
  }, [chartClick]);

  /////////////////////////////////////

  const asyncFetchWrapper = async (p_params) => {
    console.log("asyncFetchWrapper: start");
    setLoading(true);
    const data = await asyncFetch();
    setLoading(false);
    console.log("asyncFetchWrapper: got data:", data);
    return data;
  };

  const asyncFetch = async (mode = "from") => {
 // {"picker":"week","format":"DD.MM.YYYY","from":{"date":"2023-06-25T09:28:41.638Z","dat1":"25.06.2023","dat2":"01.07.2023"}}
debugger;
    const request_params = {
      p_context: getUserTenant(),
      p_step: getStep(params),
      p_dat1: params[mode]?.dat1.format(dateFormat_db),
      p_dat2: params[mode]?.dat2.format(dateFormat_db),
      p_fill_empty: params.is_fill_empty ? 1 : 0,
      p_company_id: params.company_id,
      // �urrencyList.reduce((a, v) => { return a + (v_currency[v] ? v + "," : ""); }, "")
    };
    console.log("request_params: ", request_params);

    // url.search = new URLSearchParams(request_params).toString();
    const query =
      "?" +
      Object.keys(request_params)
        .map((k) => (request_params[k] ? k + "=" + encodeURIComponent(request_params[k]) : ""))
        .join("&");

    console.log("query: ", query);
    const result = await fetch(
      url_api + query
      //      {
      //      method: "GET",
      //      credentials: "include",
      //      header: { "Referrer-Policy": "no-referrer" }
      //      }
    );
    if (!result.ok) {
      throw new Error("fetch: responce not ok");
    }
    const response = await result.json();
    return response;
  };

  //////////////////////////////////////
  const doNavigation = (click_data) => {
    //alert(JSON.stringify(click_data));
    console.log("doNavigation: picker:", params.picker, "sub_picker:", params.sub_picker);

    // moved to ChartNav
    // setChartNavStack([...chartNavStack, { ...click_data, params: params }]);

    let next_picker, next_sub_picker, next_date;
    switch (params.picker) {
      case "year":
        next_picker = "month";
        next_sub_picker = "week";
        next_date = params.from?.date.set('month', click_data.data.step);
        break;
      case "month":
        next_picker = "week";        
        next_date = params.from?.date.set('week', click_data.data.step);
        break;
      default:
        next_picker = null;
    }
    console.log("next: picker:", next_picker);

    const next_pickers = { 
       picker: next_picker ? next_picker : params.picker,
       sub_picker: next_sub_picker ? next_sub_picker : params.sub_picker ,
       picker_format: next_picker? pickerDateFormat[next_picker] : params.picker_format, 

       from: !next_date || !next_picker ? params.from : { 
         date: next_date,
         dat1: moment(next_date).startOf(next_picker),
         dat2: moment(next_date).endOf(next_picker),
       },
/*
       // reset "to"
       // import?
       // range_type: DEFAULT_RANGE_TYPE, 
       to: null
*/
    };

    console.log("next: params pickers:", next_pickers);

    if (next_picker) { 
      setChartNavStack([...chartNavStack, {params: {} }]); // replaced at useEffect[params]
      setParams({...params, ...next_pickers, nav: true});
    }
  };

  //////////////////////////////////////
  const onChangeParams = (new_params) => {
    console.log("onChangeParams: fired");
    setParams(new_params);
  }
  const onChangeChart = (v) => {
    console.log("onChangeChart:", JSON.stringify(v));
    chartRef.current(v);
    // moveed to ChartNav
    //setChartNavStack(v);

    // local variables not accessed from Chart, try to use ref
    // setChartClick();
  };
  const onChangeChartNav = (index) => {
debugger;
    console.log("onChangeChartNav:", index); // JSON.stringify(v));
    setParams(chartNavStack[index].params);

    const newNavStack = [...chartNavStack].splice(0, index+1); //
    setChartNavStack(newNavStack);
  }

  //////////////////////////////////////
  if (!params) return "Please select chart parameters ...";
  if (loading) return "Please wait, loading data ...";

  return (
   <>[{tab.tenant}]
    <ChartParamsCrt value={params} onChange={onChangeParams}/>
{/*
    <hr/>
    {params.currency}
    <br/>
    {params.picker && params.from && customStartEndFormat(params, "from")}
    &nbsp;
    {params.picker && params.to && customStartEndFormat(params, "to")}
    <br/>
    {params && JSON.stringify(params)}
    <br/>
    data: {data && JSON.stringify(data.length)}
    <br/>
    data_trend: {dataTrend && JSON.stringify(dataTrend.length)}
    <br/>
*/}
{/*
    <Divider orientation="left" orientationMargin="0px" plain>Navigator</Divider>
    <Divider orientation="left" orientationMargin="0px" plain>Chart</Divider>
*/}
    <Divider plain style={{margin: "3px"}}/>
    <ChartNav value={chartNavStack} onChange={onChangeChartNav} />
    { chartNavStack.length > 0 && <Divider plain style={{margin: "3px 0px 10px 0px"}}/> }
    <Chart
       data={data}
       data_trend={dataTrend}
       params={params}
       onChange={(v) => onChangeChart(v)}
       value={chartClick}
    />   
   </>
  );
};

export default ChartsCrtPage;
