import React from "react";
import moment from "moment";
import { useStoreon } from "storeon/react";
import { Tooltip, Badge, Card, Space, Row, Col, Tag, Button } from "antd";
import ShipmentListMenu from "./ShipmentListMenu";
import { FilesIcon } from "../shared/FilesIcon";
import Address from "../shared/Address";
import { InfoOutlined, PaperClipOutlined, SnippetsOutlined, CaretLeftOutlined, CaretRightOutlined, PauseOutlined } from "@ant-design/icons";
import * as CONST from './Constants';

const ShipmentCardAvia = (props) => {
  const { shipper, orders, truck, trailer, departure_date, id } = props.item;
  const date = moment(departure_date).format("DD-MM-YYYY");
  return ( <>
  {date} <b>AVIA</b> <br />
  </>);
}

const ShipmentCardTruck = (props) => {
  const { shipper, orders, truck, trailer, departure_date, id } = props.item;
  const carrier = truck?.reg_number + ((trailer?.reg_number)?("-" + (trailer?.reg_number)):" ");
  const _carrier = truck?.reg_number + "-" + (trailer?.reg_number);
  const date = moment(departure_date).format("DD-MM-YYYY");
  return ( <>
  {date} <b>{shipper?.name}</b> <br />
  {carrier} <br />
  </>);
}

const ShipmentCardWarehouse = (props) => {
  const { warehouse, orders, truck, trailer, departure_date, id } = props.item;
  const date = moment(departure_date).format("DD-MM-YYYY");
  return ( <>
  {date} <b>{warehouse?.name}</b> <br />
  <Address record={warehouse}/> <br />
  </>);
}

const ShipmentCard = (props) => {
  const { selection, dispatch } = useStoreon("selection");
  const { shipmentId } = selection;
  const { type, shipper, orders, truck, trailer, departure_date, id, files } = props.item;
  const { item } = props;

  const date = moment(departure_date).format("DD-MM-YYYY");

  let cargoTypes = orders
    .filter((v) => v.cargo !== null) // filter null
    .map((v) => v.cargo.code);

  cargoTypes = [...new Set(cargoTypes)]; // remove dublicates

  const selectShipment = (id) => {
    dispatch("select", { model: "shipment", id: id });
  };

  const changeState = (event, item, direction) => {
    event.stopPropagation();
    console.log("changeState: ", direction);
  };
  const showHistory = (event, item) => {
    event.stopPropagation();
    selectShipment(item.id);
    if (item.shipments_histories.length === 0) 
      console.log("history : is empty");
    else
      console.log("history: "+item.shipments_histories.reduce((r, s, i) => (r+(i>0?" -> ":"")+s.status+" ["+moment(s.created_at).format("DD.MM.YYYY HH:mm")+"]"), ""));

    dispatch("showForm", { component: "shipment_status" });
  };

  //const style = { button: { background: 'blue' } };
  //<Button size="small" style={item?.shipments_histories.length > 0 ? style.button : {} } onClick={(e) => showHistory(e, item)}>{item?.state}</Button>

  return (
    <div
      onClick={() => selectShipment(id)}
      style={{
        cursor: "pointer",
        width: "100%",
        _backgroundColor: id === shipmentId ? "#f7faff" : "",
        padding: "5px",
     }}
    >
      <Row justify="start">
        <Col span={20}>
 { type === CONST.SHIPMENT_TYPE_AVIA_KEY  && <ShipmentCardAvia {...props} /> }
 { type === CONST.SHIPMENT_TYPE_TRUCK_KEY  && <ShipmentCardTruck {...props} /> }
 { type === CONST.SHIPMENT_TYPE_WAREHOUSE_KEY  && <ShipmentCardWarehouse {...props} /> }
	    
         <Space size={1}>
          <Tooltip title={props.spr_decode[item?.state]?.desc}>
            <Button size="small"
              type={item?.shipments_histories.length > 1 ? "primary" : "default" }
              onClick={(e) => showHistory(e, item)}
            >
              {props.spr_decode[item?.state]?.info}
            </Button>
          </Tooltip>
          {" "}{cargoTypes.map((v, k) => ( <Tag key={k} style={{backgroundColor:"#f3f7be"}}>{v}</Tag>))}
         </Space>
        </Col>
        <Col span={4}>
          <Space>
           <Tooltip placement="topLeft" title="Linked orders">
           <Badge size="small" count={orders.length} style={{ float: "right" }}>
             <SnippetsOutlined/>
           </Badge>
           </Tooltip>
          {/* <Button size="small" type="link" style={{ float: "right" }}> */}
          {/* <PaperClipOutlined /> */}
          {/* </Button> */}
          <FilesIcon id={id} dataModel="shipment" count={files.aggregate.count} />
          </Space>
          <br />
        </Col>
      </Row>
    </div>
  );
};

export default ShipmentCard;
//<Tag color="yellow">Orders {orders.length}</Tag>
