import * as React from "react";
import { useParams } from "react-router-dom";

import useFetch from "../utils/utils";

// sometging like this
// https://api.rbinternational.com/swagger-ui-oauth2-redirect?code=Wn9M7YNvDUfK7oMZN4I420WmeAdTOWBd&state=blabla

const RaiffeisenRedirect = (props) => {
  // Raiffeisen use query parameters for redirect 
  // const params = useParams();
  const query = new URLSearchParams(window.location.search);
  const api_url = window.location.href;

/*
  const api_url = 'https://app001.crudsoft.ru/auth/raiffeisen/redirect'+window.location.search;
  console.log('RaiffeisenRedirect: api: ', api_url);
  const [consentLoading, consentError, consentData] = useFetch(api_url, "GET");

  if (consentLoading) return 'loading ...';
  if (consentError) return `error ... [${consentError}]`;
*/
  const [consentLoading, consentError, consentData] = [false, false, {}];
  const result = query.get('ref')? 'OK' : 'SOMETHING STRANGE';

  return (
    <div>
      <h1>PARAMS:</h1>
      <p>error: {query.get('error')}</p>
      <p>state: {query.get('status')}</p>
      <p>code: {query.get('code')}</p>
      <hr/>
      <p>get: {api_url}</p>
      <p>back: {JSON.stringify(consentData)}</p>
      <p>result: {result}</p>
    </div>
  );
}


export default RaiffeisenRedirect;
//       <p>back: {JSON.stringify(consentData)}</p>
