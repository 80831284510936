import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker: Picker } = DatePicker;

const RangePicker = props => {
  const { onChange, ...otherProps } = props;

  return (
    <Picker
      {...otherProps}
      // value={moment(value)}
      onChange={onChange}
    />
  );
};

export default RangePicker;
