import React from "react";
import { useStoreon } from "storeon/react";
import { Button, Tooltip } from "antd";
import { LockOutlined } from "@ant-design/icons";

const ChangePasswordBtn = props => {
  const { dispatch } = useStoreon("selection");

  const handleClick = () => {
    dispatch("select", { model: "user", id: props.id });
    dispatch("showForm", { component: "change_password"} );
  };

  return (
<Tooltip placement="topLeft" title="Change password">
    <Button type="link" onClick={handleClick} {...props} >
      <LockOutlined/>
    </Button>
</Tooltip>
  );
};

export default ChangePasswordBtn;
