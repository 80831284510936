import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";
import { Tag, Typography, Divider, Space, Modal, Form, Checkbox, Button } from "antd";
import RangePicker from "../shared/RangePicker";
import CompanySelect from "../shared/CompanySelect";
import ShipmentStatuses from "../shared/ShipmentStatuses";
import moment from "moment";

const { Text } = Typography;


const ShipmentFilterForm = () => {
  const [form] = Form.useForm();
  const { ui, dispatch, filter, selection } = useStoreon("ui", "filter", "selection");
  const { visible } = ui.shipmentFilterForm;
  const [ preRange, setPreRange ] = useState();

/*
 useEffect(() => {
      console.log('useEffect. mount, filter: ', filter.shipment);
      dispatch("setShipmentFilter", {range: [moment(), moment()]});
      //form.setFieldValue({range: [moment(), moment()]});
  }, []);
*/

  useEffect(() => {
debugger;
    if (visible) {
      console.log('filter: ', filter.shipment);
      // remove this attribute due null generate empty selected item at multiple mode
      // empty array is ok, but at graphql used as search criteria
      if (!filter.shipment.state) delete filter.shipment.state;
      form.setFieldsValue(filter.shipment);
      setPreRange(filter.shipment.range);
/*
 * move to list -> via dispatch
 *
      if (!filter.shipment.range || filter.shipment.range == "") {
        form.setFieldsValue({range: [moment().add(-3, 'days'), moment()]});
      }
*/
    }
  }, [visible]);

  const closeForm = () => {
    dispatch("hideShipmentFilterForm");
  };

  const resetFilter = () => {
    dispatch("deselectRows", { model: "shipment" });
    dispatch("deselect", { model: "shipment" });

    dispatch("resetShipmentFilter");
    closeForm();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        //if (values.range[0].isSame(values.range[1]))
        values.range[0] = values.range[0].startOf('day');
        values.range[1] = values.range[1].endOf('day');
        console.log("filter.range:", values.range);
        dispatch("setShipmentFilter", values);
        closeForm();
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  const makeRange = (event, days: 0, months: 0) => {
     
     console.log("makeRange: 0: ", form.getFieldValue('range'));
     console.log("makeRange: 1: ", {range: [moment().add((days), 'days').startOf('day'), moment().endOf('day')]});
     let start = moment().add(days, 'days').startOf('day');
     if (months) start = start.subtract((months-1), 'month').startOf('month');
     form.setFieldsValue({range: [
        start,
        moment().endOf('day')
     ]});
     console.log("makeRange: 2: ", form.getFieldValue('range'));
  };

  const rangeChangeHandler = (e, v) => {
      // TODO: add to makeRange()?
      if (e) setPreRange(e);
  };

  return (
    <Modal
      visible={visible}
      okText="Filter"
      onOk={handleSubmit}
      onCancel={closeForm}
      forceRender={true}
      title="Shipment filter"
      footer={[
        /*<Button key="reset" onClick={resetFilter}>
          Reset
        </Button>,*/
        <Button key="back" onClick={closeForm}>
          Close
        </Button>,
        <Button key="Apply" type="primary" onClick={handleSubmit}>
          Apply
        </Button>
      ]}
    >
      <Form layout="vertical" form={form}>
        <Space>
        <Form.Item
          label="Date range"
          name="range"
          rules={[{ required: false, message: "Please select Loading date" }]}
        >
          <RangePicker format="YYYY-MM-DD" onChange={rangeChangeHandler}/>
        </Form.Item>
        { preRange && preRange[1] && !moment().isSame(preRange[1], 'day') &&  <Text type="warning">* Till: Not today</Text> }
        </Space>
        <Form.Item>
          <Tag type="link" onClick={(e) => makeRange(e, -3)}>Last 3 days</Tag>
          <Tag type="link" onClick={(e) => makeRange(e, -7)}>Last 7 days</Tag>
          <Tag type="link" onClick={(e) => makeRange(e, 3)}>Next 3 days</Tag>
          <Tag type="link" onClick={(e) => makeRange(e, 7)}>Next 7 days</Tag>
          <Tag type="link" onClick={(e) => makeRange(e, 0, 1)}>Current month</Tag>
        </Form.Item>
        <Form.Item
          label="Shipper"
          name="shipperId"
          rules={[{ required: false, message: "Please select shipper" }]}
        >
          <CompanySelect role="shipper" />
        </Form.Item>
        <Form.Item
          name="active"
          valuePropName="checked"
          rules={[{ required: false, message: "Please set status" }]}
        >
          <Checkbox>Active</Checkbox>
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[{ required: false, message: "Please set state" }]}
        >
          <ShipmentStatuses mode="multiple" showArrow="true"/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ShipmentFilterForm;
