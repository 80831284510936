import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useMutation, useQuery } from "@apollo/client";
import { message, Form, Modal, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

const UserChangePassForm = () => {
  const [form] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { component } = ui.visibleForm;
  const { userId } = selection;

  useEffect(() => {
    if (data?.user) {
      form.setFieldsValue({username: data?.user?.user_accounts[0]?.username});
    }
  });

  const closeForm = () => {
    form.resetFields();
    dispatch("hideForm");
  };

  const { loading, error, data } = useQuery(GET_USERS, {
    skip: !userId,
    variables: {
      id: userId
    }
  });

  const [mutate] = useMutation(UPDATE_USER_ACCOUNT, {
    variables: { user_id: userId },
    refetchQueries: ["Users"],
    onCompleted: () => closeForm(),
    onError: (e) => processError(e) // onError or mutate.then/catch
  });

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
	 // could analyze constrain name [user_accounts_username_key] due two uniq keys?
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
          message.error('Username already exists');
         } else
          message.error('Server error, contact your administrator');
        });
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        delete values.__typename;
        delete values.confirm;
        values.user_id = userId;

console.log("handleSubmit: values");
console.dir(values);
console.log("handleSubmit: try mutate() ... ");
	var m = { variables: { user_id: userId, objects: values } }
console.dir(m);
        //mutate({ variables: { objects: values } });
        mutate(m);

console.log("handleSubmit: mutate() done");

        // form.resetFields();
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Modal
      title="Change password"
      visible={component === "change_password"}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={closeForm}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please enter username" }]}
        >
          <Input
            addonAfter={
              <Button
                size="small"
                onClick={() =>
                  form.setFieldsValue({ username: data.user.email })
                }
                type="link"
              >
                Use email
              </Button>
            }
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          hasFeedback
          rules={[{ required: true, message: "Please enter password" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!"
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const UPDATE_USER_ACCOUNT = gql`
  mutation UpsertUserAccount(
    $user_id: Int!
    $objects: [user_accounts_insert_input!]!
  ) {
    insert_user_accounts(
      objects: $objects
      on_conflict: {
        constraint: user_accounts_user_id_key
        update_columns: [username, password]
        where: { user_id: { _eq: $user_id } }
      }
    ) {
      affected_rows
    }
  }
`;

const GET_USERS = gql`
  query UsersById($id: Int!) {
    user: users_by_pk(id: $id) {
      id
      email
      user_accounts {
        username
      }
    }
  }
`;

export default UserChangePassForm;
