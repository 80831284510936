import React, { useState } from "react";
import { message, Popconfirm, Button, Tooltip } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { SyncOutlined } from "@ant-design/icons";
import { AUTH_TOKEN } from "../../../constant";

const ERROR_MESSAGE_DURATION = 5;

const LinkRenewBtn = props => {
  const { link, postRenewHandler, ...otherProps } = props;
  const [state, setState] = useState({disabled: false, hide: null});
  const [processing, setProcessing] = useState(false);

  // console.log("LinkRenewBtn: props:", props);

  const handleRenewLink = (link) => {
    const hide = message.loading('Action in progress ...', 0, () => {
       // message.success('Done');
    });

// debug
/*
    hide();
    alert(`Renew: create for ${link.institution_id} ${link.bank_name}, delete: ${link.id}`);
    return;
*/

    setState({disabled: true, hide: hide});
    setProcessing(true);

    const url_create = process.env.REACT_APP_SERVER_URL + "/auth/gocardless/create_link?"+
          new URLSearchParams({ id: link.institution_id, bank_name: link.bank_name, renew: 'yes' }).toString();

//alert('url_create: '+url_create);
    const url_delete = process.env.REACT_APP_SERVER_URL + "/auth/gocardless/remove_link?id="+link.id;
//alert('url_delete: '+url_delete);

    const req_options = {
          headers: {
           "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN),
            Accept: "application/json",
           "cache-control": "no-cache"
          }
    };

    Promise.all([
      fetch(url_create, req_options),
      fetch(url_delete, req_options)
    ]).then(responses =>
       Promise.all(responses.map(response => response.json()))
    ).then(data => {
      console.log(data)
//alert('data: '+JSON.stringify(data));
      if (data[0].error || !data[0].link) throw new Error(data[0].error);
      if (data[1].error) throw new Error(data[1].error);

      if (link.stored) {
         console.log("LinkRenewBtn: try to update db ...");
//alert('try to update');
         update_link({ variables: { id: link.id, new_id: data[0].id } });
      }
      hide(); 
      if (postRenewHandler) { 
          // wtf? after removing at removeHandler() next link will be disabled
          // try to reset state before delete row
          setState({disabled: false, hide: null});

          postRenewHandler(); // if need IDs add parameter { id: link.id, new_id: data[0].id }
      }

    }).catch(err => {
      message.error(`Can't renew requisition for ${link.institution_id} as [${link.bank_name}]: ${err}`, ERROR_MESSAGE_DURATION)

      if (state?.hide) state.hide();
      setProcessing (false);

    }).finally(() => {
      //
    });
  };

  // update db
  // may be move this part to server side into update_link() too 
  const [update_link] = useMutation(UPDATE_LINK, {
    skip: !link.id,
// we must refresh whole page (https api data and db data )
//    refetchQueries: ["Gocardless_parameters"],
    onCompleted: (d) => handleCompleted(d),
    onError: (e) => processError(e) 
  });

  const handleCompleted = (d) => {
    // debugger;
    if (state?.hide) state.hide();
    console.log("LinkRenewBtn: renew completed ...");
    if (d.update_bank_parameters.affected_rows !== 1)
      message.warning('Gocardless link renewed, but in db link not updated.');
    else
      message.success('Link with specified id updated successfully.');

    setProcessing (false);
  }

  // Foreign key violation? update or delete on table "bank_parameters"
  const processError = (e) => {
    if (state?.hide) state.hide();

    console.log("LinkRenewBtn: Got errors: ");
    e.graphQLErrors.map(error => {
     console.log(error);
     if (error.extensions.code === "constraint-violation") {
      console.log(`GOT IT! ${error.message}`);
        message.error('Can`t update this link due constraint violation, contact your administrator', ERROR_MESSAGE_DURATION);
     } else
      message.error('Server error, contact your administrator', ERROR_MESSAGE_DURATION);
    });

    setProcessing (false);
  }

  if (!link.id) return (<></>);

  return (
    <Popconfirm
      title="Are you sure renew requisition?"
      onConfirm={e => {
        e.preventDefault();
        console.log("renew: "+JSON.stringify(props));
        handleRenewLink (link);
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip placement="left" mouseLeaveDelay="0" title="Renew requisition" >
        <Button
           {...otherProps}
           type="link"
           icon={<SyncOutlined />}
           disabled={state.disabled}
        />
      </Tooltip>
    </Popconfirm>
  );
};

const UPDATE_LINK = gql`
mutation UpdateTransactions(
  $id: String!,
  $new_id: String!
 ) {
 update_bank_parameters(
      where: { _and: [
       {par_name: {_eq: "req_id"}}
       {par_value: {_eq: $id}},
      ]},
      _set: { par_value: $new_id}
   ) {
   affected_rows
 }  
}
`;

export default LinkRenewBtn;

