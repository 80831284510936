import React, { Component, Fragment } from "react";

class PublicPage extends Component {
  render() {
    return (
      <Fragment>
        <h1>Welcome </h1>
      </Fragment>
    );
  }
}

export default PublicPage;
