import React, { Component } from "react";
import CompaniesTable from "./CompaniesTable";

class Companies extends Component {
  render() {
    return <CompaniesTable />;
  }
}

export default Companies;
