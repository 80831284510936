import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { Table } from "antd";
import FormBtn from "../shared/FormBtn";
import TrailerDisconnectBtn from "../trailers/TrailerDisconnectBtn";
import { FilesIcon } from "../shared/FilesIcon";

const columns = [
  {
    title: "Number",
    dataIndex: "reg_number",
    width: "5%",
  },
  {
    title: "Files",
    width: "3%",
    render: record => <FilesIcon id={record.id} dataModel="trailer" count={record.files.aggregate.count}/>
  },
  {
    render: record => (
      <div style={{ float: "right" }}>
        <FormBtn component="trailer" action="edit" id={record.id} />
        <TrailerDisconnectBtn
          id={record.trucks_connection[0].id}
        />
      </div>
    )
  }
];

const TrailersTable = props => {
  const { truckId } = props;

  const { loading, error, data } = useQuery(GET_TRAILERS, {
    variables: { truckId: truckId },
    skip: !truckId
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <Row type="flex" justify="start" gutter={8}>
        <Col span={2}>
          <FormBtn component="connect_trailer" action="add" truck_id={truckId} />
        </Col>
        <Col span={3}>
          <span />
        </Col>
        <Col span={19} />
      </Row>
      <Row>
        <Col span={24}>
          <Table
            rowKey="id"
            pagination={false}
            columns={columns}
            dataSource={data.trailers}
            size="small"
            style={{margin: 0}}
          />
        </Col>
      </Row>
    </>
  );
};

const GET_TRAILERS = gql`
  query Trailers($truckId: Int) {
    trailers(
      where: { trucks_connection: { truck_id: { _eq: $truckId } } }
      order_by: [{ reg_number: asc }]
    ) {
      id
      reg_number
      trucks_connection {
        id
      }
      files: files_aggregate(where: { hidden: { _eq: false } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  }
`;

export default TrailersTable;
