import React from "react";
import { useStoreon } from "storeon/react";
import { Button } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

const WarehoouseModalBtn = props => {
  const { dispatch } = useStoreon("selection");

  const handleClick = () => {
    if (props.id) dispatch("select", { model: "warehouse", id: props.id });
    if (props.company_id) dispatch("select", { model: "company", id: props.company_id });
    
    dispatch("showForm", { component: "warehouse", action: props.action });
  };

  return (
    <Button onClick={handleClick} {...props}>
      {props.action === "add" ? <PlusOutlined>Add</PlusOutlined> : <EditOutlined>Edit</EditOutlined>}
    </Button>
  );
};

export default WarehoouseModalBtn;
