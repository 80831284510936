import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { Form, Input, Modal, Button, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Countries from "../shared/Countries";

const CountryLookupCodes = () => {
  const [form] = Form.useForm();
  const { ui, dispatch } = useStoreon("ui", "selection");
  const { component } = ui.visibleForm;

  useEffect(() => {

    if (data?.country_lookup_codes) {
      form.setFieldsValue(data);
    }
  });

  const closeForm = () => {
    dispatch("hideForm");
  };

  const { loading, error, data } = useQuery(GET_LOOKUP_CODES, {
    fetchPolicy: "cache-and-network",
    skip: !(component === "country_lookup_codes"),
  });

  const [upsert] = useMutation(UPSERT_LOOKUP_CODES, {
    refetchQueries: ["GetLookupCodes"],
    // onCompleted: () => {
    //   form.resetFields();
    //   closeForm();
    // },
  });

  const [remove_code] = useMutation(DELETE_LOOKUP_CODE, {
    // TODO: refetchQueries - bad idea, but remove() not worked yet
    refetchQueries: ["GetLookupCodes"],
    onCompleted: (data) => {
      // alert(data?.deleted?.id);
    },
  });

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        values.country_lookup_codes.forEach((v) => {
          delete v.__typename;
          upsert({
            variables: { objects: v },
          });
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <Modal
        title="Country lookup codes"
        visible={component === "country_lookup_codes"}
        // visible={true}
        okText="Submit"
        onOk={handleSubmit}
        onCancel={closeForm}
      >
        <Form layout="vertical" form={form} initialValues={data}>
          <Form.List name="country_lookup_codes">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                <Form.Item
                 required={false}
                 key={field.key}
                >
                    <Row align="left" justify="space-between">
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, "country_id"]}
                          fieldKey={[field.fieldKey, "country_id"]}
                        >
                          <Countries />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          name={[field.name, "code"]}
                          fieldKey={[field.fieldKey, "code"]}
                        >
                          <Input placeholder="code" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item>
                          <MinusCircleOutlined
                            style={{ margin: 10, color: "red" }}
                            onClick={() => {
                              console.log('click: remove: '+JSON.stringify(field));
                              if (field?.key !== null && data.country_lookup_codes[field.key]?.id) {
                                remove_code({variables: { id: data.country_lookup_codes[field.key].id }})
                                .then(() => {
                                  console.log('remove: removed from db');
                                  // TODO: not worked ... use refetchQueries
                                  remove(field.name); 
                                });
                              } else 
                                remove(field.name); 
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                 </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusOutlined /> Add code
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
};

export default CountryLookupCodes;

const GET_LOOKUP_CODES = gql`
  query GetLookupCodes {
    country_lookup_codes(order_by: { country: { name: asc } }) {
      id
      code
      country_id
    }
  }
`;

const DELETE_LOOKUP_CODES = gql`
  mutation DeleteLookupCode($id: Int!) {
    delete_country_lookup_codes(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const DELETE_LOOKUP_CODE = gql`
  mutation DeleteLookupCode($id: Int!) {
    deleted: delete_country_lookup_codes_by_pk(id: $id ) {
      id
    }
  }
`;

const UPSERT_LOOKUP_CODES = gql`
  mutation InsertLookupCodes($objects: [country_lookup_codes_insert_input!]!) {
    insert_country_lookup_codes(
      objects: $objects
      on_conflict: {
        constraint: country_lookup_codes_pkey
        update_columns: [code, country_id]
      }
    ) {
      affected_rows
    }
  }
`;
