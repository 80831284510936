import React, { Component, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { PlusOutlined } from "@ant-design/icons";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useStoreon } from "storeon/react";
import { message, Select, Avatar } from "antd";
import { AUTH_TOKEN } from "../../../constant";

const Option = Select.Option;
const ERROR_MESSAGE_DURATION = 5;

/////////////////////////////////////////////////////
const GetGocardlessBank = ({country, forceDisable, onChange, ...otherProps}) =>  {
  const [ value, setValue ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ institutions, setInstitutions ] = useState([]);
  
  const api_url = process.env.REACT_APP_SERVER_URL + "/auth/gocardless/banks";

/*
    const [ loading, error, data] = [false, false, !country? [] : [{
      "id":"ABNAMRO_ABNAGB2LXXX",
      "name":"ABN AMRO Bank Commercial",
      "bic":"ABNAGB2LXXX",
      "transaction_total_days":"540",
      "countries":[
         "GB"
      ],
      "logo":"https://cdn.nordigen.com/ais/ABNAMRO_FTSBDEFAXXX.png"
   }]];
*/

  useEffect(() => {
    console.log("GetGocardlessBank: useEffect: got country: ", country);
    if (!country) { 
      if (institutions.length > 0) alert('Clear country?');
      setValue();
      setInstitutions([]); 
      return; 
    }

    setLoading (true);
    const url = process.env.REACT_APP_SERVER_URL + "/auth/gocardless/banks?country="+country;
    fetch (url,
        {
          headers: {
           "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN),
            Accept: "application/json",
           "cache-control": "no-cache"
        },
        method: 'GET', 
    })
     .then(data => data.json())
     .then(data => {
        if (data.error) throw new Error(data.error);
        // alert(JSON.stringify(data));

// debug only
/*
 * add SANDBOX for testing gocardless api
 *
*/
       //setInstitutions([{name: 'SandBox', id: 'SANDBOXFINANCE_SFIN0000' }, ...data]);
       setInstitutions(data);

     })
     .catch(err => {
        setInstitutions([]);
        message.error(`Can't get institutions for ${country}: `+err, ERROR_MESSAGE_DURATION);
     })
     .finally(() => {
       setLoading (false);       
     });

  }, [country]);

  const handleChange = (p_value) => {
   setValue(p_value);
   if (onChange) onChange(p_value);
  }

  //if (loading) return "Loading...";
  //if (error) return `Error! ${error.message}`;

  return (
    <>
    <Select
       {...otherProps}
       onChange={handleChange}
       value={value}
       allowClear
       showSearch
       placeholder={ loading ? "Loading ..." : "Choose a bank" }
       disabled={!country || loading || forceDisable}
       optionFilterProp="children"
       filterOption={(input, option) => 
         option.children[1]
           .toLowerCase()
           .indexOf(input.toLowerCase()) >= 0
       }
     >
       {institutions.map((item, key) => {
         return (
           // spaces progibited due filterOption => option.children[1]
           <Option key={key} value={item.id}>{ item.logo?.length > 0 ? <Avatar shape="square" size="small" style={{backgroundColor: '#ffffff'}} src={item.logo} /> : <span style={{width: '16px'}}></span> }{item.name}</Option>
         );
       })}
     </Select>
    </>
    );
};

export default GetGocardlessBank;
