import React, { Component } from "react";
import { message, Popconfirm, Button } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";

const DeleteWarehouseBtn = props => {
  const [delete_warehouse] = useMutation(DELETE_WAREHOUSE, {
    refetchQueries: ["Companies", "Warehouses"], // Companies for tags
    //onCompleted: (d) => closeForm(d),
    onError: (e) => processError(e), // onError or mutate.then/catch
  });

  const { id } = props;

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
          message.error('This Warehouse is still used in some orders');
         } else
          message.error('Server error, contact your administrator');
        });

   }
   return (
          <Popconfirm
            title="Are you sure delete this warehouse?"
            onConfirm={e => {
              e.preventDefault();
              delete_warehouse({ variables: { id: id } });
            }}
            onCancel={e => { e.preventDefault(); }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger icon={<DeleteOutlined />} {...props}/>
          </Popconfirm>
  );
}

const DELETE_WAREHOUSE = gql`
  mutation DeleteWarehouse($id: Int!) {
    delete_warehouses(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export default DeleteWarehouseBtn;

