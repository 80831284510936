import React from "react";
import { message, Popconfirm, Button, Tooltip } from "antd";
import { gql, useMutation } from "@apollo/client";
import { RollbackOutlined, PayCircleOutlined } from "@ant-design/icons";
import { useStoreon } from "storeon/react";



const Transaction2ConvBtn = ({disabled, ...props}) => {
  const { auth } = useStoreon("auth");

  const [update_transaction] = useMutation(SET_CNV_TRANSACTION, {
    refetchQueries: ["Transactions"],
    //onCompleted: () => closeForm(),
    onError: (e) => processError(e)
  });

  //const perms = {...(disabled && { disabled })};
  const perms = disabled || props.link_id;
  //console.log(`Transaction2ConvBtn: perms: `, perms, "link_id:", props.link_id);

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         // could analyze constrain name:
	 // "users_company_id_fkey"
	 // "warehouses_company_id_fkey"
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
/*
	  if (error.message.includes("users_company_id_fkey"))
          	message.error('Company has attached users');
	  else
         	message.error('Deletion is impossible due to the availability of information about the company\'s actions');
*/
         } else
         	message.error('Server error, contact your administrator');
        });
  }

  // const action = props.type_transaction == "IGN"?"clear":"unlink";

  return (
    <Popconfirm
      title={"Are you sure set this transaction as CNV?"}
      onConfirm={e => {
        e.preventDefault();
        update_transaction({ variables: { id: props.id, linked_user: auth.login, linked_at: new Date() }});
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
     <Tooltip placement="left" mouseLeaveDelay="0" title="Set this transaction as CNV" >
        <Button
          {...props}
          type="link"
          _size="small"
          _danger="true"
          _disabled={perms}
          icon={<PayCircleOutlined />}
        />
      </Tooltip>

    </Popconfirm>
  );
};

//    _set: { initiator_id: null, type_transaction: 'CNV', link_id: null, linked_user: null, linked_at: null  }

const SET_CNV_TRANSACTION = gql`
  mutation UpdateTransaction($id: uuid!, $linked_user: String!, $linked_at: timestamptz!) {
    update_p_transactions_by_pk (
      pk_columns: {id: $id}
      _set: { type_transaction: "CNV", linked_user: $linked_user, linked_at: $linked_at }
    ) {
      id
    }
  }
`;

export default Transaction2ConvBtn;
