// 
// Data storage that differs for different tabs

// due storeon has only one type of storage for persistState
// config.storage
// 
const mock_key = "tenant";

export default (store) => {
  store.on("@init", () => { 
   //debugger;
   let tenant = sessionStorage.getItem(mock_key);
   if (!tenant) {
     tenant = process.env.REACT_APP_DEFAULT_TENANT;
     sessionStorage.setItem(mock_key, tenant); 
   }   
   return {
    tab: {
      tenant: tenant,
    },
   }
  });

  store.on("setTabData", ({ tab }, args) => {
    //debugger;
    if (args.hasOwnProperty(mock_key)) 
       args.tenant ? sessionStorage.setItem(mock_key, args.tenant) : sessionStorage.removeItem(mock_key);
    return {
     tab: {
        ...tab,
        tenant: args.tenant,
     },
    };
  });
};
