import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { useStoreon } from "storeon/react";
import gql from "graphql-tag";
import { AUTH_TOKEN } from "../../constant";
import { useMutation, useQuery } from "@apollo/client";
import { Modal, Upload, Button, Table, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const dataKeysIPH = [
  "master_awb",
  "flight",
  "colli",
  "colli_transit",
  "brutto_weight",
  "chargeable_weight",
  "label",
  "country_id",
  "house_awb",
  "house_awb",
  "transit_eta",
  "transit_client",
  "transit_id",
  "transit_ready",
];

const dataKeysKN = [
  "unused",
  "master_awb",
  "flight",
  "transit_eta",
  "unused",
  "colli",
  "colli_transit",
  "brutto_weight",
  "chargeable_weight",
  "label",
  "country_id",
  "unused",
  "house_awb",
  "transit_client",
  "unused",
];

const columns = [
  {
    title: "Master AWB",
    dataIndex: "master_awb",
  },
  {
    title: "Colli",
    dataIndex: "colli",
  },
  {
    title: "Brutto",
    dataIndex: "brutto_weight",
  },
  {
    title: "Label",
    dataIndex: "label",
  },
];

const { Option } = Select;

const OrderImportForm = () => {
  // Warning, hardcoded sellers id:
  // <Option value={11}>IPHandlers</Option>
  // <Option value={14}>Kuehne + Nagel</Option>

  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sellerId, setSellerId] = useState();

  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { component } = ui.visibleForm;
  const { shipmentId } = selection;

  const onUpload = (res) => {
    if (res.file.status === "done") {
      let rows = res.file.response.data;
      let keys = sellerId === 11 ? dataKeysIPH : dataKeysKN; // hardcoded
      rows = _.chain(rows)
        .reject((a) => _.isEmpty(a))
        .map((a) => _.zipObject(keys, a))
        .map((a) => _.assign(a, { id: uuidv4() }))
        .value();
      setRows(rows);
    } else console.log("upload status", res.file.status);
  };

  const { loading, error, data } = useQuery(GET_LOOKUP_CODES, {
    skip: !(component === "import_orders"),
  });

  const [mutate] = useMutation(INSERT_ORDER, {
    refetchQueries: ["Orders"],
    onCompleted: () => closeForm(),
  });

  const getCountry = (e) => {
    let index = _.findIndex(
      data.country_lookup_codes,
      (v) => v.code === e.country_id
    );
    if (index > -1) {
      e.country_id = data.country_lookup_codes[index].country_id;
    }
    return e.country_id;
  };

  const handleSubmit = () => {
    let rows = selectedRows;
    rows = _.chain(rows)
      .map((a) =>
        _.assign(a, {
          cargo_type: "CUTFLOWERS",
          packing_type_id: 4, //TODO, 4 = Transit Box
          seller_id: sellerId,
          shipment_id: shipmentId,
          country_id: getCountry(a),
        })
      )
      .map((a) => _.omit(a, ["transit_ready", "id", "transit_eta"])) //TODO transit_eta time format error
      .value();
    // console.log(rows);
    mutate({ variables: { objects: rows } });
  };

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const closeForm = () => {
    setRows([]);
    dispatch("hideForm");
  };

  if (loading) return null;
  if (error) return `Error! ${error.message}`;

  return (
    <Modal
      width="80vw"
      centered
      title="Import form"
      visible={component === "import_orders"}
      okText={`Submit ${selectedRows.length} rows`}
      onOk={handleSubmit}
      okButtonProps={{ disabled: !selectedRows.length > 0 || !sellerId }}
      onCancel={closeForm}
    >
      <Select
        placeholder="Select schema"
        onChange={(value) => setSellerId(value)}
      >
        <Option value={11}>IPHandlers</Option>
        <Option value={14}>Kuehne + Nagel</Option>
      </Select>
      {sellerId && (
        <Upload
          action={process.env.REACT_APP_SERVER_URL + "/xlsx/file-to-json"}
          onChange={(res) => onUpload(res)}
          headers={{
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Upload xlsx</Button>
        </Upload>
      )}
      <Table
        rowSelection={rowSelection}
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={rows}
      />
    </Modal>
  );
};

const INSERT_ORDER = gql`
  mutation CreateOrderByImport($objects: [orders_insert_input!]!) {
    insert_orders(objects: $objects) {
      affected_rows
    }
  }
`;

const GET_LOOKUP_CODES = gql`
  query LookupCodes {
    country_lookup_codes {
      id
      code
      country_id
    }
  }
`;

export default OrderImportForm;

const countrysMapping = [
  { oldValue: "UGEBB", newValue: "222" },
  { oldValue: "ECUIO", newValue: "62" },
  { oldValue: "KENBO", newValue: "110" },
  { oldValue: "COBOG", newValue: "47" },
  { oldValue: "NLAMS", newValue: "150" },
  { oldValue: "ILTLV", newValue: "104" },
  { oldValue: "TWTYN", newValue: "208" },
  { oldValue: "TWTPE", newValue: "208" },
  { oldValue: "TRAYT", newValue: "218" },
  { oldValue: "RUMOW", newValue: "177" },
  { oldValue: "-", newValue: "150" },
  { oldValue: "", newValue: "150" },
  { oldValue: "EBB", newValue: "222" },
  { oldValue: "UIO", newValue: "62" },
  { oldValue: "NBO", newValue: "110" },
  { oldValue: "NBC", newValue: "110" },
  { oldValue: "BOG", newValue: "47" },
  { oldValue: "AMS", newValue: "150" },
  { oldValue: "TLV", newValue: "104" },
  { oldValue: "TYN", newValue: "208" },
  { oldValue: "TPE", newValue: "208" },
  { oldValue: "AYT", newValue: "218" },
  { oldValue: "MOW", newValue: "177" },
  { oldValue: "Quito, Ecuador", newValue: "62" },
  { oldValue: "Tel Aviv, Israel", newValue: "104" },
  { oldValue: "Bogota, Columbia", newValue: "47" },
  { oldValue: "Nairobi, Kenya", newValue: "110" },
  { oldValue: "Amsterdam,", newValue: "150" },
];
