import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";
import gql from "graphql-tag";
import moment from "moment";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Button, Row, Col, Space, Form, InputNumber, Input, Modal } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import DatePicker from "../shared/DatePicker";
import CompanySelect from "../shared/CompanySelect";
import Countries from "../shared/Countries";
import CargoTypes from "../shared/CargoTypes";
import PackingTypes from "../shared/PackingTypes";
import OrderTypes from "../shared/OrderTypes";
import WarehouseSelect from "../shared/WarehouseSelect";
import TemperatureRange from "../shared/TemperatureRange";
import { isSupplier, isAdmin } from "../../auth/authHelper";

const OrderForm = (props) => {
  const [ form ] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { action, component, mode } = ui.visibleForm;
  const { orderId } = selection;
  const { shipmentId } = mode === "all" ? {} : selection;
  const [ unitsLoaded, setUnitsLoaded ] = useState();

  const { auth } = useStoreon("auth");
  const [ buyerId, setBuyerId ] = useState(null);
  const [ supplierId, setSupplierId ] = useState(null);
//  const [ countryId, setCountryId ] = useState(null);
  const [ init, setInit ] = useState(null);
  const [ getCountry, { loading: l_loading, data: l_data, error: l_error } ] = useLazyQuery(GET_SUPPLIER_COUNTRY);

  const { loading, error, data, refetch } = useQuery(GET_ORDER, {
    skip: !(component === "order" && action === "edit")||!orderId,
    variables: {
      id: orderId
    }
  });

  // TODO: refresh Shipments only for add action
  const [mutate] = useMutation(
    action === "edit" ? UPDATE_ORDER : INSERT_ORDER,
    {
      variables: { id: action === "edit" ? orderId : undefined },
      refetchQueries: ["Orders", "Shipments",], // "OrderById"],
      // cache is not smart enough to automatically update the cache for us in this case
      onCompleted: () => { if (data) refetch(); closeForm() }
    }
  );

  useEffect(() => {
    // console.log("*** OrderForm.useEffect(): fired");
    if (action === "add" && !init) {
      setInit(true);
      // console.log("*** OrderForm.useEffect(): add and not init, set dates");
      form.setFieldsValue({ departure_date: moment().toDate(),  creation_date: moment().toDate() } );
     if (isSupplier()) {
        //console.log("*** OrderForm.useEffect(): add and not init and supplier, set id and field supp id");
        const id = parseInt(auth.company_id);
        setSupplierId(id);
        form.setFieldsValue({ supplier_id: id});
        if (!form.getFieldValue('country_id')) {
          //console.log("*** OrderForm.useEffect(): add and not init and supplier, country_id is empty, call getCountry()");
	  getCountry({variables: { id: id }});
        } else {
          // console.log("*** OrderForm.useEffect(): add and not init and supplier, country_is not empty, skip");
        }
      } else
          getCountry({variables: { id: -1 }}); //clear l_data
    }
  });

/*
  useEffect(() => {
console.log("****************** OrderForm.useEffect([]): fired");
    if (action === "add") {
console.log("****************** OrderForm.useEffect([]): add, set dates");
      form.setFieldsValue({ departure_date: moment().toDate(),  creation_date: moment().toDate() } );
    }
  }, []);
*/

/*
  useEffect(() => {
    if (action === "add") {
console.log("****************** useEffect([]): add");
      if (isSupplier()) {
console.log("****************** useEffect([]): supplier, set id");
        setSupplierId(parseInt(auth.company_id));
        // debug: setSupplierCountryId(15);
      } else {
console.log("****************** useEffect([]): not supplier, clear supplier id");
        setSupplierId(null);
      }
    }
  }, []);
*/

/*
  useEffect(() => {
    if (action === "edit") {
console.log("****************** OrderForm.useEffect(): edit set fields");
      form.setFieldsValue(data && data.order);
      //setCountryId(data && data.order.country_id);
    }
    else if (action === "add") {
console.log("****************** OrderForm.useEffect(): add set fields");
      form.setFieldsValue({ departure_date: moment().toDate(),  creation_date: moment().toDate() } );
      if (isSupplier()) {
console.log("****************** useEffect(): supplier, set id");
        setSupplierId(parseInt(auth.company_id));
        form.setFieldsValue({ supplier_id: supplierId});
        // debug: setSupplierCountryId(15);
      } else {
//console.log("****************** useEffect(): not supplier, clear supplier id");
//        setSupplierId(null);
      }
    }
  });
*/

 useEffect(() => {
  //debugger;
  // console.log("****************** OrderForm.useEffect([l_data]): fired");
  // due to view: company => company[] 
  if (l_data && l_data.company?.country_id) {
    // console.log("****************** OrderForm.useEffect([l_data]): set field country_if");
    //setCountryId(l_data.company.country_id);
    if (!form.getFieldValue('country_id')) 
      form.setFieldsValue({ country_id: l_data.company.country_id });
  }
 }, [l_data]);

 useEffect(() => {
    // console.log("****************** OrderForm.useEffect([data]): fired");
    if (action === "edit" && data) {
      // console.log("****************** OrderForm.useEffect([data]): edit and data, set buyer and supp ids");
      form.setFieldsValue(data && data.order);
      setBuyerId(data?.order?.buyer_id);
      setSupplierId(data?.order?.supplier_id);
      setUnitsLoaded(data.order.order_units);
    }
    else if (action === "add") {
      // console.log("****************** OrderForm.useEffect([data]): add, set fields with dateas"); 
      form.setFieldsValue({ departure_date: moment().toDate(),  creation_date: moment().toDate() } );

      if (isSupplier()) { 
        // console.log("****************** OrderForm.useEffect([data]): add and supplier, set id and field supp id");
        const id = parseInt(auth.company_id);
	setSupplierId(id);
        form.setFieldsValue({ supplier_id: id});
	// debug: setSupplierCountryId(15);
      } else {
        // console.log("****************** OrderForm.useEffect([data]): add and not supplier, clear supplier id");
	setSupplierId(null);
        form.setFieldsValue({ supplier_id: null});
      }
    }
  }, [data]);

  ////////////////////////////////////
/*
      .then(values => {
        delete values.__typename;
        values.company_id = companyId;
        if (action === "edit") {
          let contacts = values.contacts;

          contacts.forEach(v => {
            delete v.__typename;
            v.user_id = userId;
          });

          delete values.contacts;

          mutate({
            variables: {
              id: userId,
              users_data: values,
              contacts_data: contacts,
            }
          })
          ;
        }
        if (action === "add") {
          if (values.contacts) {
            values = Object.assign(values, {
              contacts: { data: values.contacts }
            });

          }
          var v =  {
            objects: values,
            truck_id: 0, truck_data: {}
          };
          mutate({ variables: v })
        }
        // form.resetFields();
      })

*/
  ////////////////////////////////////

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
debugger;
        delete values.__typename;
        let order_units, order_units_delete = [];
        if (action === "edit") {
          order_units = values.order_units;
          order_units.forEach(v => {
            delete v.__typename;
            v.order_id = orderId;
          });

          delete values.order_units;
          
         unitsLoaded.forEach((del) => {
           if (!order_units.filter((u) => u.id === del.id).length)
             order_units_delete.push(del.id);
         });

        } else if (action === "add") {
           values.shipment_id = shipmentId;
           if (values.order_units) {
            values = Object.assign(values, {
              order_units: { data: values.order_units }
            });

          }
        } else {
          console.error("OrderForm: Unknown operation");
        }

        var v = {
          objects: values,
          id: action === "edit" ? orderId : undefined,
          units_data: action === "edit" ? order_units : undefined,
          units_data_delete: action === "edit" ? order_units_delete : undefined,
          
        };
        mutate({ variables: v });
        //form.resetFields();
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  const changeSupplierHandler = (v) => {
console.log("****************************** changeSupplierHandler: fired with", v);

    // TODO: try to remove ... now we use `value` via setFieldsValue()
    setSupplierId(v); 
    // try to change country only if the field is empty and we have supplier
    // update: useEffect([l_data]) not fired in some cases when id not changed 
    // if (v && !form.getFieldValue('country_id')) getCountry({variables: { id: v }});
    //if (v) 
getCountry({variables: { id: v?v:-1 }});
console.log("****************************** changeSupplierHandler: after lazy", l_data);
  };

  const closeForm = () => {
    dispatch("hideForm");
    form.resetFields();
    setSupplierId(null);
//    setCountryId(null);
    setInit(false);
  };
  ///////////////////////////////////////

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Modal
      title={"Order form ["+(((action==="edit"&&data?.order?.shipment_id)||(action==="add"&&shipmentId))?"for shipment":"unattached")+"]"}
      visible={component === "order"}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={closeForm}
    >
    <Form layout="vertical" form={form} name="order_form" >
      <Row gutter={8}>
      <Col span={8}>
      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: "Please select order type" }]}
      >
        <OrderTypes />
      </Form.Item>
      </Col>
      {!isSupplier() &&
      <Col span={8}>
      {/* Don`t foget that's custom DataPicker */ }
      <Form.Item
        label="Create at"
        name="creation_date"
        rules={[{ required: true, message: "Please select date" }]}
      >
        <DatePicker allowClear={false} inputReadOnly={isAdmin()?false:true}/>
      </Form.Item>
      </Col>
      }
      <Col span={8}>
      <Form.Item
        label="Departure at"
        name="departure_date"
        rules={[{ required: true, message: "Please select departure date" }]}
      >
        <DatePicker allowClear={false}/>
      </Form.Item>
      </Col>
      </Row>
      {!isSupplier() &&
      <Form.Item
        label="Supplier"
        name="supplier_id"
        rules={[{ required: true, message: "Please select supplier" }]}
      >
        <CompanySelect role="supplier" onChange={(v) => { changeSupplierHandler(v); }} />
      </Form.Item>
      }
      {/*isSupplier() &&
<>
      <Form.Item
        name="supplier_id"
        _value={supplierId}
        noStyle
      >
        <Input defaultValue={supplierId} x_value={supplierId} x_type="hidden"/>
      </Form.Item>
      <p>[{supplierId}] [{auth.company_id}]</p>
</>
      */}

      <Form.Item
        label="Supplier warehouse"
        name="supplier_warehouse_id"
        rules={[{ required: false, message: "Please select supplier wariehouse" }]}
        
      >
        <WarehouseSelect role="supplier" company_id={supplierId} disabled={supplierId?false:true} action={action}/>
      </Form.Item>
      <Form.Item
        label="Buyer"
        name="buyer_id"
        rules={[{ required: false, message: "Please select buyer" }]}
      >
        <CompanySelect role="buyer" onChange={(v) => { setBuyerId(v); }} />
      </Form.Item>
      <Form.Item
        label="Buyer warehouse"
        name="buyer_warehouse_id"
        rules={[{ required: false, message: "Please select buyer wariehouse" }]}
      >
        <WarehouseSelect role="buyer" company_id={buyerId} disabled={buyerId?false:true} action={action}/>
      </Form.Item>
      <Form.Item
        label="Labels"
        name="label"
        rules={[{ required: true, message: "Please enter labels" }]}
      >
        <Input />
      </Form.Item>
{/*
      <Space>
      <Form.Item
        label="Colli"
        name="colli"
        rules={[{ required: true, message: "Please enter colli" }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label="Packing type"
        name="packing_type_id"
        rules={[{ required: true, message: "Please select packing type" }]}
      >
        <PackingTypes />
      </Form.Item>
      <Form.Item
        label="Cargo type"
        name="cargo_type"
        rules={[{ required: true, message: "Please enter type of cargo" }]}
      >
        <CargoTypes />
      </Form.Item>
      </Space>
*/}
<Form.List name="order_units">
          {(fields, { add, remove }) => (
            <div>
              {fields.map(field => {
                return (
                  <Row align="middle" justify="space-between" key={field.key}>
                    <Form.Item
                      name={[field.name, "colli"]}
                      fieldKey={[field.fieldKey, "colli"]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          //whitespace: true,
                          message: "Add unit or delete field."
                        }
                      ]}
                    >
                      <InputNumber placeholder="Colli" />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "packing_type_id"]}
                      fieldKey={[field.fieldKey, "packing_type_id"]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          //whitespace: true,
                          message: "Add unit or delete field."
                        }
                      ]}
                    >
                      <PackingTypes />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "cargo_type"]}
                      fieldKey={[field.fieldKey, "cargo_type"]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          //whitespace: true,
                          message: "Add unit or delete field."
                        }
                      ]}
                    >
                      <CargoTypes />
                    </Form.Item>

                    {/* {fields.length > 0 ? ( */}
                    <Form.Item>
                      <MinusCircleOutlined
                        style={{ margin: 10, color: "red" }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Form.Item>
                    {/* ) : null} */}
                  </Row>
                );
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                >
                  <PlusOutlined /> Add cargo unit
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>

{/*********************************************************************/}
      <Form.Item
        label="Origin"
        name="country_id"
        rules={[{ required: true, message: "Please enter country of origin" }]}
      >
        <Countries />
      </Form.Item>
      <Form.Item
        label="Temperature range"
        name="temperature_range"
        rules={[{ required: false, message: "Please enter temperature range" }]}
      >
        <TemperatureRange />
      </Form.Item>
    </Form>
    </Modal>
  );
};


const INSERT_ORDER = gql`
  mutation CreateOrder($objects: [orders_insert_input!]!) {
    insert_orders(objects: $objects) {
      affected_rows
    }
  }
`;

const UPDATE_ORDER = gql`
  mutation UpdateOrder(
    $id: Int!,
    $objects: orders_set_input!
    $units_data: [order_units_insert_input!]!
    $units_data_delete: [Int!]!
  ) {
    update_orders(_set: $objects, where: { id: { _eq: $id } }) {
      affected_rows
    }
    insert_order_units (
      objects: $units_data
      on_conflict: {
        constraint: order_units_pkey
        update_columns: [colli, cargo_type, packing_type_id]
        where: { order_id: { _eq: $id } }
      }
    ) {
      affected_rows
    }
    delete_order_units (where: { id: { _in: $units_data_delete } }) {
      affected_rows
    }
  }
`;

// companies_view => companies (due to permissions)
// by_pk not acceptable for views: company: companies_by_pk(id: $id) {
// company => company[]
// company: companies_view (where: { id: { _eq: $id } }) {

const GET_SUPPLIER_COUNTRY = gql`
  query GetCompanyById($id: Int!) {
    company: companies_by_pk(id: $id) {
      country_id
    }
  }
`;

const GET_ORDER = gql`
  query OrderById($id: Int!) {
    order: orders_by_pk(id: $id) {
      id
      type
      creation_date
      departure_date
      shipment_id
      supplier_id
      supplier_warehouse_id
      buyer_id
      buyer_warehouse_id
      label
      order_units {
       id
       colli
       packing_type_id
       cargo_type
       order_id
      }
      brutto_weight
      country_id
      temperature_range
    }
  }
`;

const DELETE_ORDER_UNIT = gql`
  mutation DeleteOrderUnit($id: Int!) {
    delete_order_units(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export default OrderForm;
