import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";
import gql from "graphql-tag";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { Tag, Form, InputNumber, Input, Modal, Tabs } from "antd";
import DriversTable from "../users/DriversTable";

const OrderTabPane = (props) => {

 const order = props.src;

 return (
   <>
    <p><b>{order.type === 'E'?"Export":order.type === 'T'?"Transit":"Unknown"}</b></p>
    <p>Created <b>{moment(order.creation_date).format('DD.MM.YYYY')}</b> at: <b>{moment(order.creation_date).format('HH:mm:SS')}</b></p>
    {order.shipment?.truck?.reg_number &&
     <>
      <p>Truck: <Tag><b>{order.shipment?.truck?.reg_number}</b></Tag>
      {order.shipment?.shipper?.name && <>[by {order.shipment?.shipper?.name}]</>}
      </p>
      <Tabs defaultActiveKey="1" tabPosition="left">
        <Tabs.TabPane tab="Drivers" key="1">
          <DriversTable truckId={order.shipment?.truck?.id} readonly={true}/>
        </Tabs.TabPane>
      </Tabs>
     </>
    }
   </>
  );

};

export default OrderTabPane;
