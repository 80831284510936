import React, { Component } from "react";

import TransactionsTable  from "./TransactionsTable";

class TransactionsInc extends Component {
  render() {
    return (
      <TransactionsTable type="INC" />
    );
  }
}

export default TransactionsInc;

