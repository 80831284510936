import React, { Component } from "react";
import { TRANSACTIONS_LOCAL } from "../shared/TransactionTypes";
import TransactionsTable from "./TransactionsTable";

class TransactionsOut extends Component {
  render() {
    return (
      <TransactionsTable type={TRANSACTIONS_LOCAL} />
    );
  }
}

export default TransactionsOut;

