import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Space, Popconfirm, Input, InputNumber, Form, Button, Table } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';

import "../settings/SortableTable.css";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
        {children}
    </td>
  );
};

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const OrdersSortableTable = ({columns, data: _data, helperContainer, form, getNewData }) => {
  console.log("JSON.parse: ", JSON.stringify(_data));
  const originData = _data?JSON.parse(JSON.stringify(_data)).map((e, i) => ({ ...e, index: i, key: i })): null;
  const [data, setData] = useState(originData);
  const [state, setState ] = useState ({ dataSource: data, count: data?.length });
  const [editingKey, setEditingKey] = useState('');
//  const [form] = Form.useForm();

  useEffect (() => {
   setState ({ dataSource: data, count: data?.length });
debugger;
   //getNewData(data);
  }, [data]);

  useEffect (() => {
debugger;
   getNewData(state.dataSource);
  }, [state]);

  const onSortStart = (node, event) => {
debugger;
     // Pause at 0?
     if (node.index === 0) { event.preventDefault(); return(false); }
     console.log('onSortStart: ', node);
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
debugger;
    const { dataSource } = state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el).map((e,i) => {e.position = i; e.index = i; return (e)});
      console.log('Sorted items: ', newData);
      
      setState((prev) => ({ count: prev.count, dataSource: newData }));
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { dataSource } = state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    //const index = dataSource.findIndex(x => x[key_name] === restProps['data-row-key']);
    //console.log("DraggableBodyRow: ", style, {...restProps} );
    //disabled: e.status == 'P', collection: e.status == 'P'?0:1 

    //return <SortableItem className={className} style={style} index={index} {...restProps} collection={index==0?0:1} /*style={{zIndex: 99999999}}*/ />;
    return <SortableItem className={className} style={style} index={index} {...restProps} />;
  };

  console.log('ref: ', helperContainer);

  const { dataSource } = state;

  const DraggableContainer = props => (
      <SortableContainer
        {...props}
        lockAxis="y"
        useDragHandle
        helperClass="row-dragging"
        _helperClass="dragging-helper-class"
        helperContainer={helperContainer}
        _onSortStart={onSortStart}
        onSortEnd={onSortEnd}
        _shouldCancelStart={ () => props.lock ? true : false}
        _shouldCancelStart={(i,j) => { console.log(i, j); return false; }}
      />
    );
  if (!columns) return ('no columns');
  if (!dataSource) return ('no data');

  const l_columns = [
  {
    title: 'Sort',
    _dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: (rec, idx) => ( rec?.status=='P'?<></>:<DragHandle /> ),
  },
  ...columns,
  ];

    console.log("columns: ", l_columns);
    console.log("data: ", data);
    console.log("dataSource: ", dataSource);

debugger;
    return (
     <Form form={form} component={false}>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={l_columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
            _cell: EditableCell,
          },
        }}
      />
     </Form>
    );
}

export default OrdersSortableTable;
