import React from "react";
import { Popconfirm, Button } from "antd";
import { useStoreon } from "storeon/react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";

const OrdersDeleteBtn = () => {
  const { selection, dispatch } = useStoreon("selection");
  const { orderRows } = selection;

  const [delete_order] = useMutation(DELETE_ORDERS, {
    refetchQueries: ["Orders"],
    onCompleted: () => dispatch("selectRows", { model: "order", rowKeys: [] })
  });

  return (
    <Popconfirm
      title="Are you sure delete orders?"
      onConfirm={e => {
        e.preventDefault();
        delete_order({ variables: { id: orderRows } });
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button disabled={!orderRows.length > 0} type="danger" icon={<DeleteOutlined />}>
        {orderRows.length}
      </Button>
    </Popconfirm>
  );
};

const DELETE_ORDERS = gql`
  mutation DeleteOrders($id: [Int!]) {
    delete_orders(where: { id: { _in: $id } }) {
      affected_rows
    }
  }
`;

export default OrdersDeleteBtn;
