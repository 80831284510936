import { createStoreon } from "storeon";
import { storeonDevtools } from "storeon/devtools";
import { persistState } from "@storeon/localstorage";

import ui from "./ui";
import filter from "./filter";
import selection from "./selection";
import auth from "./auth";

// use ugly handmade storage (sessionStorage) for tab.tenant
import tab from "./tab";

export const store = createStoreon([
  selection,
  filter,
  ui,
  auth,
  tab,
  persistState(["auth"]),
  process.env.NODE_ENV !== "production" && storeonDevtools,
]);

