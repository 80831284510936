import React from "react";
import gql from "graphql-tag";
import { saveAs } from "file-saver";
import { useLazyQuery } from "@apollo/client";
import { useStoreon } from "storeon/react";
import { Button, Tooltip } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { GET_ORDERS_BY_SELECTION } from "./graphql/orders_graphql.js";

const OrderExportBtn = () => {
  const { selection } = useStoreon("selection");
  const { orderRows } = selection;

  const [getOrders] = useLazyQuery(GET_ORDERS_BY_SELECTION, {
    onCompleted: data => {
      fetch(process.env.REACT_APP_SERVER_URL + "/xlsx/orders", {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
        method: "POST"
      })
        .then(res => res.blob())
        .then(blob => saveAs(blob, "export.xlsx"))
        .catch(err => {
          console.log(err);
        });
    }
  });

  const onClick = () => {
    getOrders({ variables: { ordersId: orderRows } });
  };

  return (
    <Tooltip title="Export selected orders" placement="bottomLeft">
    <Button
      onClick={onClick}
      disabled={!orderRows.length > 0}
      icon={<ExportOutlined />}
    >
      {orderRows.length}
    </Button>
    </Tooltip>
  );
};

export default OrderExportBtn;

/*
const GET_ORDERS = gql`
  query GetOrders($ordersId: [Int!]) {
    orders(where: { id: { _in: $ordersId } }) {
      id
      departure_date
      shipment_id
      shipment {
        shipper {
          name
        }

        truck {
          reg_number
        }
      }
      seller_id
      seller {
        name
        short_name
      }
      buyer_id
      buyer {
        name
        short_name
      }
      loading_order
      label
      colli
      colli_controle
      colli_transit
      brutto_weight
      netto_weight
      chargeable_weight
      master_awb
      house_awb
      flight
      transit_id
      transit_consignee
      transit_client
      transit_eta
      country {
        alpha2
        name
      }
      cargo_type
      packing_type {
        type
        code
      }
      files_aggregate(where: { hidden: { _eq: false } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  }
`;
*/
