import React from "react";
import { useStoreon } from "storeon/react";
import { Button, version } from "antd";
import DeletedFilesTable from "./DeletedFilesTable.js";

import SheetJS from './xlsx'

const Settings = () => {
  const { dispatch } = useStoreon();

  return (
    <>
    <h1>antd version: {version}</h1>
      <Button type="link"
        onClick={() =>
          dispatch("showForm", { component: "country_lookup_codes" })
        }
      >
        Edit country lookup codes
      </Button>
      <Button type="link"
        onClick={() =>
          dispatch("showForm", { component: "shipment_statuses" })
        }
      >
        Edit shipment statuses 
      </Button>
      <Button type="link"
        onClick={() =>
          dispatch("showForm", { component: "deleted_files" })
        }
      >
        Manage deleted files
      </Button>
      <DeletedFilesTable/>
      <SheetJS/>
    </>
  );
};

export default Settings;
