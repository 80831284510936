import React, { useEffect, useState } from "react";

import moment from "moment";
import locale from "antd/es/locale/en_US";

import { Input, Select, DatePicker, ConfigProvider, Space } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const { Option } = Select;
// same order as get_currency_list_by_tenant() at tenants.js
const CURRENCY = ['EUR', 'USD','CNY', 'AED', 'HUF'];
//const CURRENCY = ['USD', 'EUR', 'CNY', 'AED', 'HUF'];
const RATE_ACCURACY = 4;
//const SPREAD_AMOUNT = 0.004;
      
// start from monday
moment.locale("en-us", {
  week: {
    dow: 1
  }
});

const CurrencyRates = props => {
  const { value, onChange: onChangeParent, currency, direction, ...otherProps } = props;
  const [ rateInfo, setRateInfo ] = useState();

  console.log("CurrencyRates: ", JSON.stringify(currency));

  const disabledDate = (current) => {
    const is_exist = data.p_exchange_rates.find(d => moment(d.exchange_date).isSame(current, 'day') );
    // Can not select days after today and without rates
    return (current && current > Date.now(0)) || !is_exist;
  };

  const calcRate = (p_exchange_rate, currency, p_exchange_spreads) => {
    var rate = {};

    if (!p_exchange_rate || !p_exchange_spreads || currency.from === null || currency.to === null) return rate;

    if (currency.from === currency.to)  rate = { rate_ucb: 1.0, rate_ucb_rev: 1.0, rate_spreaded: 1.0, rate_spreaded_rev: 1.0, spread: 0.0 };
    else {
      // use Abnamro rates as UCB  
      const pair = currency.from+currency.to;
      const pair_rev = currency.to+currency.from;

      console.log("CurrencyRates: ", pair, pair_rev);
      console.log("CurrencyRates: p_exchange_spreads: ", JSON.stringify(p_exchange_spreads));

      const spread = p_exchange_spreads.find((s) => s.pair == pair).spread;
      const spread_rev = p_exchange_spreads.find((s) => s.pair == pair_rev).spread;

      console.log(`calcRate: pairs: ${pair}/${pair_rev} spreads: ${spread}/${spread_rev}`);

      if (!p_exchange_spreads)  {
        console.log('CurrencyRates.calcRate: p_exchange_spreads is null, return');
        return rate;
      }

      // missed in Abnamro pairs (Evgeniy say: no such conversions)
      if (pair === "CNYAED" || pair === "AEDCNY") return rate;

//debugger;

/*
0,5% ��� 
0,5*0,9346/100 = 0,004673 ��������� 0,0047
���� 0,9346-0,0047 = 0,9299
� � ���� 0,9393
*/
      rate = { 
        rate_ucb: p_exchange_rate[pair],
        rate_ucb_rev: p_exchange_rate[pair_rev],
        rate_spreaded: p_exchange_rate[pair] - ( p_exchange_rate[pair] / 100 * spread), 
        rate_spreaded_rev: spread_rev ?
            p_exchange_rate[pair_rev] + ( p_exchange_rate[pair_rev] / 100 * spread_rev) : 
            1 / rate.rate_spreaded,
        spread: spread
      };
      console.log('calcRate: rate: '+JSON.stringify(rate));
    }

    // Do we have bank exchange rates on this pair?
    const info = rate.rate_ucb ? {
      date: p_exchange_rate.exchange_date, 
      rate: parseFloat((rate.rate_ucb).toFixed(RATE_ACCURACY)),
      rate_rev: parseFloat((rate.rate_ucb_rev).toFixed(RATE_ACCURACY)),
      rate_spreaded: parseFloat((rate.rate_spreaded).toFixed(RATE_ACCURACY)),
      rate_spreaded_rev: parseFloat((rate.rate_spreaded_rev).toFixed(RATE_ACCURACY)),
      spread: (rate.rate_ucb === rate.rate_spreaded ? null : rate.spread),
    } :
/*
    { 
//      date: p_exchange_rate.exchange_date, 
//      rate: null,
//      rate_rev: null,
//      rate_spreaded: null,
//      rate_spreaded_rev: null,
      spread: rate.spread,
    }
*/  
    //{}
    null
    ;

    console.log('calcRate: info: '+info?JSON.stringify(info):'null');

    return (info);
  }

  const onChange = (value) => {
    const rates_on_date = data?.p_exchange_rates?.find(d => moment(d.exchange_date).isSame(value, 'day'));
    if (!data || !rates_on_date) { setRateInfo(); return(false); }

    const rate_info = calcRate (rates_on_date, currency, data.p_exchange_spreads);
    setRateInfo(rate_info);
    if (onChangeParent) onChangeParent(rate_info);
  };

  const onPanelChange = (value, mode) => {};
  const onCalendarChange = (p1, p2, p3, p4) => {};

  useEffect(() => {
      console.log("CurrencyRates.useEffect: ", currency, JSON.stringify(rateInfo));
      if (data && currency.from && currency.to) {
        console.log("CurrencyRates.useEffect: got data");
        const rate_info = calcRate (data?.p_exchange_rates[0], currency, data?.p_exchange_spreads);
        setRateInfo(rate_info);
        if (onChangeParent && data) {
          console.log('CurrencyRates.useEffect: call onChange rate_info: '+rate_info?JSON.stringify(rate_info):'null');
          onChangeParent(rate_info);
        }
      } else {
        setRateInfo();
        onChangeParent({});
      }
  }, [currency]);

  const { loading, error, data } = useQuery(GET_EXCHANGE_RATES_ALL, {
    fetchPolicy: "network-only",
  });

/*
  useEffect(() => {
    if (onChange && data) {
        console.log('CurrencyRates.useEffect: call onChange '+JSON.stringify(rateInfo));
        onChange(rateInfo);
    }
  }, [data]);
*/

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  //const data_amounts = Array.from(CURRENCY, x => data.p_exchange_rates.find(y => y.currency == x)?.sum);
  //console.log("CurrencyRates: data:", JSON.stringify(data));

  return (
    <Space direction="vertical">
    <span style={{color: props.disabled?"grey":"black", whiteSpace: "nowrap" }}> ABNAMRO Rate: { rateInfo?.rate && <><b>{rateInfo?.rate.toFixed(RATE_ACCURACY)}</b> &nbsp;/&nbsp; <b>{rateInfo?.rate_rev.toFixed(RATE_ACCURACY)}</b>&nbsp; on </>}
  <ConfigProvider locale={locale}>
     <DatePicker
      disabled={props.disabled || !(data && currency.from && currency.to)}
      value={rateInfo?.date?moment(rateInfo?.date):null}
      size="small"
      allowClear={false}
      disabledDate = {disabledDate}
      onChange={onChange}
      onPanelChange={onPanelChange}
      onCalendarChange={onCalendarChange}
     />
  </ConfigProvider>
  </span>
  <Space direction="horizontal">
    { rateInfo?.spread? <span style={{color: props.disabled?"grey":"black", whiteSpace: "nowrap" }}> Spread: <b>{ Number.parseFloat((rateInfo?.spread).toFixed(RATE_ACCURACY)) }%</b></span>:<></>  }   
    { rateInfo?.rate_spreaded && rateInfo?.rate_spreaded !== 1 && 
        <span style={{color: props.disabled?"grey":"black", whiteSpace: "nowrap" }}> Rate:&nbsp; 
           <span style={{color: direction==="from" && !props.disabled?"black":"grey", fontWeight: "bold"}} >
            { (rateInfo?.rate_spreaded).toFixed(RATE_ACCURACY) }
           </span>
           &nbsp;/&nbsp; 
           <span style={{color: direction!=="from" && !props.disabled?"black":"grey", fontWeight: "bold"}}>
            { (rateInfo?.rate_spreaded_rev).toFixed(RATE_ACCURACY) }
           </span>
        </span>  
    }
  </Space>
  </Space>
   
  );
};

// due to permisions
/*
const GET_EXCHANGE_RATES_LAST = gql`
query GetRatesLast {
  p_exchange_rates: p_exchange_rates_abnamro(order_by: {exchange_date: desc_nulls_last}, limit: 1) {
    EURUSD
    EURCNY
    EURAED
    USDEUR
    USDCNY
    USDAED
    CNYEUR
    CNYUSD
    CNYAED
    AEDEUR
    AEDUSD
    AEDCNY
    exchange_date
  }
}`;
*/
// 
const GET_EXCHANGE_RATES_ALL = gql`
query GetRatesAll {
  p_exchange_rates: p_exchange_rates_abnamro (order_by: {exchange_date: desc_nulls_last}, limit: 120) {
    EURUSD
    EURCNY
    EURAED
    USDEUR
    USDCNY
    USDAED
    CNYEUR
    CNYUSD
    CNYAED
    AEDEUR
    AEDUSD
    AEDCNY
    HUFEUR
    HUFUSD
    EURHUF
    USDHUF
    exchange_date
  }
  p_exchange_spreads {
    pair
    spread
  }
}`;

export default CurrencyRates;
export { CURRENCY as CURRENCY_LIST };

//module.exports.CURRENCY_LIST = CURRENCY;
//module.exports.CurrencyRates = CurrencyRates;

