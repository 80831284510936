import React, { useState } from "react";
import { Popconfirm, Modal, Switch } from "antd";

const { confirm } = Modal;

const MyComponent = (props) => {

  const [checked, setChecked] = useState(props.is_active)

  const handleConfirm = () => {
    if (props.onConfirm) props.onConfirm();
    setChecked(!checked);
  }
  return (
  <Popconfirm 
    title={props?.title}
    onConfirm={handleConfirm}
    okText="Yes"
    cancelText="No"
  >
    <Switch checked={checked}
       checkedChildren={props.checkedChildren}
       unCheckedChildren={props.unCheckedChildren}
       disabled={props.disabled}
    />

  </Popconfirm>
  )
}
export default MyComponent;
