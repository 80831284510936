import React, { useEffect, useState }  from "react";
import { Select } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { CURRENCY_LIST } from "./CurrencyRates";

import { getUserTenant } from "../../auth/authHelper";
import { get_currency_list_by_tenant }  from "../../tenants";

//const CURRENCY = ['USD', 'EUR', 'CNY'];

const CompanyAmounts = props => {
  const { value, onChange, company_id, ...otherProps } = props;

  const { loading, error, data } = useQuery(GET_COMPANY_AMOUNTS, {
    skip: !company_id,
    variables: {
      id: company_id
    }
  });

  useEffect(() => {
    console.dir(data);
    // when change tenant updated with unknows company ... skip this?
    if (onChange && data && data.initiator) { 
        const CURRENCY_LIST_X = get_currency_list_by_tenant(getUserTenant());
        const value = Array.from(CURRENCY_LIST_X, x => data.sum.find(y => y.currency == x)?.sum);
        console.log('CompanyAmounts.useEffect: ['+company_id+'] call onChange '+JSON.stringify(value));
	//onChange(value);
	onChange({amounts: value, limits: (data.initiator.type == 'client')});
	//debugger;
    }
  }, [data]);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

// 2023.12.08 sh: useEffect only?
//debugger;

  const CURRENCY_LIST_X = get_currency_list_by_tenant(getUserTenant());
  //const CURRENCY_LIST_T = get_currency_list_by_tenant(getUserTenant());
  //const CURRENCY_LIST_X = CURRENCY_LIST.filter((v) => CURRENCY_LIST_T.indexOf(v) !== -1);

  const data_amounts = Array.from(CURRENCY_LIST_X, x => data.sum.find(y => y.currency == x)?.sum);
 
  // <Statistic _title="Amount" value={1128} prefix="Amount" suffix="EURO"/> }
  return (
<div id="balance_wrapper" style={{display: "flex", gap: "15px"}}>
    <div id="balance_prefix">Balance:</div>
      {CURRENCY_LIST_X.map((item, key) => {
        return (
          <div id={key}><b>{(data_amounts[key]?data_amounts[key]:0.0).toFixed(2)}</b> {item}</div>
        );
      })}
</div>
  );
};

const GET_COMPANY_AMOUNTS = gql`
query Amounts($id: Int!) {
  sum: p_transactions_sum_amount_ex(where: {initiator_id: {_eq: $id}}) {
    currency
    sum
  }
  initiator: companies_by_pk (id: $id) {
    type
  }
}
`;

/*
 * may be later for speeed up
 *
  return (
<div style={{display: "flex", gap: "15px"}}>
    <div>Balance:</div>
    <div><b>{data.accounts[0].balance_usd}</b> USD</div>
    <div><b>{data.accounts[0].balance_euro}</b> EUR </div>
    <div><b>{data.accounts[0].balance_cny}</b> CNY</div>
</div>
  );

const GET_COMPANY_AMOUNTS = gql`
  query Accounts ($id: Int) {
    accounts (
     where: { company_id: { _eq: $id } },
    )
    {
      balance_usd
      balance_euro
      balance_cny
    }
  }
`;
*/

export default CompanyAmounts;
//<div style={{display: "flex", gap: "10px"}}>
//<div style="display:flex; gap: 10px;">
