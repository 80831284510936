import React, { useEffect } from "react";
import gql from "graphql-tag";
//import { useStoreon } from "storeon/react";
import { useQuery } from "@apollo/client";
import { Table } from "antd";
import FormBtn from "../shared/FormBtn";
import ChangePasswordBtn from "./ChangePasswordBtn";
//import UserDeleteBtn from "./UserDeleteBtn";
import DriverDisconnectBtn from "./DriverDisconnectBtn"
import Contact from "../shared/Contact";

const renderContent = (value, row, index) => {
  const obj = {
    children: value,
    props: {},
  };
  if (!row || !row.id) {
    obj.props.colSpan = 0;
  }
  return obj;
};

const columns = [
  {
    title: "Name",
    dataIndex: "display_name",
    render: (text, row, index) => {
      if (row && row.id) {
        return (row.display_name);
      }
      return {
        children: row.readonly?<>not assigned ... </>:<FormBtn component="user" role="driver" action="add" company_id={row.company_id} truck_id={row.truck_id} driver_index={index}/> ,
        props: {
          colSpan: 2,
        },
      };
    },
  },
  {
    title: "First Name",
    dataIndex: "first_name",
//    render: renderContent,
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
//    render: renderContent,
  },
  {
    title: "Contacts",
    dataIndex: "contacts",
    //render: ref => {ref && ref.map((item, key) => { return ({item.contact}{item.description}); }}
    render: rec => <> { rec && rec.map((r, i) => <Contact key={r.id} record={r} index={i}/>) } </>
  },
  {
    title: "Company",
    dataIndex: ["company","name"],
    render: renderContent,
  },
  {
    title: "",
    render: (text, row, index) => row && row.id && !row.readonly &&
      <div style={{ float: "right" }}>
        <FormBtn component="user" action="edit" id={row.id} company_id={row.company_id}/>
        <ChangePasswordBtn id={row.id} />
        {/*<UserDeleteBtn id={record.id} type="danger" icon="delete" />*/}
        <DriverDisconnectBtn id={row.id} truck_id={row.truck_id} driver_index={index}/>
      </div>
    ,
  }

];

const DriversTable = (props) => {

  const truckId = props.truckId;
  const companyId = props.companyId;
  const readonly = props.readonly;

  console.log("DriversTable: truckId:",truckId, "companyId", companyId);

  const { loading, error, data } = useQuery(GET_TRUCK_DRIVERS, {
    skip: !truckId,
    variables: { id: truckId },
  });

  useEffect(
     () => {
       const onCompleted = data => {
/*
	modifiedData = {...data};
debugger;
  if (data && data.trucks && data.trucks[0]) {
   data.trucks[0].company = {users: []};
   if (data.trucks[0].user_1) modifieDdata.trucks[0].company.users[0]= {...data.trucks[0].user_1};
   if (data.trucks[0].user_2) modifieDdata.trucks[0].company.users[1]= {...data.trucks[0].user_2};
  }
       }
       const onError = error => {
         return ( <div>{error}</div>)
       }
       if (onCompleted || onError) {
         if (onCompleted && !loading && !error) {
           onCompleted(data)
         } else if (onError && !loading && error) {
           onError(error)
         }
       }
*/
     }
    },
     [loading, data, error]
   );

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const emulateData = (d) => {
    var m = JSON.parse(JSON.stringify(d));
    //var m = Object.assign({}, d);
    //var m = {...d};
    // we need deep copy due preventExtensions on data
    var m = JSON.parse(JSON.stringify(d));
    m.trucks[0].company = {users: [{...d.trucks[0]?.user_1}, {...d.trucks[0]?.user_2}]};
debugger;
    if (!m.trucks[0].company.users[0].company_id) m.trucks[0].company.users[0] = {company_id: m.trucks[0].company_id};
    if (!m.trucks[0].company.users[1].company_id) m.trucks[0].company.users[1] = {company_id: m.trucks[0].company_id};
    m.trucks[0].company.users[0].truck_id =  m.trucks[0].id;
    m.trucks[0].company.users[1].truck_id =  m.trucks[0].id;
    m.trucks[0].company.users[0].readonly = readonly;
    m.trucks[0].company.users[1].readonly = readonly;
/*
    m.trucks[0].company = {users: []};
    if (d.trucks[0]?.user_1) m.trucks[0].company.users[0] = d.trucks[0]?.user_1;
    if (d.trucks[0]?.user_2) m.trucks[0].company.users[1] = d.trucks[0]?.user_2;
*/
    return m;
  }
  //const modifiedData = {...data, company: {users: [data.trucks[0]?.user_1, data.trucks[0]?.user_2]}};
  const modifiedData = emulateData(data);
  console.log("modifiedData:", modifiedData);

  return (
    <>
      { (modifiedData.trucks[0].company.users.length < 2 && companyId) &&
      <FormBtn component="user" role="driver" action="add" company_id={companyId}/>
      }
      <Table
        pagination={false}
        rowKey="id"
        columns={columns}
        _dataSource={data && data.trucks && data.trucks[0]?.company?.users}
        dataSource={modifiedData && modifiedData.trucks && modifiedData.trucks[0]?.company?.users}
        style={{ margin: 0 }}
        size="small"
      />
    </>
  );
};

export const FRAGMENT_USERS = gql`
fragment driver on users {
       id
       first_name
       last_name
       display_name
       company_id
       company {
        name
        short_name
        city
       }
       contacts {
        id
        contact
        description
        user_id
       }
}
`;

export const GET_TRUCK_DRIVERS = gql`
  ${FRAGMENT_USERS}
  query Drivers ($id: Int) {
    trucks (where: { id: { _eq: $id } }) {
      id
      reg_number
      company_id
      user_1 {
        ...driver
      }
      user_2 {
        ...driver
      }
    }
} 
`;

const GET_TRUCK_DRIVERS_ALL = gql`
  query Drivers ($id: Int) {
    trucks (where: { id: { _eq: $id } }) {
      user
      company {
       users (where: { default_role: { _eq: "driver" } }) {
         ...driver
     }
    }
   }
}
`;

export default DriversTable;
