import gql from "graphql-tag";

export const FRAGMENT_SHIPMENTS = gql`
fragment shipments_fragment on shipments {
      id
      type
      created_at
      departure_date
      shipper_id
      active
      status
      orders {
        cargo {
          code
          type
        }
      }
      shipper {
        name
      }
      sub_shipper {
        name
      }
      truck {
        reg_number
      }
      trailer {
        reg_number
      }
      warehouse {
        id, name, city, street, house_no
      }
      files: files_aggregate(where: { hidden: { _eq: false } }) {
        aggregate {
          count(columns: id)
        }
      }
      state
      shipments_histories {
        status
        created_at
      }
    }
`;

export const FRAGMENT_SHIPMENT_STATUSES = gql`
fragment shipment_statuses_fragment on shipment_statuses {
      status
      position
      info
      description
 }
`;
