import React from "react";
import { useStoreon } from "storeon/react";
import { Button, Tooltip } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

const FormBtn = (props) => {
  const { dispatch } = useStoreon();
  const { type, icon, icon_only, hint, ...otherProps } = props;

  const handleClick = () => {
    if (props.id) { dispatch("select", { model: props.component, id: props.id }); }
    if (props.company_id) { dispatch("select", { model: "company", id: props.company_id }); }
    if (props.truck_id) { dispatch("select", { model: "truck", id: props.truck_id }); }
    if (props.trailer_id) { dispatch("select", { model: "trailer", id: props.trailer_id }); }

    dispatch("deselect", { model: "new_trailer" });
    dispatch("showForm", { ...props });
  };

  // TODO: use Tooltip only if hint exists
  return (
    <Tooltip placement="left" mouseLeaveDelay="0" title={hint} >
    <Button
      {...otherProps}
      onClick={handleClick}
      type={type||"link"}
      icon={icon||props.action === "add" ? <PlusOutlined /> : <EditOutlined />}
    > 
      {!icon_only && `${props.action === "add" ? "Add" : "Edit"} ${
        props.label ? props.label : ""
      }`}
    </Button>
    </Tooltip>
  );
};

export default FormBtn;
