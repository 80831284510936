import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";
import { AUTH_TOKEN, REFRESH_TOKEN } from "../constant";
import { getUserInfo, setUserRole, setUserTenant, getUserTenant } from "../auth/authHelper.js";
import { Form, Spin, Typography, Alert, Input, Button, Select, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom"; 
import { useApolloClient } from '@apollo/client';

const { Text } = Typography;
const Option = Select.Option;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 }
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 8 }
};

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
}

const LoginPage = props => {
  const { dispatch, auth, tab } = useStoreon("auth", "tab");
  const [form] = Form.useForm();
  const [, forceUpdate] = useState(); // To disable submit button at the beginning.
  const q_params = useQuery();
  const [state, setState] = useState({error: null, loading: false});
  const client = useApolloClient();

  //console.log("LoginPage: ", JSON.stringify(props));

  useEffect(() => {
    //console.log("LoginPage.useEffect: props: ", JSON.stringify(props));
    //console.log("LoginPage.useEffect: location.state: ", props.location.state);
    //console.log("LoginPage.useEffect: q_params: ", q_params.get('mode'), " from: ", q_params);
    if (props.location.state?.mode === "clear" || q_params.get('mode') === 'clear') {
       console.log("LoginPage.useEffect: try to clear auth data");
       if (auth.authenticated) {
         // TODO: form.setFieldsValue({username: auth.login});
         // but disabled => form.isFieldsTouched() is not affected 
         localStorage.removeItem(AUTH_TOKEN);
         localStorage.removeItem(REFRESH_TOKEN);
         dispatch("setAuthData", { authenticated: false});
         // setUserTenant();
         dispatch("setTabData", {});
       }
       //props.client.clearStore();

       if (props.location.state) props.location.state = null;
       if (props.location.search) props.history.replace('/login');
       setState((prev) => ({...prev, error: "Your session is expired. Please relogin."}));
    } else {
      // use store/@init
//      const t = getUserTenant();
//      if (!getUserTenant()) setUserTenant(tab.tenant||process.env.REACT_APP_DEFAULT_TENANT);
    }
  }, [props, auth.authenticated, dispatch]);

  const onFieldsChange = () => {
    if (state.error) setState((prev) => ({...prev, error: null }));
    // console.log("onFieldsChange: state=", state);
  }
  const onFinishFailed = errorInfo => {
    console.log("onFinishFailed:");
    console.log(errorInfo);
    // setState((prev) => ({...prev, error: errorInfo }));
    setState((prev) => ({...prev, error: "Form validation error" }));
  };

  const onFinish = values => {
    console.log("LoginPage.onFinish: fired");
    setState((prev) => ({...prev, loading: true }));
    fetch(process.env.REACT_APP_SERVER_URL + "/auth/login", {
      headers: {
        "Content-Type": "application/json",
        "cache-control": "no-cache"
      },
      body: JSON.stringify(values),
      method: "POST"
    })
      .then(res => res.json())
      .then(res => {
        setState((prev) => ({...prev, loading: false }));
        if (res.error || res.errors) {
          console.log("fetch ok, but response has some error: ", res);
          // message.error(res.error);
          setState((prev) => ({...prev, error: res.error }));
          //if (props?.history) props.history.push("login");
        }
        if (res.jwt_token !== undefined && res.refresh_token !== undefined) {

          //TODO validate token?
          localStorage.setItem(AUTH_TOKEN, res.jwt_token);
          localStorage.setItem(REFRESH_TOKEN, res.refresh_token);

          const info = getUserInfo(res.jwt_token);
          if (!info) { 
             setState((prev) => ({...prev, error: "Auth error" }));
          } else {
            //dispatch("setAuthData", { authenticated: true, login: values.username });
            dispatch("setAuthData", {
                authenticated: true,
		login: values.username,
		user_id: info['x-hasura-user-id'],
		user_role: info['x-hasura-role'],
		company_id: info['x-hasura-company-id'],
		company_role: info['x-hasura-company-role'],
            });
            // clear login or login due expired token
            const tenant = getUserTenant();
console.log('*********** 0.0 ***************, tenant:', tenant, ', tab:', tab?tab.tenant:'unknown');
            if (!tenant) {
console.log('*********** 0 ***************, no tenant, set default');
// use only store mock
//              setUserTenant(process.env.REACT_APP_DEFAULT_TENANT);
              dispatch("setTabData", {               
                tenant: process.env.REACT_APP_DEFAULT_TENANT,
              });
            }

console.log('*********** 1 ***************');
            setUserRole(info['x-hasura-role']);

console.log('*********** 2 ***************, from:', props.location.from);
            //if (props.location.pathname === '/login') props.history.push('/');
            props.history.replace(props.location.from?props.location.from:'/');
console.log('*********** 3 ***************');


/*
 * 
            if (getUserTenant() !== values.tenant) {
              //alert(`new tenant: client.clearStore: was [${getUserTenant()}] => [${values.tenant}]`);

debugger;
              // reset the cache without refetching active queries
              client.clearStore().then(() => {
                alert("client.clearStore: done");
                client.cache.reset();
                client.cache.evict({ id: "ROOT_QUERY"}); // , fieldName: "Companies" 
                client.cache.gc();

                client.queryManager.cache.reset();
                if (client.localstate?.cache) client.localstate.cache.reset();

              });
              setUserTenant(values.tenant);

              //alert(`new tenant: client.clearStore: now [${getUserTenant()}]`);
            }
*/
          }
        }
      })
      .catch(err => {
        console.error("LoginPage: fetch.catch(err): ", JSON.stringify(err));
        setState((prev) => ({...prev, loading: false, error: 'Internal error, please contact your administrator' }));
      })
      .finally(() => {
        console.log("LoginPage: finally: ", state.loading);
        // moved to then and catch, we must change state before replace location
        // add: why?
        //setState((prev) => ({...prev, loading: false }));
      });
  };

  if (auth.authenticated) {
    return <div>You are already logged in</div>;
  }

  return (
    <Spin spinning={state.loading} delay={100} wrapperClassName="login_form">
    <Form form={form}
      {...layout}
      name="login_form"
      onFieldsChange={onFieldsChange}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <center style={{padding: '10px'}}><Text type="danger">{state.error}</Text>&nbsp;</center>

      <Form.Item label="Username" name="username" rules={[{ required: true }]}>
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item label="Password" name="password" rules={[{ required: true }]}>
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          autoComplete="off"
        />
      </Form.Item>     
      <Form.Item {...tailLayout} shouldUpdate={true}>
        {() => (
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}
            disabled={
              !form.isFieldsTouched(true) ||
              form.getFieldsError().filter(({ errors }) => errors.length).length
            }
        >
          Log in
        </Button>
        )}
      </Form.Item>
    </Form>
    </Spin>
  );
};

export default LoginPage;

/*
            _disabled={
              !form.isFieldsTouched(true) ||
              form.getFieldsError().filter(({ errors }) => errors.length).length
            }

*/