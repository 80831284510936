import React, { Component } from "react";

import UsersTable from "./UsersTable";

export default class UserPage extends Component {

  render() {
    //return <div>User table placeholder</div>;
    return <UsersTable mode="all" />;
  }
}
