import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Radio, DatePicker, Space, Checkbox } from "antd";
import moment from "moment";
import { useStoreon } from "storeon/react";
// const { RangePicker } = DatePicker;
import Counterparts from "../shared/Counterparts";
import CompanySelect from "../shared/CompanySelect";

import { getUserTenant } from "../../auth/authHelper";
import { get_currency_list_by_tenant }  from "../../tenants";

// start week from Monday
moment.locale("en-us", { week: { dow: 1 } });

// constants //////////////////////////////////////////////////////
const dateFormat_db = "MM.DD.YYYY";
const dateFormat = "DD.MM.YYYY";
const weekFormat = "WW.YYYY";
const monthFormat = "MM.YYYY";
const yearFormat = "YYYY";

const pickerDateFormat = {
  day: dateFormat,
  week: weekFormat,
  month: monthFormat,
  year: yearFormat
};

const DEFAULT_PICKER = "year";
const DEFAULT_SUB_PICKER = "day";
const DEFAULT_RANGE_TYPE = "none";

const SINGLE = true;

const DEFAULT_PARAMS = {
  picker: DEFAULT_PICKER,
  sub_picker: DEFAULT_SUB_PICKER,
  range_type: DEFAULT_RANGE_TYPE,
  format: dateFormat,
  picker_format: pickerDateFormat[DEFAULT_PICKER],
  company_id: null,
  from: {
    date: moment(),
    dat1: moment().startOf(DEFAULT_PICKER), //.format(dateFormat_db),
    dat2: moment().endOf(DEFAULT_PICKER), //.format(dateFormat_db)
  },
  to: null,

  is_fill_empty: true,
  is_show_trend: false,
  is_show_grouped: false,
};

//////////////////////
const getStep = (params) => {
  console.log("getStep: begin", params?.picker + "/" + params?.sub_picker);

  // to calculate using the average, you need to add 
  // a semicolon and an average calculation mask
  // this will be either a month or a week
  //
  // year:
  //  Mon -> Mon;MM.YYYY
  // month: 
  //  DD -> DD;MM.YYYY
  //  WW -> WW;WW.YYYY
  // week:
  //  ID Dy (DD.MM) -> ID Dy (DD.MM);WW.YYYY

  switch (params?.picker) {
    case "year":
      return "Mon;MM.YYYY";
    case "week":
      return "ID Dy (DD.MM);WW.YYYY"; // "ID";
    case "month":
      return params.sub_picker === "day" ? "DD;MM.YYYY" : "WW;WW.YYYY";
    default:
      return null;
  }
  
};

const customStartEndFormat = (chartParams, type) => {
  console.log("customStartEndFormat: type:", type);
  return !chartParams[type]?.date?'':`${chartParams[type].dat1.format(dateFormat)} ~ ${chartParams[type].dat2.format(dateFormat)}`;
};

//////////////////////

const ParamsCrt = ({ onChange, value, ...othreProps }) => {
  // TODO: use value?
  //const [chartParams, setChartParams] = useState(value? value : DEFAULT_PARAMS);
  const { auth } = useStoreon("auth");
  
  useEffect(() => {
    console.log("Params.useEffect (init): fired");
    //if(onChange) onChange(chartParams);
  }, []);

  // handlers: begin //////////////////////////////////
  const onChangePicker = (v) => {
    console.log("onChangePicker:", v, "format:", pickerDateFormat[v]);
    //console.log("chartParams:", JSON.stringify(chartParams));

    const new_params = {...value, 
      picker: v, 
      picker_format: pickerDateFormat[v],
      from: !value.from?.date? null : { 
        date: value.from.date,
        dat1: moment(value.from.date).startOf(v),
        dat2: moment(value.from.date).endOf(v),
      },
      // reset "to"
      range_type: DEFAULT_RANGE_TYPE, 
      to: null
    };
    if (onChange) onChange(new_params);
  };

  const onChangeRangeType = (v) => {
    console.log("onChangeRangeType:", v);
    const new_params = {...value, 
      range_type: v,      
      to: {}
    }; 

    switch (v) {
      case "none": 
        new_params.to = null;
        break;
      case "prev":
        new_params.to.date = moment(new_params.from.date)
          .add(-1, new_params.picker + "s")
          .startOf(new_params.picker);
          // don`t add break due next block used fo both cases
      case "custom":
        new_params.to.dat1 = !new_params.to ? null : moment(new_params.to.date).startOf(new_params.picker);//.format(dateFormat_db);
        new_params.to.dat2 = !new_params.to ? null : moment(new_params.to.date).endOf(new_params.picker);//.format(dateFormat_db);
    }

    if (onChange) onChange(new_params);     
  };

  const onChangeDatePicker = (v, type) => {
    console.log("onChangeDatePicker: begin, v=", v);

    console.log("1.1: ", `${moment(v).startOf(value.picker).format(dateFormat)}`);
    console.log("1.2: ", `${moment(v).endOf(value.picker).format(dateFormat)}`);

    const new_params = {...value, 
      [type]: {
        date: v,
        dat1: !v ? null : moment(v).startOf(value.picker),//.format(dateFormat_db),
        dat2: !v ? null : moment(v).endOf(value.picker)//.format(dateFormat_db)
      }
    };

    if (onChange) onChange(new_params);     
  };

  const onChangeMonthStep = (v) => {
    console.log("onChangeMonthStep:", v);
    const new_params = {...value, 
      sub_picker: v, 
    };

    if (onChange) onChange(new_params);     
  };

  const onChangeFillEmpty = (e) => {
    console.log(`onChangeFillEmpty: checked = ${e.target.checked}`);
    const new_params = {...value, 
      is_fill_empty: e.target.checked, 
    };
    if (onChange) onChange(new_params);     
  };

  const onChangeShowTrend = (e) => {
    console.log(`onChangeShowTrend: checked = ${e.target.checked}`);
    const new_params = {...value, 
      is_show_trend: e.target.checked
    };
    if (onChange) onChange(new_params);     
  };

  const onChangeShowGrouped = (e) => {
    console.log(`onChangeShowGrouped: checked = ${e.target.checked}`);
    const new_params = {...value, 
      is_show_grouped: e.target.checked
    };
    if (onChange) onChange(new_params);     
  };

  const onChangeCompany = (id) => {
    console.log("onChangeCompany: ", id);
    const new_params = {...value, 
      company_id: id
    };
    if (onChange) onChange(new_params);     
  };


  // handlers: end ////////////////////////////////////

  /////////////////////////////////////////////////////
  return (
    <>
      <Space direction="horizontal" >
        <Checkbox checked={value.is_fill_empty} onChange={onChangeFillEmpty}>
          fill empty
        </Checkbox>
	
        { /* value.transaction_type !== 'COM' && */ (<><CompanySelect
          role="transaction_initiator"
          role_text="initiator"
          size="small"
          my_company_id={auth.company_id}
          allowClear={true}
          style={{width: "200px"}}
          onChange={onChangeCompany}
          value={value.company_id}
        />
        </>)}

        <Checkbox
          checked={value.is_show_trend}
          onChange={onChangeShowTrend}
        >
          trend
        </Checkbox>
        <Checkbox
          checked={value.is_show_grouped}
          onChange={onChangeShowGrouped}
        >
          group
        </Checkbox>

    {/*/////////////////////////////////////////////*/}
        <Radio.Group
          size="small"
          value={value.picker}
          onChange={(e) => onChangePicker(e.target.value)}
          buttonStyle="solid"
        >
          <Radio.Button value="week">week</Radio.Button>
          <Radio.Button value="month">month</Radio.Button>
          <Radio.Button value="year">year</Radio.Button>
        </Radio.Group>

        <Radio.Group
          disabled={value.picker !== "month"}
          size="small"
          defaultValue_={value.sub_picker}
          onChange={(e) => onChangeMonthStep(e.target.value)}
          buttonStyle="solid"
          value={value.sub_picker}
        >
          <Radio.Button value="day">day</Radio.Button>
          <Radio.Button value="week">week</Radio.Button>
        </Radio.Group>

        <DatePicker
          size="small"
          defaultValue_={moment()}
          format={value.picker_format}
          picker={value.picker}
          value={value.from?.date}
          onChange={(e) => {
            onChangeDatePicker(e, "from");
          }}
          disabledDate={(current) => {
            // disable future
            return current && moment().diff(current, "days") < 0;
          }}
        />
{/*
        <Radio.Group
          size="small"
          defaultValue_={DEFAULT_RANGE_TYPE}
          onChange={(e) => onChangeRangeType(e.target.value)}
          buttonStyle="solid"
          value={value.range_type}
        >
          <Radio.Button value="none">none</Radio.Button>
          <Radio.Button value="prev">prev</Radio.Button>
          <Radio.Button value="custom">custom</Radio.Button>
        </Radio.Group>

        {value.range_type === "custom" && (
          <DatePicker
            size="small"
            defaultValue_={moment()}
            format={value.picker_format}
            picker={value.picker}
            onChange={(e) => onChangeDatePicker(e, "to")}
            disabledDate={(current) => {
              return current && moment().diff(current, "days") < 0;
            }}
          />
        )}
*/}
      </Space>
   </>
  );
};

export default ParamsCrt;
export {
  customStartEndFormat,
  getStep,
  dateFormat_db,
  dateFormat,
  weekFormat,
  monthFormat,
  yearFormat,
  pickerDateFormat,
  DEFAULT_PARAMS
}
