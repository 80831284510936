import React, { Component } from "react";
import { Select } from "antd";
import gql from "graphql-tag";
import { Query } from "@apollo/react-components";

const Option = Select.Option;

class PackingTypes extends Component {
  render() {
    return (
      <Query query={GET_PACKING_TYPES}>
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;

          return (
            <Select
              {...this.props}
              allowClear
              placeholder="Select packing type"
            >
              {data.packing_types.map((item, key) => {
                return (
                  <Option key={key} value={item.id}>
                    {item.type}
                  </Option>
                );
              })}
            </Select>
          );
        }}
      </Query>
    );
  }
}

const GET_PACKING_TYPES = gql`
  query PackingTypes {
    packing_types(order_by: { type: asc }) {
      id
      type
      code
    }
  }
`;

export default PackingTypes;
