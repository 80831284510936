import React from "react";
import { Button } from "antd";

export const ApiButton = () => {
  const callApi = () => {
    fetch(process.env.REACT_APP_SERVER_URL + "/healthz", {
      headers: {
        "Content-Type": "application/json",
        "cache-control": "no-cache"
      },
      method: "GET"
    })
      .then(res => console.log(res))
      .catch(err => {
        console.log(err);
      });
  };

  return <Button onClick={callApi}> Api health </Button>;
};
