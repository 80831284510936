import gql from "graphql-tag";
import { FRAGMENT_SHIPMENTS, FRAGMENT_SHIPMENT_STATUSES } from "./shipments_fragment_graphql";

export const GET_SHIPMENTS = gql`
  ${FRAGMENT_SHIPMENTS}
  query Shipments($condition: shipments_bool_exp!)
  {
    shipments (
      order_by: { departure_date: desc }
      where: $condition
    ) {
      ...shipments_fragment
    }
    shipment_statuses (
      order_by: { position: asc }
    ) {
      status
      position
      info
      description
    }
  }
`;

export const GET_SHIPMENTS_BY_SELECTION = gql`
  ${FRAGMENT_SHIPMENTS}
  ${FRAGMENT_SHIPMENT_STATUSES}
  query Shipments($shipmentsId: [Int!]) {
    shipments (
      order_by: { departure_date: desc }
      where: { id: { _in: $shipmentsId } }
    ) {
       ...shipments_fragment
    }
    shipment_statuses (
      order_by: { position: asc }
    ) {
      ...shipment_statuses_fragment
    }
  }
`;

