import React, { Component, useState, useEffect } from "react";
import { Form, Input } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const RegNumInput = (props) =>  {
  const { label, name, country_id, company_id, form, ...otherProps } = props;
  //const [form] = Form.useForm();

  const { loading, error, data } = useQuery(GET_RULES,{
   variables: {
      //countryId: country_id,
      companyId: company_id,
    },
    skip: !country_id && !company_id
   }
  );

  useEffect(() => {
     console.log('useEffect: I');
  });

  useEffect(() => {
     console.log('useEffect: II');
  }, []);

  useEffect(() => {
     console.log('useEffect: III');
    if (data) {
     console.log('useEffect: III.I', name, data);
      //form.setFieldsValue(data.truck);
      form.validateFields([name]);
    }
    console.log('useEffect: III.II');
  }, [data]);

  //if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const validateCustom  = async (rule, value) => {
     console.log('validateCustom: ', company_id, data, value);
     var c = data?.companies[0].country;
     if (!company_id || !data || !c.rules.length || !value) {
       console.log('validateCustom: ... skip');
       return;
     }
     console.log('validateCustom:', rule, value);
     console.log('rules: ', c.rules.length);
     for (var i=0; i<c.rules.length; i++) {
      console.log(`rule [${i}]: `, c.rules[i].rule);
      //console.log('test: ', RegExp(data.rules[i].rule).test(value));
      console.log("before test");
      var r = new RegExp(c.rules[i].rule); 
      if (r.test(value)) {
       console.log("Ok");
       return;
      }
     }
     throw new Error('Bad registration number format');
  };

  return (
      <Form.Item 
        {...otherProps}
        normalize={value => (value || '').toUpperCase()} 
        label={label}
        name={name}
        rules={[{
            required: true,
            message: 'Please input reg number!',
          },
/*
          {
            transform: (v) => v.replace(/\s/g, '').toLowerCase(),
            message: 'Please input correct reg number!',
            pattern: pattern,
          },                    
*/
          {
            _transform: (v) => v.replace(/\s/g, '').toUpperCase(),
            message: 'Please input correct reg number!',
            validator: validateCustom, 
          },          
        ]}
      >
        <Input addonAfter={company_id && data?.companies[0].country.alpha3}  disabled={loading?true:false}/>
      </Form.Item>
  );
}

const GET_RULES = gql`
 query RegNumRules (
    $companyId: Int
  ) {
    companies (
      where: { id: { _eq: $companyId } })
    {      
     country  {
      id
      alpha3
      rules: country_reg_num_rules 
      {
       id
       rule
      }      
    }
   }
  }
`;

export default RegNumInput;
