import moment from "moment";

export default (store) => {
  // default: filter is on with 3 days range
  store.on("@init", () => ({
    filter: {
      shipment: {
        isOn: true,
        // range: "",
// debug only
// TODO:  -3 
// 
        range: [moment().add(-300, 'days').startOf('day'), moment().endOf('day')],
        shipperId: undefined,
        active: true,
        state: null,
      },
    },
  }));

  store.on("setShipmentFilter", ({ filter }, args) => {
    return {
      filter: {
        ...filter,
        shipment: {
          isOn: true,
          range: args.range,
          shipperId: args.shipperId,
          active: args.active,
          state: args.state
        },
      },
    };
  });

  store.on("resetShipmentFilter", ({ filter }) => {
    return {
      filter: {
        ...filter,
        shipment: {
          isOn: false,
          range: "",
          shipperId: undefined,
          active: true,
          state: null,
        },
      },
    };
  });
};
