import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { Form, Modal, Checkbox, Button, Input } from "antd";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import DatePicker from "../shared/DatePicker";
import TruckSelect from "../shared/TruckSelect";
import TralersByTruckSelect from "../shared/TrailersByTruckSelect";
import CompanySelect from "../shared/CompanySelect";
import WarehouseSelect from "../shared/WarehouseSelect";
import ShipmentType from "../shared/ShipmentType";
import * as CONST from './Constants';
import { isShipper, isAdmin } from "../../auth/authHelper";

import moment from "moment";

const ShipmentForm = () => {
  const [form] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { action, component } = ui.visibleForm;
  const { shipmentId } = selection;
  const [ shipmentType, setShipmentType ] = useState(null);

  useEffect(() => {
    if (action === "add") {
      form.resetFields();
      form.setFieldsValue({ departure_date: moment().toDate() });
      form.setFieldsValue({ active: true });
      form.setFieldsValue({ type: CONST.SHIPMENT_TYPE_TRUCK_KEY });
      setShipmentType(CONST.SHIPMENT_TYPE_TRUCK_KEY); //default
    }

    if (action === "edit" && data) {
      setShipmentType(data.shipment.type);
      form.setFieldsValue(data.shipment);
      // virtual 
      if(data.shipment.warehouse) form.setFieldsValue({"company_id": data.shipment.warehouse.company.id});
    }
  });

  const closeForm = () => {
    dispatch("hideForm");
  };

  const [mutate] = useMutation(
    action === "edit" ? UPDATE_SHIPMENT : INSERT_SHIPMENT,
    {
      variables: { id: action === "edit" ? shipmentId : undefined },
      refetchQueries: ["Shipments"],
      onCompleted: () => closeForm()
    }
  );

  const { loading, error, data } = useQuery(GET_SHIPMENT, {
    skip: !(action === "edit" && component === "shipment"),
    variables: {
      id: shipmentId
    }
  });

  useEffect(() => {
    const onCompleted = (data) => {
//debugger;
    //if (data) data.shipment.company_id = data.shipment.warehouse.company.id;
};
    const onError = (error) => { /* magic */ };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
debugger;
        delete values.__typename;
	// TODO: while this one ...
        delete values.company_id;
        var v = { objects: values, id: action === "edit" ? shipmentId : undefined };
        console.log("handleSubmit: ", v);
        mutate({ variables: v });
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Modal
      title="Shipment form"
      name="shipment_form"
      visible={component === "shipment"}
      onCancel={closeForm}
      onOk={handleSubmit}
    >
      <ShipmentInnerForm form={form} type={shipmentType} />
    </Modal>
  );
};

//const ShipmentInnerForm = ({ form, shipmentType }) => {
const ShipmentInnerForm = (props) => {
  const { form } = props;
  const [shipmentType, setShipmentType ] = useState(props.type);

  const handleChangeType = (v) => {
     setShipmentType(v); 
  }

  useEffect(() => {
      //debugger;
     // var v; 
      //if ((v = form.getFieldValue('type'))) setShipmentType(v);
      var v = form.getFieldValue('type');
      var vv = shipmentType;
      var vvv = props.type;
     setShipmentType(vvv); 
  }, [props.type]);

  return (
    <Form name="shipment_form" layout="vertical" form={form}>
      <Form.Item
        label="type"
        name="type"
        rules={[{ required: true, message: "Please select shipment type" }]}
      >
        <ShipmentType
          _defaultValue={shipmentType||CONST.SHIPMENT_TYPE_TRUCK_KEY}
          onChange={handleChangeType}
          onSelect={handleChangeType}
        />
      </Form.Item>
      <Form.Item
        label="Loading date"
        name="departure_date"
        rules={[{ required: true, message: "Please select Loading date" }]}
      >
        <DatePicker />
      </Form.Item>
      { shipmentType === CONST.SHIPMENT_TYPE_AVIA_KEY  && <ShipmentChunkAvia form={form} /> }
      { shipmentType === CONST.SHIPMENT_TYPE_TRUCK_KEY  && <ShipmentChunkTruck form={form} /> }
      { shipmentType === CONST.SHIPMENT_TYPE_WAREHOUSE_KEY  && <ShipmentChunkWarehouse form={form} /> }
      <Form.Item
        name="active"
        valuePropName="checked"
        rules={[{ required: false, message: "Please set shipment status" }]}
      >
        <Checkbox>Active</Checkbox>
      </Form.Item>
    </Form>
  );
};

const ShipmentChunkAvia = ({ form }) => {
   return(<></>); 
}

const ShipmentChunkTruck = ({ form }) => {
   const [ numTrailers, setNumTrailers ] = useState(null);

   const handlerSelectTruck = (v, o) => {
      setNumTrailers(o?o["data-num-trailers"]:null);
      form.setFieldsValue({"trailer_id": undefined});
   }
   //<TralersByTruckSelect truckId={numTrailers>0?form.getFieldValue("truck_id"):null} _disabled={numTrailers>0?"false":"true"}/>
   return(
     <>
      {!isShipper() &&
      <Form.Item
        label="Shipper"
        name="shipper_id"
        rules={[{ required: true, message: "Please select shipper" }]}
      >
        <CompanySelect role="shipper" />
      </Form.Item>
      }
      <Form.Item
        label="Truck"
        name="truck_id"
        rules={[{ required: true, message: "Please select truck" }]}
      >
        <TruckSelect onSelect={handlerSelectTruck} onClear={handlerSelectTruck}/>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.truck_id !== currentValues.truck_id
        }
      >
        {() => {
          return (
            <Form.Item
              label="Trailer"
              name="trailer_id"
              rules={[{ required: false, message: "Please select trailer" }]}
            >
              <TralersByTruckSelect truckId={form.getFieldValue("truck_id")} numTrailers={numTrailers}/>
            </Form.Item>
          );
        }}
      </Form.Item>
    </>
   );
}

const ShipmentChunkWarehouse = ({ form }) => {
   const [ companyId, setCompanyId ] = useState(null);

   return(
     <>
      <Form.Item
        label="Warehouse company"
        name="company_id"
        _name={["warehouse", "company", "id"]}
        rules={[{ required: true, message: "Please select warehouse company" }]}
      >
        <CompanySelect role="warehouse" onChange={(v) => { setCompanyId(v); }}/>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.company_id !== currentValues.company_id
        }
      >
        {() => {
          return (
            <Form.Item
              label="Warehouse"
              name="warehouse_id"
              rules={[{ required: false, message: "Please select warehouse" }]}
            >
              <WarehouseSelect company_id={form.getFieldValue("company_id")} disabled={form.getFieldValue("company_id")?false:true}/>
            </Form.Item>
          );
        }}
      </Form.Item>

    </>
   );
}

const INSERT_SHIPMENT = gql`
  mutation CreateShipment($objects: [shipments_insert_input!]!) {
    insert_shipments(objects: $objects) {
      affected_rows
    }
  }
`;

const UPDATE_SHIPMENT = gql`
  mutation UpdateShipment($id: Int!, $objects: shipments_set_input!) {
    update_shipments(_set: $objects, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const GET_SHIPMENT = gql`
  query ShipmentById($id: Int!) {
    shipment: shipments_by_pk(id: $id) {
      id
      type
      active
      departure_date
      shipper_id
      sub_shipper_id
      truck_id
      trailer_id
      warehouse_id
      company_id: warehouse_id
      warehouse {
        company {
          id
        }
      }
    }
  }
`;

export default ShipmentForm;
