// import XLSX from 'xlsx';
// not tested ... fixed due docker issue
import * as XLSX from 'xlsx/xlsx.mjs';

import DataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';

import React, { Component, useState, useEffect } from 'react';
import { Pagination, Tabs, Button, Input, Table, Row, Col } from 'antd';

const { TextArea } = Input;
const TabPane = Tabs.TabPane;
const { Column, ColumnGroup } = Table;
// react-native-fs
//import { writeFile, readFile, DocumentDirectoryPath } from 'react-native-fs';
//const DDP = DocumentDirectoryPath + "/";
const input = res => res;
const output = str => str;

const alphaToNum = (alpha) => {
  var i = 0,
      num = 0,
      len = alpha.length;

  for (; i < len; i++) {
    num = num * 26 + alpha.charCodeAt(i) - 0x40;
  }

  return num - 1;
}

const numToAlpha = (num) => {

  var alpha = '';

  for (; num >= 0; num = parseInt(num / 26, 10) - 1) {
    alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
  }

  return alpha;
}

const _buildColumnsArray = (range) => {

  var i, v,
      res = [],
      rangeNum = range.split(':').map(function(val) {
        return alphaToNum(val.replace(/[0-9]/g, ''));
      }),
      start = rangeNum[0],
      end = rangeNum[1] + 1;

  //res.push({ title: "#", dataIndex: "", fixed: "left", render:(value, item, index) => (index+1)});
  //res.push({ title: "#", dataIndex: "", fixed: "left"});
  //res.push({ title: "#", dataIndex: "__rowNum__", fixed: "left"});
  res.push({ title: "#", dataIndex: "__rowNum__", render: (value) => (value-1), fixed: "left"});
  for (i = start; i < end ; i++) {
    v = numToAlpha(i);
    res.push({ title: v, dataIndex: v });
    //res.push(numToAlpha(i));
  }
  return res;
}

const  make_cols = (refstr/*:string*/) => {
  var o = [];
  var range = XLSX.utils.decode_range(refstr);
  for(var i = 0; i <= range.e.c; ++i) {
    o.push({name: XLSX.utils.encode_col(i), key:i});
  }
  return o;
}

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => { resolve(reader.result); };
    reader.onerror = reject;
    //reader.readAsArrayBuffer(file);
    reader.readAsBinaryString(file);
  })
}
const processFile = async (file) => {
  try {
    console.log("processFile: in ... ");
    let buf = await readFileAsync(file);
    console.log("processFile: got buf");
    let res = await processXSLX(buf);
    console.log("processFile: got res", res);
    return(res);
  } catch (err) {
    console.log("processFile: got error", err);
    return Promise.reject(err);
  }
}
const processXSLX = async (data) => {

     let workbook = XLSX.read(data,{type:"binary"});
     console.log("processXSLX: workbook: ", workbook);

     workbook.SheetNames.forEach(sheet => {
	  console.log("processXSLX: sheet: ", sheet);
          let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
	  console.log("processXSLX: rowObject: ", rowObject);
          //document.getElementById("jsondata").innerHTML = JSON.stringify(rowObject, undefined, 4);
     });

     // convert from workbook to array of arrays
     var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
     var range;
     if (first_worksheet["!ref"]) {
	range = first_worksheet["!ref"];
	console.log('ws[!ref]: ', range);
     }

     //var json_data = XLSX.utils.sheet_to_json(first_worksheet, {header:1, blankrows: false});
     // range: "A9-AE24"
     var opts = {header:"A", blankrows: false };
     if (range) opts.range = range;
     console.log("processXSLX: opts: ", opts);
     var json_data = XLSX.utils.sheet_to_json(first_worksheet, opts);
     console.log("processXSLX: sheet_to_json: ", json_data);
     //setJsonData( JSON.stringify(json_data, undefined, 4));

///
    if (!range) {
     var colNames = new Set([]);
     var cells = Object.keys(first_worksheet);

     for (var i = 0; i < cells.length; i++) {
	 //colNames.add(alphaToNum(cells[i].replace(/[0-9]/g, '')));
         if (cells[i].substr(0, 1) != '!') colNames.add(cells[i].replace(/[0-9]/g, ''));
     }
     console.log("colNames: ", colNames);
     var arr = [...colNames];
     var name_num = arr.map((v) => alphaToNum(v));
     var name_num_sort = [...name_num].sort((a,b)=>a-b);

     console.log("last: ", name_num_sort[name_num_sort.length-1]);
     console.log("last: ", numToAlpha(name_num_sort[name_num_sort.length-1]));
     range = "A:"+numToAlpha(name_num_sort[name_num_sort.length-1]);
     }
/// 
     var c = _buildColumnsArray(range);
     //c[0].render = (value, item, index) => ((page - 1) * 10 + index+1);

     // setXslx_columns ( c );
     //setXslx_data( json_data );

     var csv = XLSX.utils.sheet_to_csv(first_worksheet, opts);
     //var a2d = csv.split("\n").map(function(e,i) { return e.split(",").map((v) => { return({value: v}); } ); });
     var a2d = csv.split("\n", json_data.length).map(function(e,i) { return ([{value: (i+1), readOnly: true}, ...(e.split(",").map((v) => { return({value: v}); } ) )]); });
     var a2d_header = c.map((v) => { return({value: v.title, readOnly: true}); });
     console.log("processXSLX: return ");
     return { columns:c, data:json_data, excel:[a2d_header, ...a2d] };
}
const Xslx_worker = (props) => { 
    // XLSX.utils.json_to_sheet(data, 'out.xlsx');
    const selectedFile = props.file;
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(20);
    const [jsonData, setJsonData ] = useState();
    const [xslx_columns, setXslx_columns ] = useState([]);
    const [xslx_data, setXslx_data ] = useState([]);
    const [excel_data, setExcel_data ] = useState([]);

    console.log('xslx_worker: start with ', selectedFile);

    //const {json, columns, data } = processFile(selectedFile);
    // const res = processFile(selectedFile);
    useEffect(() => {
     console.log("xslx_worker: useEffect: selectedFile=", selectedFile?selectedFile.name:"none");
     if (selectedFile) 
     processFile(selectedFile)
     .then((res) => {
      console.log('xslx_worker: got res: ', res);
      setJsonData( JSON.stringify(res.data, undefined, 4));
      setXslx_columns(res.columns);
      setXslx_data(res.data);
      setExcel_data(res.excel);
     });
    }, [selectedFile]);

    if(!selectedFile) {
      console.log("xslx_worker: no one file ... exit");
      return (<pre>File not selected</pre>);
    }

/*
    return (<>
<p>Result: {xslx_columns?.length}</p>
<p>{xslx_data?.length}</p>
</>);
*/

const state = {
      columns: [
        { label: '#', width: '5%' },
        { label: 'Style', width: '40%' },
        { label: 'IBUs', width: '20%' },
        { label: 'Color (SRM)', width: '20%' },
        { label: 'Rating', width: '20%' }
      ],
      grid: [
        [
          { readOnly: true, value: '', width: 50 },
          { value: 'A', readOnly: true, width: 50 },
          { value: 'B', readOnly: true, width: 50 },
          { value: 'C', readOnly: true, width: 50 },
          { value: 'D', readOnly: true, width: 50 },
        ],
        [
          { readOnly: true, value: 1 },
          { value: 1 },
          { value: 3 },
          { value: 3 },
          { value: 3 },
        ],
        [
          { readOnly: true, value: 2 },
          { value: 2 },
          { value: 4 },
          { value: 4 },
          { value: 4 },
        ],
        [
          { readOnly: true, value: 3 },
          { value: 1 },
          { value: 3 },
          { value: 3 },
          { value: 3 },
        ],
        [
          { readOnly: true, value: 4 },
          { value: 2 },
          { value: 4 },
          { value: 4 },
          { value: 4 },
        ],
      ],
    };

     const onContextMenu = (e, cell, i, j) => cell.readOnly ? e.preventDefault() : null;

     return (
      <Tabs defaultActiveKey="1" tabPosition="top">
        <TabPane tab="Raw" key="1">
	  <TextArea size="small" rows="25" value={jsonData}/>
        </TabPane>
        <TabPane tab="Table" key="2">
	<Table
         _pagination={<Pagination pageSize="25" hideOnSinglePage/>}
         pagination={{ size: "small", pageSize: pageSize, hideOnSinglePagei: true, onChange: (page, pageSize)=>{ setPage(page); setPageSize(pageSize); } }}
         _sticky={true}
         bordered={true}
         rowKey="id"
	 columns={xslx_columns}
         dataSource={xslx_data}
         size="small"
         rowKey="__rowNum__"
         _onRow={(record, _) => { return { onClick: () => console.log("onRow: ", { model: "invoice", id: record.id }) }; }}
        />
{/*
         <Column
           header="#"
           title="#"
           key="index"
           render={(value, item, index) => (page - 1) * pageSize + index}
         />
         {xslx_columns && xslx_columns.map((r, i) => 
         <Column
           title={r.title}
           dataIndex={r.dataIndex}
         />
         )}
*/}
       </TabPane>
       <TabPane tab="Excel" key="3">
        <div style={{backgroundColor: 'white'}}>
         <DataSheet
          _data={state.grid}
          data={excel_data}
          valueRenderer={cell => cell.value}
          onContextMenu={onContextMenu}
          onChange={() => {}}
         />
        </div>
       </TabPane>
     
      </Tabs>
     );

};

const SheetJS = () => {
 const [selectedFile, setSelectedFile ] = useState();

 return (<>
	<Input type="file" id="input" accept=".xls,.xlsx" onChange={(e) => { setSelectedFile(e.target.files[0]) }} />
	{/*<Button id="button" onClick={xlsx_worker(selectedFile)}>Convert</Button> */}
        <Xslx_worker file={selectedFile} />
	<pre id="jsondata"></pre>
 </>);

}
export default SheetJS;

// react-native-fetch-blob
/*
import RNFetchBlob from 'react-native-fetch-blob';
const { writeFile, readFile, dirs:{ DocumentDir } } = RNFetchBlob.fs;
const DDP = DocumentDir + "/";
const input = res => res.map(x => String.fromCharCode(x)).join("");
const output = str => str.split("").map(x => x.charCodeAt(0));


/*
const make_cols = refstr => Array.from({length: XLSX.utils.decode_range(refstr).e.c + 1}, (x,i) => XLSX.utils.encode_col(i));
const make_width = refstr => Array.from({length: XLSX.utils.decode_range(refstr).e.c + 1}, () => 60);

export default class SheetJS extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [[1,2,3],[4,5,6]],
			widthArr: [60, 60, 60],
			cols: make_cols("A1:C2")
		};
		this.importFile = this.importFile.bind(this);
		this.exportFile = this.exportFile.bind(this);
	};
	importFile() {
		Alert.alert("Rename file to sheetjs.xlsx", "Copy to " + DDP, [
			{text: 'Cancel', onPress: () => {}, style: 'cancel' },
			{text: 'Import', onPress: () => {
				readFile(DDP + "sheetjs.xlsx", 'ascii').then((res) => {
					//* parse file 
					const wb = XLSX.read(input(res), {type:'binary'});

					//* convert first worksheet to AOA 
					const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];
					const data = XLSX.utils.sheet_to_json(ws, {header:1});

					//* update state 
					this.setState({ data: data, cols: make_cols(ws['!ref']), widthArr: make_width(ws['!ref']) });
				}).catch((err) => { Alert.alert("importFile Error", "Error " + err.message); });
			}}
		]);
	}
	exportFile() {
		//* convert AOA back to worksheet 
		const ws = XLSX.utils.aoa_to_sheet(this.state.data);

		//* build new workbook 
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

		//* write file 
		const wbout = XLSX.write(wb, {type:'binary', bookType:"xlsx"});
		const file = DDP + "sheetjsw.xlsx";
		writeFile(file, output(wbout), 'ascii').then((res) =>{
				Alert.alert("exportFile success", "Exported to " + file);
		}).catch((err) => { Alert.alert("exportFile Error", "Error " + err.message); });
	};
	render() { return (
<ScrollView contentContainerStyle={styles.container} vertical={true}>
	<Text style={styles.welcome}> </Text>
	<Image style={{width: 128, height: 128}} source={require('./logo.png')} />
	<Text style={styles.welcome}>SheetJS React Native Demo</Text>
	<Text style={styles.instructions}>Import Data</Text>
	<Button onPress={this.importFile} title="Import data from a spreadsheet" color="#841584" />
	<Text style={styles.instructions}>Export Data</Text>
	<Button disabled={!this.state.data.length} onPress={this.exportFile} title="Export data to XLSX" color="#841584" />

	<Text style={styles.instructions}>Current Data</Text>

	<ScrollView style={styles.table} horizontal={true} >
		<Table style={styles.table}>
			<TableWrapper>
				<Row data={this.state.cols} style={styles.thead} textStyle={styles.text} widthArr={this.state.widthArr}/>
			</TableWrapper>
			<TouchableWithoutFeedback>
				<ScrollView vertical={true}>
					<TableWrapper>
						<Rows data={this.state.data} style={styles.tr} textStyle={styles.text} widthArr={this.state.widthArr}/>
					</TableWrapper>
				</ScrollView>
			</TouchableWithoutFeedback>
		</Table>
	</ScrollView>
</ScrollView>
	); };
};

const styles = StyleSheet.create({
	container: { flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#F5FCFF' },
	welcome: { fontSize: 20, textAlign: 'center', margin: 10 },
	instructions: { textAlign: 'center', color: '#333333', marginBottom: 5 },
	thead: { height: 40, backgroundColor: '#f1f8ff' },
	tr: { height: 30 },
	text: { marginLeft: 5 },
	table: { width: "100%" }
});

AppRegistry.registerComponent('SheetJS', () => SheetJS);
*/
