import React from "react";
import { Select } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const { Option } = Select;

const TralersByTruckSelect = props => {
  const { truckId, numTrailers, value, onChange } = props;

  const { loading, error, data } = useQuery(GET_TRAILERS, {
    variables: { id: truckId },
    _skip: !truckId || numTrailers === 0,
    skip: !truckId,
  });

  if (loading) return <Select placeholder="loading" />;
  if (error) return `Error! ${error.message}`;

  return (
    <Select
      onChange={onChange}
      // value={value && data?.trailers.filter(i => i.id === value)[0].reg_number}
      value={value}
      allowClear
      disabled={!truckId || !(data?.trailers.length>0)}
      showSearch
      placeholder={data?.trailers.length>0?"Select trailer":""}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data &&
        data.trailers &&
        data.trailers.map((item, key) => {
          return (
            <Option key={key} value={item.id}>
              {item.reg_number}
            </Option>
          );
        })}
    </Select>
  );
};

      // {!data && <Option key="0" value="0" /> }
const GET_TRAILERS = gql`
  query TralersByTruck($id: Int) {
    trailers(where: { trucks_connection: { truck_id: { _eq: $id } } }) {
      id
      reg_number
    }
  }
`;

export default TralersByTruckSelect;
