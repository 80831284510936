// TODO:
// 1. ALL:
//   - remove currency ALL and make total text
//   - try to modify legend if currenct available
// 2. MIX: try to add currency name in legend or at sum
//

import React, { useState, useEffect } from "react";
import { Column, DualAxes } from "@ant-design/plots";
import { each, groupBy } from "@antv/util";
import { customStartEndFormat, dateFormat, pickerDateFormat } from "./ChartParams";

const transaction_types = {INC: 'incoming', OUT: 'outgoing', COM: 'commision'};

const month_idx = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

const get_currency_color = ({currency}) => {
  switch (currency) {
    case 'EUR': return 'rgb(52,  186, 235)';
    case 'USD': return 'rgb(52,  235, 113)';
    case 'CNY': return 'rgb(226, 235, 52)';
    case 'AED': return 'rgb(212, 105, 78)';
    case 'HUF': return 'rgb(52,  235, 52)';
    case 'ALL': return '#8805A8';
  }
  return 'black';
}

const Chart = ({
  data: data_,
  data_trend: data_trend_,
  params,
  onChange,
  value,
  ...otherProps
}) => {

  // or use Chart options
  const ref = React.useRef();
  ref.current = params;

  useEffect(() => {
    console.log("Chart.useEffect: fired");
  }, [params]);

  if (!data_) return "no data ...";

  console.log("got data: ", data_.length);

  const data = data_
    .filter((v) => {
      // remove empty
      if (!params.is_fill_empty && v.sum === 0) return false;

      // skip filter for SINGLE
      return true;
    })
    .sort((a, b) => params.picker === "year" ? 
      month_idx.indexOf(a.step) - month_idx.indexOf(b.step)
      :
      parseInt(a.step, 10) - parseInt(b.step, 10)
    );
  console.log("got data: filted: ", data.length);

  const data_trend =
    data_trend_ && params.currency === "ALL"
      ? data_trend_
          .filter((v) => {
            // remove empty
            if (!params.is_fill_empty && v.sum === 0) return false;
            // skip filter for SINGLE
            return true;
          })
          .sort((a, b) => params.picker === "year" ? 
            month_idx.indexOf(a.step) - month_idx.indexOf(b.step)
            :
            parseInt(a.step, 10) - parseInt(b.step, 10)
           )
      : data;

  const extr = data.reduce(
    (accumulator, currentValue) => {
      const c = parseFloat(currentValue.sum, 10);
      // skip empty values
      if (c === 0) return accumulator;

      accumulator.min =
        !accumulator.min || accumulator.min > c ? c : accumulator.min;
      accumulator.max =
        !accumulator.max || accumulator.max < c ? c : accumulator.max;
      return accumulator;
    },
    { min: null, max: null }
  );
  console.log("extremums", extr);


  console.log("data_trend", data_trend);
  const c_data   = params.is_show_trend ? [data, data_trend] : data;
  const c_yField = params.is_show_trend ? ["sum", "sum"] : "sum";
  const c_color  = params.is_show_trend
//    ? [["#0000FF", "#00DFDF", "#FF0033", "#FF5500"], ["#0000FF", "#00DFDF", "#FF0033", "#FF5500"]]
//    : ["#0000FF", "#00DFDF", "#FF0033", "#FF5500"];
      ? [get_currency_color, get_currency_color]
      : get_currency_color

  const c_yAxis_ = {
    label: {
      formatter: (value) => {
        return Math.floor(value / 1000) + "K";
      }
    }
  };
  const c_yAxis = params.is_show_trend ? [c_yAxis_, c_yAxis_] : c_yAxis_;

  // let total = 0, total_arr = [];
  let total = [];
  ////////////////
  const annotations = [];

  // add values rounded to kilo on top of bars 
  //params.currency === "ALL" &&
  
  if (params.currency !== "MIX") {
    const key = params.currency==="ALL"?"EUR":params.currency;
debugger;
    each(groupBy(data, "step"), (values, k) => {
      //console.log("annotations.1: ", k, values);
      const value = values.reduce((a, b) => a + b.sum, 0);
      const display_val =
        value >= 1000 ? parseFloat(value / 1000).toFixed(0) + "K" : parseFloat(value).toFixed(2);
      value &&
        annotations.push({
          type: "text",
          position: [k, value],
          content: `${display_val}`,
          style: {
            textAlign: "center",
            fontSize: 12,
            fill: "rgba(0,0,0,0.55)"
          },
          // put max columnt annon below ... in some cases max value not fittexd in canvas
          offsetY: extr.max && extr.max === value? (12+5) : -10,
          // debug: offsetX: +100
        });
    });

  }
/*
   else { // if (params.currency === "MIX") {
    each(groupBy(data, "currency"), (values, k) => {
      //console.log("annotations.1: ", k, values);
      const value = values.reduce((a, b) => a + b.sum, 0);
      total[k] = value;
    });
  }
*/
  each(groupBy(data, "currency"), (values, k) => {
      // console.log("each.currency: k=", k, "values:", values);
      const value = values.reduce((a, b) => a + b.sum, 0);
      total[k] = total[k]||0 + value;
  });

  const grand_total = Object.keys(total).reduce((a, b, i) => a + total[b], 0);
  console.log('grand total:', grand_total);

debugger;

  console.log("annotations: length=", annotations.length);
  // for trend c_data = [data, data_trend] ... use annotations for one data set only
  // const c_annotations = params.is_show_trend ? [annotations, annotations] : annotations;
  const c_annotations = params.is_show_trend ? [[], annotations] : annotations;

  console.log("annotations: ", JSON.stringify(annotations));
  ////////////////

  const chart_params = {
    isGroup: params.is_show_grouped,
    isStack: true,
    seriesField: "currency",
    colorField: 'currency',
    color: get_currency_color,
  };

  //const x_axis_text = params.picker.charAt(0).toUpperCase() + params.picker.slice(1) + ": " +
  const x_axis_text = "Range: " +
      customStartEndFormat(params, "from") + "\nTotal "+transaction_types[params.transaction_type]+' per '+params.picker+ 
      ': '+Object.keys(total).reduce((prev, cur, idx) => prev + (idx>0?', ':'') + 
        parseFloat(total[cur]).toFixed(2) + 
      ' '+(params.currency==='ALL'?'EUR/':'')+cur, '')+
      (params.currency==='ALL'?'\nGrand total: ' + 
        parseFloat(grand_total).toFixed(2) +
      ' ALL/EUR':'')

// If we want to format currency
// Intl.NumberFormat("en").format();
// Intl.NumberFormat("en", {style: "currency", currency: "CNY", minimumFractionDigits: 2}).format();

   // 4 coma as sep: return idx == 0 ? currVal : prevVal + ', ' + currVal;

  const config = {
    data: c_data,
    xField: "step",
    yField: c_yField,
    ...chart_params,
//    color: c_color,

//    colorField: 'currency',
//    color: ({ currency }) => {
/*
    color: (o) => {
      switch (o.currency) {
        case 'EUR': return 'rgb(52, 186, 235)';
        case 'USD': return 'rgb(52, 235, 113)';
        case 'CNY': return 'rgb(226, 235, 52)';
        case 'AED': return 'rgb(212, 105, 78)';
      }
      return 'black';
    },
*/
    label: false && {
      content: (originData) => {
        return (
          // persents:
          // ((parseFloat(originData.sum) / extr.max) * 100).toFixed(1) + "%"
          parseFloat(originData.sum).toFixed(2)
        );
      },
      offset: -10
    },
    legend: ["ALL", "MIX"].includes(params.currency) ? { position: "top" } : false,
    title: { text: "This is sample chart ", position: "center" },
    yAxis: c_yAxis,
    xAxis: {
      title: { text: x_axis_text },
      label: {
        autoHide: true,
        autoRotate: false
      }
    },
    // geometryOptions: for DualAxes
    geometryOptions: [
      {
        geometry: "column",
        ...chart_params
      },
      {
        geometry: "line",
        ...chart_params,
        lineStyle: {
          lineWidth: 2
        },
        smooth: true
      }
    ],
    tooltip: {
      formatter: (datum) => {
        // console.log("tooltip.formatter:", JSON.stringify(datum));

/*
if (datum.sum === null) {
 alert(JSON.stringify(datum));
}
*/

        return {
          name: datum.currency
            ? datum.currency +
              (params.currency === "ALL" && datum.currency !== "EUR" ? " in EURO" : "")
            : "total ",
          value: (datum.sum === null? 0 : datum.sum).toFixed(2)
        };
      }
    },
    annotations: c_annotations,
    //interactions: [{ type: "element-selected" }, { type: "element-active" }],
    onReady: (plot) => {
      console.log("Char.onReady: fired");
      /*      
      (ref.current.picker === "year" ||
        (ref.current.picker === "month" &&
          ref.current.sub_picker === "week")) &&
      */

      plot.on("element:click", (event, chartElements, ...args) => {
        //...args
        console.log("click: begin");
        // console.log("click: event:", event);
        const data = event.data.data;
        console.log("click.target:", data.currency, data.step);
        console.log("picker:", ref.current.picker);
        if (
          data.step &&
          (ref.current.picker === "year" ||
            (ref.current.picker === "month" &&
              ref.current.sub_picker === "week"))
        ) {
          /*
 * move it to data.js
 *        
          console.log(
            "picker.2:",
            get_picker(),
            "sub_picker:",
            get_sub_picker(),
            "currency:",
            get_currency()
          );

          let step_next;
          switch (picker) {
            case "year":
              step_next = "month";
              break;
            case "month":
              step_next = "week";
              break;
            default:
              step_next = null;
          }
          console.log("step_next:", step_next);
*/
          if (onChange) onChange({ data: data });
        }
        /*
        console.log("click: got " + arguments.length + " args");
        for (var i = 0; i < arguments.length; i++) {
          console.log("args:", arguments[i]);
        }

        if (chartElements) {
          console.log("chartElements:", chartElements[0].label);
        }
        */
      });
    }
  };

  console.log("chart: config ready");
  console.log("config:", JSON.stringify(config));
  debugger;

  if (!params.is_show_trend)
    return (
      <>
{/*
        {params.currency} : {params.picker} : {params.sub_picker}
        config: {JSON.stringify(config)}
*/}
        <Column {...config} />
      </>
    );
    return (
      <>
{/*
        {params.currency} : {params.picker} : {params.sub_picker}
        config: {JSON.stringify(config)}
*/}
        <DualAxes {...config} />
      </>
    );

};
export default Chart;
