import React, { Component } from "react";
import { Select } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const Option = Select.Option;

const CompanyRoles = (props) =>  {

 console.log("CompanyRoles: ");
 console.log("props: ");
 console.dir(props);

  const { loading, error, data } = useQuery(GET_ROLES);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  console.log("return ...");
    return (
            <Select {...props}>
              {data.company_roles.map((item, key) => {
                return (
                  <Option key={key} value={item.role}>
                    {item.role}
                  </Option>
                );
              })}
            </Select>
    );
}

const GET_ROLES = gql`
  query CompanyRoles {
    company_roles(order_by: { role: asc }) {
      role
    }
  }
`;

export default CompanyRoles;
