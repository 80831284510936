import React, { Component } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { Table, Checkbox, Switch, Typography } from "antd";
import { CrownOutlined } from "@ant-design/icons";

import BankAccountsTable from "./BankAccountsTable";
import { getUserTenant } from "../../auth/authHelper";
import { get_currency_list_by_tenant }  from "../../tenants";

const { Text } = Typography;


// WTF? Not refreshed by refetchQueries: ["Accounts"] from TransactionsTable
//
// use fetchPolicy: 'network-only'
// or 
// use Button with refetch() call

const AccountsTable = (props) => {
  const PAGE_SIZE = 50;

const columns = [
  {
    title: "Company",
    _dataIndex: "name",
    width: "30%",
    render: data => <>
{/* <Text style={{whiteSpace: 'nowrap',  marginRight: '100px'}}> */}
 {data.name}{data.id == 1 && <CrownOutlined style={{ paddingLeft: '10px', fontSize: '16px', color: '#f00' }}/>}
{/* </Text> */}
 </>,
  },
/*
  {
    title: "Balance EURO",
    align: 'right',
    render: data => <>{(data.sum.nodes.find(x => x.currency == 'EUR')?.sum??0).toFixed(2)}</>,
    width: "1%"
  },
  {
    title: "Balance USD",
    align: 'right',
    render: data => <>{(data.sum.nodes.find(x => x.currency == 'USD')?.sum??0).toFixed(2)}</>,
    width: "1%"
  },
  {
    title: "Balance CNY",
    align: 'right',
    render: data => <>{(data.sum.nodes.find(x => x.currency == 'CNY')?.sum??0).toFixed(2)}</>,
    width: "1%"
  },
  {
    title: "Balance AED",
    align: 'right',
    render: data => <>{(data.sum.nodes.find(x => x.currency == 'AED')?.sum??0).toFixed(2)}</>,
    width: "1%"
  },
  {
    title: "Balance HUF",
    align: 'right',
    render: data => <>{(data.sum.nodes.find(x => x.currency == 'HUF')?.sum??0).toFixed(2)}</>,
    width: "1%"
  },
*/
  ...(()=>(get_currency_list_by_tenant(getUserTenant()).map((c)=>(
  {
    title: "Balance "+c,
    align: 'right',
    render: data => <>{(data.sum.nodes.find(x => x.currency == c)?.sum??0).toFixed(2)}</>,
    width: "1%"
  }
  ))))()
  ,
  {
    title: "Fee",
    align: 'right',
    render: data => <>{((data.fee??0)*100).toFixed(1)}%</>,
    width: "1%"
  },
  {
    title: "Autoconversion",
    align: 'right',
    dataIndex: "type_exchange",
    _render: data => <>{data === 'AUTO'? 'Yes' : 'No'}</>,
//    __render: (e, record) => (< Switch onChange={() => handleSwitchChange(record)} defaultChecked={e} />),

    render: (data, row) => <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={data === 'AUTO'} 
          onChange={(a) => {
             const variable_data = {id: row.id, objects: {type_exchange: a?'AUTO':null}};

             if (a && !row.dir_exchange) variable_data.objects.dir_exchange = 'EUR-USD';

             update_company({variables: variable_data})
             .then(() => {
                console.log('company.type_exchange ... updated');
             })
             .catch((e) => {
                console.log('company.type_exchange ... not updated. '+e);
                alert('company not updated: error');
             });
          }}
    />,
    width: "1%"
  },
  {
    title: "A-conv direction",
    align: 'left',
    dataIndex: "dir_exchange",
    width: "200",
    style: { "white-space": "nowrap" },

    render: (data, row) => data? <Switch checkedChildren="EUR-USD" unCheckedChildren="USD-EUR" defaultChecked={data === 'EUR-USD' } 
          disabled={!(row.type_exchange === 'AUTO')}
          onChange={(a) => {
             update_company({variables: {id: row.id, objects: {dir_exchange: a?'EUR-USD':'USD-EUR'}}})
             .then(() => {
                console.log('company.dir_exchange ... updated');
             })
             .catch((e) => {
                console.log('company.dir_exchange ... not updated. '+e);
                alert('company not updated: error');
             });
          }}
    /> : <></>,
  },
  {
    dataIndex: "dummy",
    _width: "20%"
  }
];

  const [update_company] = useMutation(UPDATE_COMPANY, {
    refetchQueries: ["Accounts"],
    onCompleted: (data) => {
      // alert('update_account: done');
    },
  });

  const { loading, error, data, refetch } = useQuery(
       GET_ACCOUNTS, 
       { 
          fetchPolicy: 'network-only',
       }); 

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  debugger;

  // move tenant master company (id === 1) at top 
  if (data?.accounts) data.accounts.sort((a, b) => a.id === 1? a.id - b.id : 0);
  //return(JSON.stringify(data));  

  return (
    <>
      <Table
        width="100%"
        rowKey="id"
        pagination={data.accounts.length > PAGE_SIZE ? {pageSize: PAGE_SIZE} : false}
        columns={columns}
        dataSource={data.accounts}
        rowClassName={(record, index) => (record.type != 'client' ? "bg-light-green" : "")}
        size="small"
        summary={(pageData) => {
              const total = {"USD": 0, "EUR": 0, "CNY": 0, "AED": 0,  "HUF": 0};
              pageData.forEach((data) => {
                 if (data.type === 'client') {
                      data.sum.nodes.forEach((node) => {
                         total[node.currency] += node.sum??0;
                      });
                 }
              });
              const cells = [];              
              get_currency_list_by_tenant(getUserTenant()).forEach((c, i)=> { cells.push(
                    <Table.Summary.Cell index={i+1}><Text style={{whiteSpace: 'nowrap', 'textAlign': 'right'}}><b>{(total[c]||0.00).toFixed(2)}</b></Text></Table.Summary.Cell>
              ); });


              // `align: right` for summary was fixed in new antd releases (textAlign?)
              return (
                <>
                  <Table.Summary.Row style={{ background: '#FFD', textAlign: "right" }}>
                    <Table.Summary.Cell index={0}  align="left"><b>Total:</b></Table.Summary.Cell>
                    {cells}
{/*                    
                    <Table.Summary.Cell index={1}><Text style={{whiteSpace: 'nowrap', 'textAlign': 'right'}}><b>{(total.EUR||0.00).toFixed(2)}</b></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}><Text style={{whiteSpace: 'nowrap', 'textAlign': 'right'}}><b>{(total.USD||0.00).toFixed(2)}</b></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}><Text style={{whiteSpace: 'nowrap', 'textAlign': 'right'}}><b>{(total.CNY||0.00).toFixed(2)}</b></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}><Text style={{whiteSpace: 'nowrap', 'textAlign': 'right'}}><b>{(total.AED||0.00).toFixed(2)}</b></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}><Text style={{whiteSpace: 'nowrap', 'textAlign': 'right'}}><b>{(total.HUF||0.00).toFixed(2)}</b></Text></Table.Summary.Cell>
*/}
                    <Table.Summary.Cell index={cells.length+1} colSpan={4}><Text></Text></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
      />
    </>
  );
};

// TODO: if we leave using florunner - get id from bot_config.my_company
const GET_ACCOUNTS = gql`
query Accounts {
  accounts: companies(where: {
     _or: [
      {account: {id: {_eq: 1}}}
      {company_role: {role: {_eq: "transaction_initiator"}}}
    ]},
    order_by: {name: asc}
   ) {
    id
    name
    fee
    type_exchange
    dir_exchange
    type
    company_role {
      role
    }
    sum: account_sum_aggregate {
      nodes {
        currency
        sum
      }
    }
  }
}
`;

const UPDATE_COMPANY = gql`
mutation UpdateCompany($id: Int!, $objects: companies_set_input!) {
  update_company: update_companies_by_pk (pk_columns: {id: $id}, _set: $objects)
  {id}
}
`;

class Accounts extends Component {
  render() {
    return <>
      <BankAccountsTable />
      <header className="ant-layout-header" style={{height: "22px"}} />
      <AccountsTable />
   </>;
  }
}

export default Accounts;
