import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import moment from "moment";
import { useStoreon } from "storeon/react";
import { useQuery } from "@apollo/client";
import { List, Input, Table, Tabs } from "antd";
import FormBtn from "../shared/FormBtn";
import { FilesIcon } from "../shared/FilesIcon";
import { OrderFileList } from "./OrderFileList";
import { Toolbar } from "./Toolbar";
import CompanySelect from "../shared/CompanySelect";
import OrderUnit from "../shared/OrderUnit";
import ShipmentStatuses from "../shared/ShipmentStatuses";
import OrderTabPane from "./OrderTabPane.js";
import { isSupplier, isAdmin, isShipper } from "../../auth/authHelper";
import { GET_ORDERS, GET_ALL_ORDERS } from "./graphql/orders_graphql.js";

const TabPane = Tabs.TabPane;

// TODO: remove status if not will used in future
/////////////////////////////////////////////////////////////////////////////////
const OrdersTable = (props) => {
  const { selection, dispatch, filter } = useStoreon("selection", "filter");
  const { auth } = useStoreon("auth");
  const { shipmentId } = props.mode === "all" ? {} : selection;
  const [ prevFilter, setPrevFilter ] = useState();
  const [ state, setState ] = useState({
    filteredInfo: {status: ["new"]},
    sortedInfo: null,
    selectedRowKeys: selection.orderRows
  });

  let { sortedInfo = {}, filteredInfo = {}} = state;

  const filter_input_helper_props = (setSelectedKeys, confirm, key) => ({
    onPressEnter: (e) => {
      setSelectedKeys(e.target.value?[e.target.value]:[]);
      if (confirm) confirm({ closeDropdown: true });
    },
    onBlur: (e) => {
      setSelectedKeys(e.target.value?[e.target.value]:[]);
      if (confirm) confirm({ closeDropdown: true });
    },
     _onChange: (e) => {
       setSelectedKeys(e.target.value?[e.target.value]:[]);
       if (confirm) confirm({ closeDropdown: true });
    },
  });

  const onSelectChange = _selectedRowKeys => {
    setState((prev) => ({...prev, selectedRowKeys: _selectedRowKeys }));
    dispatch("selectRows", { model: "order", rowKeys: _selectedRowKeys });
  };

  const { selectedRowKeys } = state;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      return { disabled: false };
      //return { disabled: (record.shipment&&!isAdmin()) };
    },
    hideDefaultSelections: true, //false,
    selections: [
// key <=> id?
//        Table.SELECTION_ALL,
//        Table.SELECTION_INVERT,
//        Table.SELECTION_NONE,
        {
          key: 'data-all',
          text: 'Select All Data',
          onSelect: changableRowKeys => {
            onSelectChange(data.orders.map((a) => a.id));
          },
        },
        {
          key: 'data-none',
          text: 'Clear All Data',
          onSelect: changableRowKeys => {
            onSelectChange([]);
          },
        },
    ]
  };

  useEffect((e) => {
    if (!prevFilter) {
    } else {
      if (JSON.stringify(prevFilter) != JSON.stringify(filter.order)) {
        console.log("****************** OrderTable.useEffect([filter.order]): clear selection");
        clearSelection();
      }
    }
    setPrevFilter(filter.order);
  }, [filter.order]);

  /*
   * where => look at permissions at hasura gui (supplier, shipper, etc.)
   *
   */

  const { loading, error, data } = useQuery((!shipmentId)? GET_ALL_ORDERS: GET_ORDERS, {
    skip: !(isAdmin() || isSupplier() ||isShipper()),
    variables: (shipmentId)? { shipmentId: shipmentId } : {}
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  // due to query.skip problem
  if (!(isAdmin() || isSupplier() || isShipper())) return "You have not suitable permissions";

  const clearSelection = () => {
    // clear selection
    dispatch("selectRows", { model: "order", rowKeys: [] });
    setState((prev) => ({...prev, selectedRowKeys: []}));
  };

  const handleChange = (pagination, filters, sorter, extra) => {
debugger;
    console.log('handleChange: fired', pagination, filters, sorter);
    setState((prev) => ({...prev,
      filteredInfo: filters,
      sortedInfo: sorter,
    }));
    if (extra.action === "filter") {
      const filteredRowKeys = extra.currentDataSource.map((r) => r.id);
      const intersection = selectedRowKeys.filter(x => filteredRowKeys.includes(x));
      dispatch("selectRows", { model: "order", rowKeys: intersection });
      setState((prev) => ({...prev, selectedRowKeys: intersection }));
    }
  };

const columns = [
/*
  {
    title: "Status",
    key: "status",
    width: "3%",
    render: r => !r.shipment?"orphan":r.shipment.status?r.shipment.status:"new",
    filters: [
          { text: 'new', value: 'new' },
          { text: 'orphan', value: 'orphan' },
        ],
    filteredValue: filteredInfo.status || null,
    onFilter: (v, r) => !r.shipment&&v==="orphan" || r.shipment?.status===null&&v==="new" || r.shipment?.status === v ,
  },

       onChange={(value) => setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "shipment.state": value?[value]:null}})) } />,
*/
  {
    title: "State",
    dataIndex: ["shipment"],
    render: r => !r?"-":r.shipment_status.info,
    key: "state",
    width: "3%",
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <ShipmentStatuses
       context="order"
       mode="multiple"
       showArrow="true"
       style={{minWidth: 200}}
       _onChange={(value) => setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "shipment.state": value?[value]:null}})) }
       __onChange={(value) => setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "shipment.state": value}})) }
       onChange={(value) => {
debugger;
        setSelectedKeys(value?value:[]);
        if (confirm) confirm({ closeDropdown: true });
       }}
    />,
    filteredValue: filteredInfo["state"] || null, // "shipment.state"
    _onFilter: (value, record) => value.constructor === Array? !value.length || (!record.shipment && value.includes('-')) ||value.includes(record.shipment?.state) : record.shipment?.state === value ,
    onFilter: (value, record) => {
console.log("filter:", value, record );
return  (!record.shipment && value === '-') || record.shipment?.state === value 
},

  },
  {
    title: "Files",
    width: "3%",
    render: record => <FilesIcon id={record.id} dataModel="order" count={record.files.aggregate.count} />
  },
  {
    x_supplier: true,
    title: "Supplier",
    dataIndex: ["supplier", "name"],
    _sorter: (a, b) => {
      let nameA = a.supplier.name.toUpperCase();
      let nameB = b.supplier.name.toUpperCase();
      if (nameA < nameB) { return -1; } else if (nameA > nameB) { return 1; }
      return 0;
    },
    // defaultSortOrder: "ascend"
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <CompanySelect
      role="supplier"
      style={{width: "100%"}}
      onChange={(value) => {
        //setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "supplier.name": value?[value]:null}}));
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    />,
    filteredValue: filteredInfo["supplier.name"] || null,
    onFilter: (value, record) => record.supplier?.id === value ,
  },
  {
    title: "Buyer",
    dataIndex: ["buyer", "name"],
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <CompanySelect
      role="buyer"
      style={{width: "100%"}}
      onChange={(value) => {
        // setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "buyer.name": value?[value]:null}}))
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    />,
    filteredValue: filteredInfo["buyer.name"] || null,
    onFilter: (value, record) => record.buyer?.id === value ,
  },
  {
    title: "Label",
    key: "label",
    dataIndex: "label",
    // width: "100px"
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <Input
      allowClear
     {...filter_input_helper_props(setSelectedKeys, confirm, "label")}
    />,
    filteredValue: filteredInfo.label || null,
    onFilter: (value, record) => record.label.toUpperCase().includes(value.toUpperCase()) ,
  },
  {
    title: "Colli",
    dataIndex: "colli"
  },
  {
    title: "Cargo type",
    dataIndex: "cargo_type"
  },
  {
    title: "Packing type",
    dataIndex: ["packing_type", "type"]
  },
  {
    title: "Units",
    dataIndex: ["order_units"],
    _render: rec => <ul> { rec && rec.map((r, i) => <li><OrderUnit key={r.id} record={r} index={i}/></li>) } </ul>,
    render: rec => !rec ||rec.length===0?<></>: 
    <List
      size="small"
      _bordered
      dataSource={rec}
      renderItem={(r,i) => <List.Item><OrderUnit key={r.id} record={r} index={i}/></List.Item>}
    />
  },
  {
    title: "Brutto",
    dataIndex: "brutto_weight"
  },
  {
    title: "Master AWB",
    dataIndex: "master_awb"
  },
  {
    title: "Origin",
    dataIndex: ["country", "alpha2"]
  },
  {
    title: "Departure date",
    dataIndex: "departure_date",
    render: date => <>{moment(date).format("DD-MM-YYYY")} </>
  },
  {
    title: "",
    _render1: record => (record.shipment&&!isAdmin())?<></>:<FormBtn id={record.id} component="order" action="edit" />,
    _render2: record => (!record.shipment || record.shipment_id == shipmentId || isAdmin())?<FormBtn id={record.id} component="order" action="edit" /> : <></>,
    render: record => (record.shipment && isSupplier())?<></>:<FormBtn id={record.id} component="order" action="edit" />
  },
];
  // console.log("Orders.data:", data);

  const active_columns = !isSupplier()?columns:columns.filter(col => !col.x_supplier);

  return (
    <>
      <Toolbar mode={props.mode} numOrders={(shipmentId && data?.orders)?data.orders.length:0}/>
      <Table
        bordered
        rowKey="id"
        rowSelection={rowSelection}
        rowClassName="row-v-alig-top"
        onChange={handleChange}
        onExpand={(_, record) => {
          dispatch("select", { model: "order", id: record.id });
        }}
        columns={active_columns}
        dataSource={data.orders}
        size="small"
        onRow={(record, _) => {
          return {
            onClick: () => dispatch("select", { model: "order", id: record.id })
          };
        }}
        expandedRowRender={record => (
          <Tabs defaultActiveKey="1" tabPosition="left">
            <TabPane tab="Order" key="1"><OrderTabPane src={record}/></TabPane>
            <TabPane tab="Parse" key="2">
              <OrderFileList id={record.id} />
            </TabPane>
          </Tabs>
        )}
      />
    </>
  );
};

/*
const FRAGMENT_ORDERS = gql`
fragment fr_company on companies {
      id
      name
      short_name
}
fragment orders_fragment on orders {
      id
      type
      creation_date
      departure_date
      shipment_id
      shipment {
        status
        state
        shipment_status {
         info
        }
        shipper {
          name
        }
        truck {
          id
          reg_number
        }
      }
      supplier_id
      supplier { ...fr_company }
      buyer_id
      buyer { ...fr_company }
      loading_order
      label
      colli
      colli_controle
      colli_transit
      brutto_weight
      netto_weight
      chargeable_weight
      master_awb
      house_awb
      flight
      temperature_range
      transit_id
      transit_consignee
      transit_client
      transit_eta
      country {
        alpha2
        name
      }
      cargo_type
      packing_type {
        type
        code
      }
      files: files_aggregate(where: { hidden: { _eq: false } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
`;

//      order_by: { creation_date: desc, supplier: { name: asc } }
const GET_ORDERS = gql`
  ${FRAGMENT_ORDERS}
  query Orders($shipmentId: Int) {
    orders(
      order_by: { creation_date: desc }
      where: { shipment_id: { _eq: $shipmentId } }
    ) {
       ...orders_fragment
    }
  }
`;

// same as GET_ORDERS except WHERE
// due new HASURA behavior
const GET_ALL_ORDERS = gql`
  ${FRAGMENT_ORDERS}
  query Orders($shipmentId: Int) {
    orders(
      order_by: { creation_date: desc }
    ) {
       ...orders_fragment
    }
  }
`;
*/

export default OrdersTable;
