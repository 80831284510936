import React, { useEffect, useState, useRef, createRef, useCallback } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { Table, Form, Input, Modal, Button, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import SortableTable from "./SortableTable";
/*
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
*/

const columns = [
/*
  {
    title: 'Sort',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
*/
  {
    title: 'Position',
    width: '3%',
    dataIndex: 'position',
    className: 'drag-visible',
  },
  {
    title: 'Status',
    width: '3%',
    dataIndex: 'status',
    editable: true,
  },
  {
    title: 'Info',
    dataIndex: 'info',
    editable: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    editable: true,
  },
/*
  {
    title: 'Operation',
    dataIndex: 'delete',
    render: (r) => <Button type="link" onClick={(e) => handleDelete(e, r.status)} >Delete</Button>
  },
*/
];

const ShipmentStatusCodes = () => {
  const [form] = Form.useForm();
  const { ui, dispatch } = useStoreon("ui", "selection");
  const { component } = ui.visibleForm;
  //const modalRef = useRef();
  const [modalRef, setModalRef] = useState();
  const [origData, setOrigData] = useState([]);
  const [newData, setNewData] = useState([]);
  
  const [isModified, setIsModified] = useState(false);
  const [isFresh, setIsFresh] = useState(true);

  const measuredRef = useCallback(node => {
    console.log('measuredRef: useCallback: ', node);
    if (node !== null) setModalRef(node);
  }, []);

  useEffect(() => {
    if (data?.shipment_statuses) {
      form.setFieldsValue(data);
    }
  });

  useEffect(() => {
  }, [modalRef]);

  const closeForm = () => {
    dispatch("hideForm");
  };

  const { loading, error, data } = useQuery(GET_SHIPMENT_STATUSES, {
    fetchPolicy: "cache-and-network",
    skip: !(component === "shipment_statuses"),
  });

  useEffect(() => {
    if (isFresh && data) {
      setOrigData(JSON.parse(JSON.stringify(data.shipment_statuses)).map((e, i) => ({ ...e, index: i, key: i })));
      setIsFresh(false); 
    }
  }, [data]);

  useEffect(() => {
    // only single level
    // setIsModified(origData.join() == newData?.join());
    if (data && origData && newData) setIsModified(JSON.stringify(origData) != JSON.stringify(newData));
  }, [data, newData]);

  const [upsert] = useMutation(UPSERT_SHIPMENT_STATUSES, {
    refetchQueries: ["ShipmentStatuses"],
    // onCompleted: () => {
    //   form.resetFields();
    //   closeForm();
    // },
  });

  const [remove] = useMutation(DELETE_SHIPMENT_STATUSES, {
    refetchQueries: ["ShipmentStatuses"],
  });

  const handleSubmit = () => {
    console.log("handleSubmit: newData=", newData);
/*
    form
      .validateFields()
      .then((values) => {
debugger;
        values.shipment_statuses.forEach((v) => {
          delete v.__typename;
          upsert({
            variables: { objects: v },
          });
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
*/
debugger;
    // todo: we can optimize => set and check flag for each modified row
    let diff = data?.shipment_statuses.filter(x => !newData.some((z) => x.status == z.status ));
    diff.forEach((v) => {
      console.log('delete: ', v);
      remove({ variables: { status: v.status }, });
    });
    newData.forEach((v) => {
      console.log('upsert: ', v);
      delete v.__typename;
      delete v.index;
      delete v.key;
      upsert({ variables: { objects: v }, });
    });
    setIsFresh(true); 
  };

/*
  const handleDelete = key => {
    const dataSource = [...this.state.dataSource];
    this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
  };

  const handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      name: `Edward King ${count}`,
      age: 32,
      address: `London, Park Lane no. ${count}`,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };
*/

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <Modal
        maskClosable={false}
        width="1000px"
        title="Shipment statuses"
        visible={component === "shipment_statuses"}
        onOk={handleSubmit}
        okText="Submit"
        okButtonProps={{disabled: !isModified }}
        onCancel={closeForm}
        cancelButtonProps={{disabled:false}}
      >
       <div ref={measuredRef}>
        <Form layout="vertical" form={form} initialValues={data}>
          <SortableTable
            columns={columns}
            data={data?.shipment_statuses}
            helperContainer={modalRef}
            form={form}
            getNewData={setNewData}
          />
        </Form>
       </div>
      </Modal>
    </div>
  );
};

export default ShipmentStatusCodes;

const GET_SHIPMENT_STATUSES = gql`
  query ShipmentStatuses {
    shipment_statuses (order_by: { position: asc }) {
      status
      position
      info
      description
    }
  }
`;

const DELETE_SHIPMENT_STATUSES = gql`
  mutation DeleteShipmentStatuses($status: String!) {
    delete_shipment_statuses(where: { status: { _eq: $status } }) {
      affected_rows
    }
  }
`;

const UPSERT_SHIPMENT_STATUSES = gql`
  mutation InsertShipmentStatuses($objects: [shipment_statuses_insert_input!]!) {
    insert_shipment_statuses (
      objects: $objects
      on_conflict: {
        constraint: shipment_statuses_pkey
        update_columns: [position, info, description]
      }
    ) {
      affected_rows
    }
  }
`;
