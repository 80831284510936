import React, { useEffect, useState, useRef, createRef, useCallback } from "react";
import moment from "moment";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { Descriptions, Space, Typography, Tooltip, Table, Form, Input, Modal, Button, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { EnvironmentOutlined, PhoneOutlined, PhoneTwoTone } from "@ant-design/icons";

import { FRAGMENT_ORDERS } from "./graphql/orders_fragment_graphql";
import { FRAGMENT_USERS } from "../users/DriversTable";
import { def_min, def_max, int4range } from "../shared/TemperatureRange";

const { Title, Paragraph, Text, Link } = Typography;

const OrdersMapBtn = (props) => {
  return (
    <Tooltip placement="bottomLeft" title="Shipment information for drivers">
      <Button key="submit" type="link" icon={<EnvironmentOutlined />} {...props} >Directions</Button>
    </Tooltip>
  );
}

export const OrdersMapForm = (props) => {
  const [ visible, setVisible ] = useState(false);
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { orderRows, shipmentId } = selection;

  const [form] = Form.useForm();
  const { component } = ui.visibleForm;
  const [modalRef, setModalRef] = useState();
  const [origData, setOrigData] = useState([]);
  const [newData, setNewData] = useState([]);
  
  const [isModified, setIsModified] = useState(false);
  const [isFresh, setIsFresh] = useState(true);

  const { loading, error, data } = useQuery(GET_SHIPMENT, {
    fetchPolicy: "cache-and-network",
    skip: !visible || !shipmentId,
    variables: { id: shipmentId },
  });

  const measuredRef = useCallback(node => {
    console.log('measuredRef: useCallback: ', node);
    if (node !== null) setModalRef(node);
  }, []);

  useEffect(() => {
    if (data?.shipment.orders) {
       //form.setFieldsValue(data);
       form.setFieldsValue({info: null});
    }
  }, [data]);

  useEffect(() => {
  }, [modalRef]);

  ///////////////////////////////////////////////////////////////////////
  const closeForm = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    console.log("handleSubmit:");
  };
  ///////////////////////////////////////////////////////////////////////

  // Show button only
  // if (loading) return "Loading...";
  // if (error) return `Error! ${error.message}`;

  const add = (i) => i?", "+i:"";

  const formatOrderInfo = (order, pos) => {
    // Supplier - address + door code + xx boxes + goods_type + temperature
    //debugger;
    const w = order.supplier_warehouse;
    let buf;
    
    buf = (pos+1)+": " + order.supplier.name;
    if (w) {
      buf += ", " + w.name + ", ";
      buf += w.street + ", " + w.house_no + ", " + w.city + ", " + w.post_code + ", " + w.country.name;
      buf += add(w.route_description) + add(w.door_code);
    }

    order.units.forEach((u) => buf += add(u.colli + "-" + u.packing_type.type + "/" + u.cargo_type) );

    let range = order.temperature_range?int4range(""+order.temperature_range):{min: def_min, max: def_max};
    buf += add("["+range.min+"C-"+range.max+"C]");

    buf += "\n";

    return (buf);
  };

  const sendHandler = (e, data) => {
     alert("TODO: send info to "+data.contact);
  };

  const formatDriverInfo = (driver) => {
    if (!driver) return null;

    return (
      <>
      {
       driver.contacts.map((c, i) => 
      <Descriptions.Item key={c.id+"_"+i} label={i===0?driver.first_name+" "+driver.last_name:""}>
        <Space>
          <Tooltip placement="bottomLeft" title="Send shipment information to driver`s phone">
            <Button
               key="submit"
               type="default"
               icon={<PhoneTwoTone twoToneColor="#52c41a" />}
               size="small"
               onClick={(e) => sendHandler(e, c)}
             >Send</Button>
          </Tooltip>
          {c.contact} {c.description?" (" + c.description + ")":""}</Space>
      </Descriptions.Item>
      )
     }
     </>
    );
  };

  const showMapButton = (order) => {
    const w = order.supplier_warehouse;
    let buf;

    if (!w) return;

    buf = w.street + "," + w.house_no + "," + w.city + "," + w.post_code + "," + w.country.name;

     return (
     <Button
       key={"btn_map_"+order.id}
       type="link"
       target='_blank'
       icon={<EnvironmentOutlined />}
       href={"https://www.google.com/maps/search/?api=1&query="+encodeURIComponent(buf).replace(/%20/g, '+')}
     >[ {w.name} ]</Button>
     );
  };

  return (
    <>
      <OrdersMapBtn onClick={(e) => setVisible(true)} disabled={props.numOrders==0} />
      <Modal
        maskClosable={false}
        width="1300px"
        title="Information for drivers"
        visible={visible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={closeForm}
        footer={[
            <Button key="submit" type="primary" loading={loading} onClick={closeForm}>
              Ok
            </Button>,
          ]}

      >
       <div ref={measuredRef}>
        { loading && <>Loading...</> }
        { error && <>`Error! ${error.message}`</> }
        { data &&
        <Form layout="vertical" form={form} initialValues={data}>
         {data.shipment.orders?.map((r, i) => showMapButton(r, i) )}
         <Paragraph>
           <pre>
            {data.shipment.orders?.map((r, i) => formatOrderInfo(r, i) )}
           </pre>
         </Paragraph>
         <Form.Item
           label="Custom info"
           name="info"
           rules={[{ required: false, message: "Please input additional custom info" }]}
         >
           <Input autoComplete="off" />
         </Form.Item>

         { (data.shipment.truck.driver_1 || data.shipment.truck.driver_2) &&
           <Descriptions title="Drivers" column={1} bordered={true} size="small" >
             {formatDriverInfo(data.shipment.truck.driver_1)}
             {formatDriverInfo(data.shipment.truck.driver_2)}
           </Descriptions>
         }
        </Form>
        }
       </div>
      </Modal>
    </>
  );
};

//Supplier - address + door code + xx boxes + goods_type + temperature

//        ...orders_fragment
const GET_SHIPMENT = gql`
  ${FRAGMENT_ORDERS}
  ${FRAGMENT_USERS}
  query ShipmentByPk($id: Int!) {
    shipment: shipments_by_pk (id: $id) {
      id
      created_at
      status
      shipper {
        name
      }
      sub_shipper {
        name
      }
      truck {
        reg_number
        driver_1: user_1 { ...driver }
        driver_2: user_2 { ...driver }
      }
      trailer {
        reg_number
      }
      orders (
        order_by: { shipment_position: asc, creation_date: desc }
      ){
        id
        supplier_id
        supplier {
          name
        }
        supplier_warehouse_id
        supplier_warehouse {
          name
          city
          street
          house_no
          post_code
          route_description
          door_code
          country {
           name
          }
        }
        units: order_units {
          colli
          packing_type_id
          packing_type {
            type
            code
          }
          cargo_type
        }
        temperature_range
      }
    }
  }
`;

