// stub: not used yet
import React, { Component } from "react";
import { Select, Tooltip } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const Option = Select.Option;

const ShipmentStatuses = (props) =>  {
  const { current, context, ...otherProps} = props;

  const { loading, error, data } = useQuery(GET_SHIPMENT_STATUSES);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
           <Select
              {...otherProps}
              defaultValue={current}
              _allowClear
              _showSearch
              placeholder="Select status"
              _optionFilterProp="children"
              _filterOption={(input, option) =>
                // todo: children may be as array
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {context === "order" &&
               <Option
                 title="Orders not assigned to any shipment yet"
                 key="-"
                 value="-"
                 style={{backgroundColor:"#f3f7be"}}
               >[-] Not assigned</Option>
              } 
              {data.shipment_statuses.map((item, key) => {
                return (
                  <Option key={key} value={item.status} disabled={item.status === "...some_status...?"}>
                    [{item.status}] {item.info}
                  </Option>
                );
              })}
            </Select>
  );
}

const GET_SHIPMENT_STATUSES = gql`
  query ShipmentStatuses {
    shipment_statuses (order_by: { position: asc }) {
      status
      position
      info
      description      
    }
  }
`;

export default ShipmentStatuses;
