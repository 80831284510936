import React from "react";
import { DatePicker as Picker } from "antd";
import moment from "moment";

const DatePicker = props => {
  const { value, onChange, otherProps } = props;

  return <Picker value={value?moment(value):null} onChange={onChange} {...otherProps}/>;
};

export default DatePicker;
