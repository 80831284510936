import React from "react";
import { message, Popconfirm, Button } from "antd";
import gql from "graphql-tag";
import { Mutation } from "@apollo/react-components";
import { DeleteOutlined } from "@ant-design/icons";
import { useStoreon } from "storeon/react";

const ShipmentDeleteBtn = props => {
  const { selection } = useStoreon("selection");
  const shipmentId = selection.shipmentId;

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         // could analyze constrain name:
         // "users_company_id_fkey"
         // "warehouses_company_id_fkey"
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
          if (error.message.includes("orders_shipment_id_fkey"))
                message.error('Shipment has attached orders');
          else
                message.error('Shipment has linked information, contact your administrator (constraint)');

         } else
                message.error('Server error, contact your administrator');
        });
  }

  return (
    <Mutation mutation={DELETE_SHIPMENT} refetchQueries={["Shipments"]}>
      {mutate => (
        <Popconfirm
          title={`Are you sure delete shipment id ${shipmentId}?`}
          onConfirm={e => {
            e.preventDefault();
            mutate({ variables: { id: shipmentId } })
               .then(() => { console.log(`shipment id ${shipmentId} deleted.`); })
               .catch((e) => { processError(e); });
          }}
          onCancel={e => {
            e.preventDefault();
          }}
          okText="Yes"
          cancelText="No"
        >
          {props.type === "menuItem" ? (
            /*<a href="#"> */
              <DeleteOutlined {...props} /* style={{ marginRight: "7px" }}*/ />
             /* {props.text?"Delete":""} */
            /*</a>*/
          ) : (
            <Button type="danger" icon="delete" size="small">
              Delete
            </Button>
          )}
        </Popconfirm>
      )}
    </Mutation>
  );
};

const DELETE_SHIPMENT = gql`
  mutation DeleteShipment($id: Int!) {
    delete_shipments(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export default ShipmentDeleteBtn;
