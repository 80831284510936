import * as React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";

import { ApolloLink, ApolloClient, useApolloClient, InMemoryCache, ApolloProvider, from, split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

import { WebSocketLink } from "@apollo/client/link/ws";
// import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import { TokenRefreshLink } from "apollo-link-token-refresh";

import { openNotification } from "./notification";

import { AUTH_TOKEN, REFRESH_TOKEN } from "./constant";
import * as serviceWorker from "./serviceWorker";
import { isTokenExpired, pretty_print_token, getUserTenant } from "./auth/authHelper";

import { StoreContext, useStoreon } from "storeon/react";
import { store } from "./state/index";
import history from './history.js';

import RootContainer from "./RootContainer";
import ErrorBoundary from "./ErrorBoundary";
import { ApolloProvider2 } from "./ApolloProvider2";

import { operation_add_tenant, operation_del_tenant }  from "./tenants";

import "./index.css";

const tokenRefreshLink = new TokenRefreshLink({
  isTokenValidOrUndefined: () => !isTokenExpired(localStorage.getItem(AUTH_TOKEN)),

  fetchAccessToken: () => {
    console.log("tokenRefreshLink.fetchAccessToken: ");
    return fetch(process.env.REACT_APP_SERVER_URL + "/auth/refresh-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token: localStorage.getItem(REFRESH_TOKEN),
      }),
    });
  },

  handleFetch: (accessToken) => {
    console.log("tokenRefreshLink.handleFetch: ");
    const { refresh_token, jwt_token } = accessToken;
    localStorage.setItem(AUTH_TOKEN, jwt_token);
    localStorage.setItem(REFRESH_TOKEN, refresh_token);
  },
/*
 * merged: disabled while under investigation
 *
 *
*/
 handleError: (error) => {
    // no hooks in handler
    console.log("tokenRefreshLink.handleError: ");

    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);

    // full control over handling token fetch Error
    console.warn('Your refresh token is invalid. Try to relogin');
    console.error(error);

    history.push({pathname: '/login', from: history.location.pathname }, {mode: 'clear'});
  },
});

const logoutLink = onError(({ networkError }) => {
  console.log("logoutLink: ", networkError );

  //console.log("logoutLink: history.b=", JSON.stringify(history, null, ' ') );

//  history.push({pathname: '/login', from: history.location.pathname }, {mode: 'clear'});
  history.push({pathname: '/logout', from: history.location.pathname }, {mode: 'clear'});


  //console.log("logoutLink: history.a=", history );

//  if (networkError.statusCode === 401) alert("401");
//  if (networkError.statusCode === 422) alert("422");
});

const mockLink = new ApolloLink((operation, forward) => {
  // const { tab } = useStoreon("tab");
  // useStoreon Invalid hook call. Hooks can only be called inside of the  
  // => store.tab.tenant ?
  const tenant = getUserTenant();

  console.log(`mockLink [${tenant}]:`, operation);
  console.log("token:", pretty_print_token(localStorage.getItem(AUTH_TOKEN)));

  // temporary switch off
  // if (1 === 1) return forward(operation);

  // crooked solution ... due db schemas (florunner - no prefix)
  //
  // !tenent - we assume by default there is no need to add tenant
  if (!tenant) {
    console.log(`mockLink empty tenant -> error`);
    throw "Empty tenant";
  } 

  //if (!tenant || tenant === "florunner") {
  if (tenant === "florunner") {
    operation_del_tenant(operation, "primeflower");
  } else {
    operation_add_tenant(operation, tenant);
  }
  return forward(operation);

});

const middleLink = onError((e) => {
  console.log("middleLink: ", e );
});

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    console.log("ErrorLink.onError: ");
    if (graphQLErrors)
      graphQLErrors.map((error) => {
        if (error.extensions.code === 'invalid-jwt') {
           localStorage.removeItem(AUTH_TOKEN);
           localStorage.removeItem(REFRESH_TOKEN);
           client.clearStore().then(() => {
              console.log("ErrorLink.onError: clear cache");
              client.resetStore();
           });

           history.push({pathname: '/login', from: history.location.pathname }, {mode: 'clear'});
           return;
        } else
           openNotification( "error", "Error", `[GraphQL error]: Message: ${error.message}`);
       }
      );

    if (networkError)
      openNotification(
        "error",
        "Error",
        `[Network error]: Message: ${networkError}`
      );
    forward(operation);
  }
);

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext((request, previousContext) => ({
  headers: {
//
// merged: previousContext.headers - under investigation
//
    ...previousContext.headers,
    authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` || "",
  },
}));
 
const wsLink = process.env.REACT_APP_GRAPHQL_WS_URL ? new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WS_URL,
  options: {
    reconnect: true,
    connectionParams: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  },
}) : null;

// for subscription we must use this link?
/*
const wsLink = new GraphQLWsLink(createClient({
  // uri process.env.REACT_APP_GRAPHQL_WS_URL + /subscriptions?
  uri: process.env.REACT_APP_GRAPHQL_WS_URL, 
  webSocketImpl: WebSocket,
  options: {
    reconnect: true,
    connectionParams: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  },
}));
*/

const splitLink = process.env.REACT_APP_GRAPHQL_WS_URL ? split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    console.log("splitLink: ", 
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription" ?  "wsLink" : "httpLink");

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
) : httpLink;

const client = new ApolloClient({
  //link: from([logoutLink, tokenRefreshLink, authLink, errorLink, splitLink]),
  //link: from([logoutLink, mockLink, tokenRefreshLink, mockLink, middleLink, authLink,  splitLink]),
  link: from([errorLink, mockLink, tokenRefreshLink, mockLink, middleLink, authLink,  splitLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

//////////////////////////////////////////////////////////////
/*
const client_primeflower = new ApolloClient({
  link: from([errorLink, mockLink, tokenRefreshLink, mockLink, middleLink, authLink,  splitLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

<ApolloProvider2.Provider value={client_primeflower}>
</ApolloProvider2.Provider>
*/
//////////////////////////////////////////////////////////////

const App = () => {
  return (
    <ApolloProvider client={client}>
      <StoreContext.Provider value={store}>
       <ErrorBoundary>
        <RootContainer />
       </ErrorBoundary>
      </StoreContext.Provider>
    </ApolloProvider>
  );
};

console.log("App: begin");
console.log("REACT_APP_GRAPHQL_WS_URL:", process.env.REACT_APP_GRAPHQL_WS_URL);

ReactDOM.render(<App />, document.getElementById("root"));
console.log("App: render end.");

// unregister?
serviceWorker.register();

//process.send('ready');
