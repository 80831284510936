import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery } from "@apollo/client";
import { Anchor, Link, List } from "antd";
import ShipmentCard from "./ShipmentCard";
import { ShipmentToolbar } from "./ShipmentToolbar";
import ShipmentFilterForm from "./ShipmentFilterForm";
import moment from "moment";
import { GET_SHIPMENTS } from "./graphql/shipments_graphql.js";

import { Layout } from 'antd';
const { Header, Footer, Sider, Content } = Layout;

const ShipmentList = (props) => {
  const { selection, dispatch, filter } = useStoreon("selection", "filter");
  const type = props.type;
  const [ shipmentId, setShipmentId ] = useState(selection.shipmentId);

/*
  useEffect((e) => {
    console.log("useEffect: fired ", e);
    if (!filter.shipment.range || filter.shipment.range == "") {
      dispatch("setShipmentFilter", {...filter.shipment, range: [moment().add(-3, 'days').startOf('day'), moment().endOf('day')]});
    }
    return ((v) => { console.log("useEffect: ret: ", v); });
  }, []);
*/

  const callBackDeselect = () => {
    setShipmentId(null);
    dispatch("deselect", { model: "shipment" });
  }
  const handleCardClick = (id) => {
    setShipmentId(id);
    if (props.callback)
       props.callback(id, callBackDeselect);
    else
       dispatch("select", { model: "shipment", id: id });

    dispatch("deselectRows", { model: "order" });
  };

  const { shipperId, active, range, state } = filter.shipment;

/*
    $shipperId: Int
    $active: Boolean
    $from: timestamptz
    $till: timestamptz
    $excluded_id: Int
    $type: String
    $state: [String!]

        _and: [
          { shipper_id: { _eq: $shipperId } }
          { active: { _eq: $active } }
          { departure_date: { _gt: $from } }
          { departure_date: { _lt: $till } }
          { id: { _neq: $excluded_id } }
          { type: { _eq: $type } }
          { state: { _in: $state } }
        ]
*/

  const { loading, error, data } = useQuery(GET_SHIPMENTS, {
    variables: {
      condition: {
        _and: [
          { shipper_id: shipperId? { _eq: shipperId } : {} },
          { active: active? { _eq: active } : {} },
          { departure_date: (range && range[0])? { _gt: range[0] } : {} },
          { departure_date: (range && range[1])? { _lt: range[1] } : {} },
          { id: props.excluded_id? { _neq: props.excluded_id } : {} },
          { type: type? { _eq: type } : {} },
          { state: state?.length? { _in: state } : {} }
        ]
      }
    }
    
/*
    variables: {
      shipperId: shipperId,
      active: active,
      from: range? range[0]: null,
      till: range? range[1]: null,
      excluded_id: props.excluded_id,
      type: type,
      state: state?.length?state:null,
    },
*/
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const decode_statuses = data?.shipment_statuses.reduce((r, s, i) => {
    r [s.status] = {info: s.info, desc: s.description, pos: s.position };
    return (r);
  }, []);

  return (
    <>
    <ShipmentFilterForm/>
    <Layout>
    <Header className="site-layout site-header"
          style={{
            height: "32px",
          }}
    >
        <ShipmentToolbar single={true} mode={props.excluded_id?"select":""} />
    </Header>
    <Content
      style={props.excluded_id?{
        overflowY: "auto",
        height: "52vh", /* move to parents */
      }
      : {
        overflowY: "auto",
        height: "82vh", /* move to parents */
      }
      }
    >
      <List
        size="small"
        dataSource={data.shipments}
        renderItem={(item) => 
            <List.Item onClick={() => handleCardClick(item.id)} style={item.id===shipmentId?{backgroundColor: '#ffff66'}:{}} >
              <ShipmentCard item={item} spr={data.shipment_statuses} spr_decode={decode_statuses}/>
            </List.Item>
        }
      />
    </Content>
   </Layout>
   </>
  );
};

export default ShipmentList;

/*
const GET_SHIPMENTS = gql`
  query Shipments($condition: shipments_bool_exp!)
  {
    shipments(
      order_by: { departure_date: desc }
      where: $condition
    ) {
      id
      type
      departure_date
      shipper_id
      active
      status
      orders {
        cargo {
          code
          type
        }
      }
      shipper {
        name
      }
      sub_shipper {
        name
      }
      truck {
        reg_number
      }
      trailer {
        reg_number
      }
      warehouse {
        name, city, street, house_no
      }
      files: files_aggregate(where: { hidden: { _eq: false } }) {
        aggregate {
          count(columns: id)
        }
      }
      state
      shipments_histories {
        status
        created_at
      }
    }
    shipment_statuses (
      order_by: { position: asc }
    ) {
      status
      position
      info
      description
    }
  }
`;
*/
