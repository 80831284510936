import React from "react";
import { message, Tag, Space, Switch, Popconfirm, Button } from "antd";
import { gql, useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";
import SwitchConfirm from "../shared/SwitchConfirm";

const { CheckableTag } = Tag;

// TODO: may be use it for delete button late?
const operations = {
 delete: {
   question: "Are you sure delete this company?",
   db: gql` mutation DeleteCompany($id: Int!) {
    delete_companies(
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
   }`,
   // ui: <Button type="danger" icon={<DeleteOutlined />} />
 },
 active: {
   question: "Are you sure change this company status?",
   db: gql` mutation SetActiveCompany($id: Int!, $active: Boolean!) {
    update_companies (
      where: { id: { _eq: $id } }
      _set: { active: $active }
    ) {
      affected_rows
    }
   }`,
   // ui: <Switch size="small" checkedChildren="active" unCheckedChildren="not active" />
 },
};
 
const CompanyOperBtn = ({disabled, ...props}) => {
  const operation = operations[props.operation];
  const [oper_company] = useMutation(operation.db, {
    refetchQueries: ["Companies"],
    onCompleted: (rez) => processOk(rez),
    onError: (e) => processError(e)
  });

  const perms = {...(disabled && { disabled })};

  const processOk = (r) => {
        console.log("Ok");
  }

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         // could analyze constrain name:
	 // "users_company_id_fkey"
	 // "warehouses_company_id_fkey"
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
	  if (error.message.includes("users_company_id_fkey"))
          	message.error('Company has attached users');
	  else if (error.message.includes("warehouses_company_id_fkey"))
          	message.error('Company has attached warehouses');
	  else
         	message.error('Server error, contact your administrator (constraint)');

         } else
         	message.error('Server error, contact your administrator');
        });
  }

  return (
    <SwitchConfirm
      checkedChildren="active"
      unCheckedChildren="not active"
      is_active={props.active}
      title={operation.question}
      onConfirm={() => {
        oper_company({ variables: { id: props.id, active: !props.active } });
      }}
      {...perms}
    />
  );
};

export default CompanyOperBtn;
