import React, { Component } from "react";
import { Select, Input } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const Option = Select.Option;

const TransactionTypes = (props) =>  {
 const { value_fixed, mode, value, onChange, ...otherProps } = props;

 console.log("TransactionTypes: props: ");
 console.dir(props);

  const { loading, error, data } = useQuery(GET_TYPES);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  // value={value && data.type_transaction.filter(i => i.type === value)[0]?.comment}
  // const props_ex = value_fixed? {onChange: ()=> {}} : {};
  console.log("return ...");
    return (
           value_fixed && !Array.isArray(value_fixed) ?
            <Input value={value_fixed} onChange={onChange} />
            :
            <Select
              {...otherProps}
              defaultValue={value_fixed}
              value={value}
              onChange={onChange}
             >
              {mode === "filter" && !Array.isArray(value_fixed) &&
               <Option
                 title="Transaction not processed yet"
                 key="-"
                 value="-"
                 style={{backgroundColor:"#f3f7be"}}
               >[-] Unprocessed</Option>
              }
		// TransactionAssignForm: value_fixed as single value `string`
		// TransactionsLocal: value_fixed as array of permitted types
              {data.type_transaction.filter(x => (!value_fixed || x.type == value_fixed || (Array.isArray(value_fixed) && value_fixed.includes(x.type)))).map((item, key) => {
                return (
                  <Option key={key} value={item.type}>
                    [{item.type}] {item.comment}
                  </Option>
                );
              })}
            </Select>
    );
}

const GET_TYPES = gql`
  query type_transaction {
    type_transaction (order_by: { type: asc }) {
      type
      comment
    }
  }
`;

const LOCAL_TYPES = ['CRT', 'ADM'];

export default TransactionTypes;
export { LOCAL_TYPES as TRANSACTIONS_LOCAL };
