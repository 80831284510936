import React from "react";

const Contact = props => {
  const { record, mode, index } = props;

  const sep = index>0?"; ":"";
  const desc = record.description?" ("+record.description+")":"";
  return (<span>{sep}{`${record.contact || ""}${desc}`}</span>);
  // return (<span><a href={"tel:"+`${record.contact}`}>{record.contact}</a> ({record.description}) </span> );

};

export default Contact;
