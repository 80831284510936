import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { message, Form, Input, Modal } from "antd";

const TrailerForm = () => {
  const [ form ] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { action, component } = ui.visibleForm;
  const { trailerId } = selection;

  useEffect(() => {
    if (action === "edit" && data?.trailer) {
      form.setFieldsValue(data.trailer);
    } else {
      form.resetFields();
    }
  });

  const goBack = (trailer_id) => {
    console.log("goBack: id="+trailer_id);
    dispatch("hideForm");
    // add - form was called from "connect_trailer"
    // TODO: use modal stack?
    if (action === "add") {
     dispatch("select", { model: "new_trailer", id: ""+trailer_id });

     console.log("goBack: add: id="+trailer_id);
     const p = { component:"connect_trailer" }
     dispatch("showForm", { ...p });
    }
  }

  const handleCompleted = (r) => {
    // "add "only
    goBack(r.insert_trailers?.returning[0]?.id);
  };

  const handleClose = (e) => {
    goBack();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        delete values.__typename;
        mutate({
          variables: {
            id: action === "edit" ? trailerId : undefined,
            objects: values
          }
        });
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  const { loading, error, data } = useQuery(GET_TRAILERS, {
    skip: !(action === "edit" && component === "trailer"),
    variables: {
      id: trailerId
    }
  });

  const [mutate] = useMutation(
    action === "edit" ? UPDATE_TRAILERS : INSERT_TRAILERS,
    {
      refetchQueries: [action==="add"?"TrailerSelect":"", "Trailers"],
      onCompleted: (r) => handleCompleted(r),
      onError: (e) => processError(e)
  });

  const processError = (e) => {
        console.log("TrailerForm: got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
          message.error('Trailer with same registration number already exists');
         } else
          message.error('Server error, contact your administrator');
        });
  }

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Modal
      title="Trailer form"
      visible={component === "trailer"}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={handleClose}
    >
      <Form layout="vertical" form={form} >
        <Form.Item
          label="Reg number"
          name="reg_number"
          rules={[{ required: true, message: "Please enter trailer number" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const INSERT_TRAILERS = gql`
  mutation CreateTrailer($objects: [trailers_insert_input!]!) {
    insert_trailers(objects: $objects) {
      affected_rows
      returning { id }
    }
  }
`;

const UPDATE_TRAILERS = gql`
  mutation UpdateTrailer($id: Int!, $objects: trailers_set_input!) {
    update_trailers(_set: $objects, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const GET_TRAILERS = gql`
  query TrailerById($id: Int!) {
    trailer: trailers_by_pk(id: $id) {
      id
      reg_number
    }
  }
`;

export default TrailerForm;
