import React, { Component, useState } from "react";

import TransactionsTable from "./TransactionsTable";

class Transactions extends Component {
  render() {
    return (
      <TransactionsTable type="ALL" />
    );
  }
}
/*
class TransactionsInc extends Component {
  render() {
    return (
      <TransactionsTable type="INC" />
    );
  }
}
class TransactionsOut extends Component {
  render() {
    return (
      <TransactionsTable type="OUT"/>
    );
  }
}
*/
/*
export const TransactionsInc = () => {
    return (
      <TransactionsTable type="INC" />
    );
}

export const TransactionsOut = () => {
    return (
      <TransactionsTable type="OUT"/>
    );
}
*/

export default Transactions;
