import React from "react";
import gql from "graphql-tag";
import { saveAs } from "file-saver";
import { useLazyQuery } from "@apollo/client";
import { useStoreon } from "storeon/react";
import { Button, Tooltip } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { GET_SHIPMENTS_BY_SELECTION } from "./graphql/shipments_graphql.js";

const ShipmentsExportBtn = () => {
  const { selection } = useStoreon("selection");
  const { shipmentRows } = selection;

  const [getShipments] = useLazyQuery(GET_SHIPMENTS_BY_SELECTION, {
    onCompleted: data => {
      fetch(process.env.REACT_APP_SERVER_URL + "/xlsx/shipments", {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
        method: "POST"
      })
        .then(res => res.blob())
        .then(blob => saveAs(blob, "shipments.xlsx"))
        .catch(err => {
          console.log(err);
        });
    }
  });

  const onClick = () => {
    getShipments({ variables: { shipmentsId: shipmentRows } });
  };

  return (
    <Tooltip title="Export selected shipments" placement="bottomLeft">
    <Button
      type="link"
      onClick={onClick}
      disabled={!shipmentRows.length > 0}
      icon={<ExportOutlined />}
    >
      {shipmentRows.length}
    </Button>
    </Tooltip>
  );
};

export default ShipmentsExportBtn;

