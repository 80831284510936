/*
 * TODO: �� ������ �������� ����������
  + ������ � ������ ��� ����� ����� - ��� ������
  ? ��� ����� ������� ����, ����� ��������� ������ � ����� ������� ��������, 
    ������ ������������ �� ��. ��� ��� ����� ������� �������� � ����� ������
    (������ ��������)
  + � � � ��� ������, ����� ����� ������ ���� �����, ����� ���� ���������� �� ���� � �� ��������� �� ���
  
*/
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { message, Modal, Form, Input, InputNumber, Statistic, Radio, Checkbox, Row, Col, Space } from "antd";
import { Typography } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import CompanySelect from "../shared/CompanySelect";
import CompanyAmounts from "../shared/CompanyAmounts";
import CurrencyInput from "../shared/CurrencyInput";
import CurrencyRates, { CURRENCY_LIST } from "../shared/CurrencyRates";

import { getUserTenant } from "../../auth/authHelper";
import { get_currency_list_by_tenant }  from "../../tenants";

import { INSERT_TRANSACTIONS, UPDATE_TRANSACTION } from "./graphql/transactions_graphql.js";

const CURRENCY_SPREAD  = 0.004;
const CURRENCY_ACCURACY = 2;
const RATE_ACCURACY = 4;
const AMOUNT_FOCUS_COLOR = "aquamarine";

const { Text } = Typography;

const ReverseRate = (props) => {
  const { isExchangeRateCustom, currencyRatesCustom, currencyRates } = props;
  const rate = isExchangeRateCustom ? currencyRatesCustom : currencyRates?.rate; 
  
  return rate ? <>Rate: <b>{(1 / rate).toFixed(RATE_ACCURACY)}</b></> : '';
   
}

/*
const RateEx = (props) => {
  const { isExchangeRateCustom, currencyRatesCustom, currencyRates, ...otherProps } = props;
  const [ rateEx, setRateEx ] = useState();

  const rate = isExchangeRateCustom ? currencyRatesCustom : currencyRates?.rate; 
  setRateEx (rate ? (1 / rate).toFixed(RATE_ACCURACY) : null);
  
  return 
    <InputNumber
       {...otherProps}
   />   
}
*/

/////////////////////////////////////////////////////////////////////
const TransactionForm = () => {
  const [form] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { auth, tab } = useStoreon("auth", "tab");
  const { action, component } = ui.visibleForm;
  const { transactionId } = selection;
  const [ companyId, setCompanyId ] = useState({}); // .from and .to
  const [ currency, setCurrency ] = useState({});   // .from and .to
//  const [ amountFrom, setAmountFrom ] = useState();
//  const [ amountTo, setAmountTo ] = useState();
  const [ AmountDirection, setAmountDirection ] = useState('from');

//  const [ amount1, setAmount1 ] = useState(4);
//  const [ amount2, setAmount2 ] = useState(8);

  const [ amountMax, setAmountMax ] = useState(0.0);
  const [ companyAmounts, setCompanyAmounts ] = useState({});
  const [ currencyRates, setCurrencyRates ] = useState(); //[]); 
  const [ currencyRatesCustom, setCurrencyRatesCustom ] = useState(); //[]);  // keep last custom rate
  const [ useComission, setUseComission ] = useState(false);
  const [ comission, setComission ] = useState();

  const [ isExchangeRateCustom, setIsExchangeRateCustom ] = useState(false); 

  const currencyIndex = CURRENCY_LIST; // ['USD', 'EUR', 'CNY', 'AED', 'HUF'];

/*
  const optionsCurrencyStub = [
    { label: 'USD',  value: 'USD', disabled: true },
    { label: 'Euro', value: 'EUR', disabled: true },
    { label: 'CNY',  value: 'CNY', disabled: true },
    { label: 'AED',  value: 'AED', disabled: true },
    { label: 'HUF',  value: 'HUF', disabled: true },
  ];
*/
  const optionsCurrencyStub = get_currency_list_by_tenant(getUserTenant()).map((c) => ({ label: c,  value: c, disabled: true }));
  const [ optionsCurrency, setOptionsCurrency ] = useState({from: [...optionsCurrencyStub], to: [...optionsCurrencyStub] }); 

  useEffect(() => {
    if (component === "transaction") {
      setOptionsCurrency({from: [...optionsCurrencyStub], to: [...optionsCurrencyStub] });
    }
  }, [component]);

  const closeForm = () => {
    dispatch("hideForm");
    form.resetFields();
    setCompanyId({});
//    setAmountFrom(0.0);
//    setAmountTo(0.0);
    setIsExchangeRateCustom(false);
    setOptionsCurrency({from: [...optionsCurrencyStub], to: [...optionsCurrencyStub] }); 
    setCurrency({});
    setCurrencyRates([]); 
    setCurrencyRatesCustom();
    setAmountMax(0);
    setUseComission(false);
    setComission();
    setAmountDirection('from');
  };

  const handleSubmit = async () => {

    // console.log(JSON.stringify(currencyRates));
    form
      .validateFields()
      .then(values => {
        delete values.__typename;

        //const actual_rate = isExchangeRateCustom ? values.rates_custom : currencyRates.rate;
        const actual_rate = AmountDirection === 'from'? values.rate_ex : 1/values.rate_ex ;

        //alert(JSON.stringify(auth));
        const t_ids = { from: uuidv4(), to: uuidv4()};

        const objs = [{
         id: t_ids.from,
         amount: -1 * values.amount_from,
         currency: values.currency_from,
         description: values.description,
         initiator_id: companyId.from,
         link_id: t_ids.to,
         type_transaction: "ADM",
         real_exchange_rate: actual_rate,
         linked_user: auth.login,
         linked_at: new Date(),
       },
       {
         id: t_ids.to,
         //amount: (values.amount * actual_rate).toFixed(CURRENCY_ACCURACY),
         amount: values.amount_to,
         currency: values.currency_to,
         description: values.description,
         initiator_id: companyId.to,
         link_id: t_ids.from,
         type_transaction: "ADM",
         real_exchange_rate: actual_rate,
         linked_user: auth.login,
         linked_at: new Date(),
       } 
       ];

       const objs_comm = !(useComission && values.comission > 0)? [] :
       [
       {
         id: uuidv4(),
         amount: (values.amount_from / 100 * values.comission * -1).toFixed(CURRENCY_ACCURACY),
         currency: values.currency_to,
         description: values.description,
         initiator_id: companyId.to,
         link_id: t_ids.to,
         type_transaction: "COM",
         real_exchange_rate: 1,
         linked_user: auth.login,
         linked_at: new Date(),
       },
       {
         id: uuidv4(),
         amount: (values.amount_from / 100 * values.comission).toFixed(CURRENCY_ACCURACY),
         currency: values.currency_to,
         description: values.description,
         initiator_id: auth.company_id,
         link_id: t_ids.to,
         type_transaction: "COM",
         real_exchange_rate: 1,
         linked_user: auth.login,
         linked_at: new Date(),
       },
       ];
       
       const bot_objs = [{
         id_transaction: t_ids.from,
         id_initiator: companyId.from,
         payload: "web",
        }, {
         id_transaction: t_ids.to,
         id_initiator: companyId.to,
         payload: "web",
        }];

        ///
        const m_data = { variables: {
      		id: action === "edit" ? transactionId : undefined,
		objects: [...objs, ...objs_comm],
                bot_queue: bot_objs} 
        };
        console.log ("mutate: "+JSON.stringify(m_data));
        //alert(JSON.stringify(m_data));
        mutate(m_data);
        //closeForm();
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  const handleOnChangeCompany = async (v) => {
     console.log("TransactiomForm.handleOnChangeCompany: v", v);
     const o = {...companyId, ...v};
     console.log("TransactiomForm.handleOnChangeCompany:", JSON.stringify(o));
     if (o.from && o.to && o.from == o.to) setUseComission(false);
     setCompanyId(o);

     const fields = {};
     // reset currency select
     if (v.hasOwnProperty('from')) fields.currency_from = null;
     else if (v.hasOwnProperty('to')) fields.currency_to = null;

     form.setFieldsValue(fields);
     setCurrency({...currency, [v.hasOwnProperty('from')?"from":"to"]: null});
     //setCurrencyRates([]);
          
     // reset rates and amounts
/*     
     form.setFieldsValue({'amount_to': null});
     if (v.from && AmountDirection === 'from' ) form.setFieldsValue({'amount_to': null});
     else if (v.to && AmountDirection === 'from' ) form.setFieldsValue({'amount_to': null});
*/
  };

  const handleOnChangeCompanyAmounts = (v) => {
     console.log("TransactiomForm.handleOnChangeCompanyAmounts: v", v);     

     setCompanyAmounts({...companyAmounts, ...v});
     if ("from" in v) {
       var t = optionsCurrency.from;
       var tt = v.from.amounts.map((a,i) =>  ({...t[i], disabled: v.from.limits && (!a || (a <= 0)) }) );
       setOptionsCurrency({...optionsCurrency, from: tt});

     } else if ("to" in v) {
       var t = optionsCurrency.to;
       var tt = v.to.amounts.map((a,i) =>  ({...t[i], disabled: false }) ); 
       setOptionsCurrency({...optionsCurrency, to: tt});
     } else
       console.error("handleOnChangeCompanyAmounts: unknown value", v);
  }   
  // Currency: begin ///////////////////////////////////////////////
  // async?
  const handleOnChangeCurrency = (v) => {
     const o = {...currency, ...v};
     console.log("TransactiomForm.handleOnChangeCurrency:", JSON.stringify(currency));
     console.log("amount_from: "+JSON.stringify(form.getFieldValue("amounts_from")));
     console.log("amount_to: "+JSON.stringify(form.getFieldValue("amounts_to")));
     if (companyAmounts.from && v.from) 
        if (companyAmounts.from.limits) {
	   //debugger;
           const max = companyAmounts.from.amounts[currencyIndex.indexOf(v.from)];
	   if (max > 0) setAmountMax(parseFloat(max.toFixed(CURRENCY_ACCURACY)));
        } else
           setAmountMax();

     if (o.from && o.to && o.from != o.to) setUseComission(false);
     setCurrency(o);

     // Recalculate amounts if we change currency
     //setTimeout(() => recalcAmounts(), 10);
  };
  const validatorCurrency = (value, mode) => {

      const value_pair = form.getFieldValue('currency_'+mode);
      if (!value || !value_pair) return Promise.resolve();


      if (value_pair !== value) {
        if (["CNY", "AED"].includes(value) && ["CNY", "AED"].includes(value_pair))
          return Promise.reject(new Error('You cannot transfer between CNY and AED!'));
        else
         return Promise.resolve();

      }
      else if (form.getFieldValue('company_id_from') != form.getFieldValue('company_id_to')) 
      {
         return Promise.resolve();
      }
                      
      return Promise.reject(new Error('You cannot transfer the same currency to yourself!'));
  };
  // Currency: end /////////////////////////////////////////////////


  // Rate: begin ///////////////////////////////////////////////
  const handleOnChangeIsExchangeRateCustom = (v) => {
/*
     if (currencyRates.rate && v && !form.getFieldValue('rates_custom')) {
        const f = {rates_custom: (currencyRates.rate).toFixed(RATE_ACCURACY) };
        form.setFieldsValue(f);
     }

     setIsExchangeRateCustom(v);
     const rate = (v ? currencyRatesCustom : currencyRates.rate); //||0.0

     if (rate) recalcAmounts(rate);
*/
     setIsExchangeRateCustom(v);
     

     // save for next 'on'
     //if (!v) setCurrencyRatesCustom(form.getFieldValue('rate_ex')); 

/*
     let rate;
     if (v) {
       rate = currencyRatesCustom ? currencyRatesCustom : currencyRates.rate;       
     } else {
       rate = AmountDirection !== 'to' ? currencyRates.rate : (1 / currencyRates.rate) ;
     }
*/

/**************
2023.04.08
    const rate = v ? 
       (currencyRatesCustom ? currencyRatesCustom : getActualRate())
       :
       getActualRate()
    ;
**************/
    const rate = getActualRate();

    const rate_value = rate !== null ? rate.toFixed(RATE_ACCURACY) : null;
    form.setFieldsValue({rate_ex: rate_value});

    if (rate_value) recalcAmounts(rate_value);
  }

  const getActualRate = (v_, d_) => {
    // const for debug
    // debugger;
    const v = (typeof v_ !== 'undefined') ? v_ : currencyRates;
    const d = (typeof d_ !== 'undefined') ? d_ : AmountDirection;

    // add HUF ... while we havn`t rates
    if (!v) return 0;

    const rate_actual = d === 'from' ? 
        v?.rate_spreaded ?? v.rate 
        :
        v?.rate_spreaded_rev ?? 1 / v.rate;

    console.log("getActualRate: ", v_, d_, v, d, rate_actual);
    return (rate_actual);
  }

  const handleOnChangeCurrencyRates = (v) => {
     //debugger;
     console.log("TransactiomForm.handleOnChangeCurrencyRates: ", JSON.stringify(v));
     setCurrencyRates(v);

     // Reset custom rate and switch to UCB mode
     setCurrencyRatesCustom(); 
     const _isExchangeRateCustom = false; // due async set...
     setIsExchangeRateCustom(_isExchangeRateCustom);

// check direction?

     const rate_actual = getActualRate(v);

     if (!rate_actual) {
       if (!_isExchangeRateCustom) form.setFieldsValue({rate_ex: null});
       if (AmountDirection) form.setFieldsValue({['amount_'+(AmountDirection === 'from'?'to':'from')]: null});
     } else

     if (!_isExchangeRateCustom && AmountDirection) {
       form.setFieldsValue({rate_ex: rate_actual});
       console.log("handleOnChangeCurrencyRates: need recalcAmounts()");
       recalcAmounts(rate_actual);
     }
  };
/*
  const handleOnChangeCurrencyRatesCustom = (v) => {
     console.log("TransactiomForm.handleOnChangeCurrencyRatesCustom: ", v);
     setCurrencyRatesCustom(v);
     console.log("handleOnChangeCurrencyRatesCustom: need recalcAmounts(force)");
     recalcAmounts(v);
  };
*/
  const handleOnChangeRateEx = (v) => {
     // debugger;
     console.log("TransactiomForm.handleOnChangeRateEx: ", v);
     if (isExchangeRateCustom) {
        // Always save direct rate as ucb        
        setCurrencyRatesCustom(AmountDirection !== 'to'? v : (1 / v).toFixed(RATE_ACCURACY));
     }
     console.log("TransactiomForm.handleOnChangeRateEx: need recalcAmounts(force)");
     recalcAmounts(v);
  };
  // Rate: end ////////////////////////////////////////////////

  // Amount: begin ////////////////////////////////////////////
  const recalcAmounts = (force_rate, force_direction) => {
    var _this = this;
    
    console.log("recalcAmounts: force_rate=", force_rate, " force_direction=", force_direction, "current_direction", AmountDirection);

debugger;
/*
    const rate = force_rate? force_rate : (isExchangeRateCustom?currencyRatesCustom:currencyRates.rate)||0.0;
*/
    const rate = force_rate? force_rate : form.getFieldValue("rate_ex");
    const direction = force_direction !== undefined ? force_direction : AmountDirection;


    var v_from = form.getFieldValue("amount_from");
    var v_to = form.getFieldValue("amount_to");       

    // if (v_from === null || v_to == null || rate == null || !direction) return;
    if ((v_from === null && v_to == null) || rate == null || !direction) return;

    var suffix, v = {};
    if (direction === "from") {
      if (v_from === null) return;
      suffix = 'to';
      // v_to
      v.to = v_from  ? (v_from * rate).toFixed(CURRENCY_ACCURACY) : null;
      console.log("recalcAmounts: "+v_from+"*"+rate+"="+v.to);
    } else
    if (direction === "to") {
      if (v_to === null) return;
      suffix = 'from';
//       v_from = v_to ? (v_to / rate).toFixed(CURRENCY_ACCURACY) : null;
      v.from = v_to ? (v_to * rate).toFixed(CURRENCY_ACCURACY) : null;
      console.log("recalcAmounts: "+v_to+"*"+rate+"="+v.from);
    }

    // form.setFieldsValue({ amount_from: v_from, amount_to: v_to }); 
    // change only one field
    form.setFieldsValue({ ['amount_'+suffix]: v[suffix] }); 
    form.validateFields(['amount_'+suffix]);
  }

  const handleOnChangeAmountFrom = (e) => {
      //setAmountFrom(e);
//      const value = (e * (isExchangeRateCustom?currencyRatesCustom:currencyRates.rate)||0.0).toFixed(CURRENCY_ACCURACY);
      const current_rate = form.getFieldValue('rate_ex');
      const value = (e * (form.getFieldValue('rate_ex')||0.0)).toFixed(CURRENCY_ACCURACY);
//      if (current_rate && ) {

      //setAmountTo(value);
      form.setFieldsValue({ amount_to: value });
      form.validateFields(["amount_to"]);
      console.log("handleOnChangeAmountFrom: ", current_rate, value);
  };
  const handleOnChangeAmountTo = (e) => {
      //setAmountTo(e);
//      const value = (e / (isExchangeRateCustom?currencyRatesCustom:currencyRates.rate)||0.0).toFixed(CURRENCY_ACCURACY);
//      const value = (e / (form.getFieldValue('rate_ex')||0.0)).toFixed(CURRENCY_ACCURACY);
      const current_rate = form.getFieldValue('rate_ex');
      const value = (e * (form.getFieldValue('rate_ex')||0.0)).toFixed(CURRENCY_ACCURACY);
      //setAmountFrom(value);
      form.setFieldsValue({ amount_from: value });
      form.validateFields(["amount_from"]);
      console.log("handleOnChangeAmountTo: ", current_rate, value);
  };

  const handleOnAmountFocus = (value, e) => {

      console.log('handleOnAmountFocus: ', value);
//    setAmountDirection(value);

//    const rate = isExchangeRateCustom ? currencyRatesCustom : currencyRates?.rate; 
//    if (rate) form.setFieldsValue({ rate_ex: (1 / rate).toFixed(RATE_ACCURACY) });

      setAmountDirection((value_prev) => { 
	if (value_prev && value_prev != value)  {
console.log('handleOnAmountFocus: setAmountDirection: ', value_prev, value);

// ���� ������, �� ���,
// � ���� ����, �� ����� �� ������ �� ������� ?

          const current_rate = form.getFieldValue('rate_ex');
          var new_rate;
          if (current_rate) {
             if (isExchangeRateCustom) {
               new_rate = 1 / current_rate;
             } else  {
               new_rate = getActualRate(currencyRates, value);
             }
             form.setFieldsValue({ rate_ex:  new_rate?.toFixed(RATE_ACCURACY) });
             // onChange for `rate_ex` not fired ...
             recalcAmounts (new_rate, value);
             console.log('handleOnAmountFocus: current_rate: '+current_rate+" -> "+new_rate);
          }
        }
	return value;
      });

  };
  const validatorAmount = (value, mode) => {
      console.log('validatorAmount: '+mode+" "+value+" < "+ amountMax);
      // form.getFieldValue('amount_from')
      if (mode === 'to' || !value || !amountMax || parseFloat(value) <= amountMax)
      {
         return Promise.resolve();
      }
                      
      return Promise.reject(new Error('You cannot withdraw more money than you have in this account.'));
  };

  // Amounts: end /////////////////////////////////////////////

/*
  const { loading, error, data } = useQuery(GET_COMPANY, {
    skip: !(action === "edit" && component === "transaction"),
    variables: {
      id: transactionId
    }
  });

  useEffect(() => {
    if (action === "edit") {
      //form.setFieldsValue(data?.company);
    }
    else
    if (action === "add") {
      console.log("TransactionForm: useEffect: add");
      form.resetFields();
      setCompanyId({});
      // form.setFieldsValue({ departure_date: moment().toDate() });
    }
  }, [data]);

*/

  const [mutate] = useMutation(
    action === "edit" ? UPDATE_TRANSACTION : INSERT_TRANSACTIONS,
    {
      variables: { id: action === "edit" ? transactionId : undefined },
      refetchQueries: ["Transactions", "Amounts", "Accounts"],
      onCompleted: () => closeForm(),
      onError: (e) => processError(e) // onError or mutate.then/catch
  });

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
          message.error('Transactions already exists, try Edit');
         } else
          message.error('Server error, contact your administrator');
        });
  };

/*
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
*/

  return (
    <Modal
      title="Manual transfer (Transaction form)"
      name="transaction_form"
      visible={component === "transaction"}
      onOk={handleSubmit}
      onCancel={closeForm}
      maskClosable={false}
      _width={640}
    >
{/*<pre>[{getUserTenant()}] {JSON.stringify(optionsCurrencyStub)}</pre>*/}
{/*<pre>[{amountMax}]</pre>*/}
{/*<pre>[{JSON.stringify(companyAmounts)}]</pre>*/}

      <Form layout="vertical" form={form}>
        <Form.Item
          label="Company [from]"
          name="company_id_from"
          rules={[{ required: true, message: "Please select company" }]}
        >
          <CompanySelect role="transaction_initiator" my_company_id={auth.company_id} onChange={(v) => { handleOnChangeCompany({from: v}); }} />
        </Form.Item>
        <Form.Item
          name="amounts_from"
        >
        {companyId?.from && <CompanyAmounts company_id={companyId.from} onChange={(v) => { handleOnChangeCompanyAmounts({from: v}); }} /> }
        </Form.Item>

{/*
           <Statistic title="Amount" value={1128} _prefix="p.amount" suffix="USD"
             dependencies={["company_id_from"]}
           /> 
<RegNumItem
          form={form}
          label="Reg number"
          name="reg_number"
          dependencies={["company_id"]}
          _rules={[{ required: true, message: "Please select truck number" }]}
          company_id={companyId}
          _shouldUpdate={(prevValues, currentValues) => prevValues.company_id !== currentValues.company_id }
        />
*/}

        <Form.Item
          label="Company [to]"
          name="company_id_to"
          rules={[{ required: true, message: "Please select company" }]}
        >
          <CompanySelect role="transaction_initiator" my_company_id={auth.company_id} onChange={(v) => { handleOnChangeCompany({to: v}); }} />
        </Form.Item>

        <Form.Item
          name="amounts_to"
        >
        {companyId?.to && <CompanyAmounts company_id={companyId.to} onChange={(v) => { handleOnChangeCompanyAmounts({to: v}); }} /> }
        </Form.Item>

<Space>
{/*// Currency: begin ///////////////////////////////////////////////////////////////*/}
        <Form.Item
          label="Currency [from]"
          name="currency_from"
          dependencies={["currency_to"]}
          rules={[{ required: true, message: "Please select currency from" },
                 ({ getFieldValue }) => ({
                    validator(_, value) { return validatorCurrency(value, 'to') }
                 })
          ]}
        >
          <Radio.Group options={optionsCurrency.from} optionType="button" buttonStyle="solid" size="small" onChange={(e) => { handleOnChangeCurrency({from: e.target.value}); }}/>
        </Form.Item>

        <Form.Item label="&nbsp;">
          <ArrowRightOutlined />
        </Form.Item>

        <Form.Item
          label="Currency [to]"
          name="currency_to"
          dependencies={["currency_from"]}
          rules={[{ required: true, message: "Please select currency to" },
                 ({ getFieldValue }) => ({
                    validator(_, value) { return validatorCurrency(value, 'from') }
                 }),
         ]}
        >
        
          <Radio.Group options={optionsCurrency.to} optionType="button" buttonStyle="solid" size="small"  onChange={(e) => { handleOnChangeCurrency({to: e.target.value}); }}/>
        </Form.Item>
</Space>
{/*// Currency: end /////////////////////////////////////////////////////////////*/}

{/*// Rate: begin ///////////////////////////////////////////////////////////////*/}
<Row>
<Col>
        <Form.Item 
          _label="&nbsp;"
        >
          <Checkbox 
             onChange={(e) => handleOnChangeIsExchangeRateCustom(e.target.checked) }
             checked={isExchangeRateCustom}
             disabled={ !currency.from || !currency.to|| currency.from == currency.to }
           >Manual&nbsp;Rate             
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="rates"
        >
          <CurrencyRates 
             dependencies={["currency_from", "currency_to", AmountDirection]}
             currency={currency} 
             onChange={(v) => { handleOnChangeCurrencyRates(v); }}
             disabled={isExchangeRateCustom || currency.from === currency.to}
             direction={AmountDirection}
         />
        </Form.Item>

{/*
        <Form.Item
          name="rates_custom"
        >
          <InputNumber
             disabled={!isExchangeRateCustom}
             placeholder="0,00"
             decimalSeparator=","
             onChange={(v) => { handleOnChangeCurrencyRatesCustom(v) }}
          />
        </Form.Item>
*/}
</Col>
</Row>

{/*// Rate: end /////////////////////////////////////////////////////////////////*/}

<Row align="top">
<Col span={6}>
{/*// Amounts: begin ////////////////////////////////////////////////////////////*/}

        <Form.Item
          label="Amount&nbsp;[from]"
          name="amount_from"
          dependencies={["amount_to"]}
          rules={[{ required: true, message: "Please provide amount from" },
                 ({ getFieldValue }) => ({
                    validator(_, value) { return validatorAmount(value, 'from') }
                 }),
          ]}
          wrapperCol={{span: 10}}
        >
          <InputNumber
             disabled={!(companyId.from && currency.from)}
             placeholder="0,00"
             decimalSeparator=","
             step="0.01"
             style={{ width: 150 }, { backgroundColor: AmountDirection === "from" ? AMOUNT_FOCUS_COLOR : "" }}
             min={0.00}
             _max={amountMax}
             onChange={handleOnChangeAmountFrom}
             onFocus={(e) => handleOnAmountFocus('from', e) }
          />

        </Form.Item>
</Col>
<Col span={2}>
        <Form.Item label="&nbsp;">
          { AmountDirection !== 'to' ? <ArrowRightOutlined /> : <ArrowLeftOutlined /> }
        </Form.Item>
</Col>
<Col span={6}>
        <Form.Item
          label="Rate"
          name="rate_ex"
          _rules={[{ required: true, message: "Please provide rate" } ]}
        >
        <InputNumber
           disabled={!(companyId.to && currency.to) || !(companyId.from && currency.from) || !isExchangeRateCustom}
           placeholder="0,00"
           decimalSeparator=","
           step="0.01"
           style={{ width: 150 }, { backgroundColor: "beige" }}
           onChange={handleOnChangeRateEx} 
       />
        </Form.Item>
</Col>
<Col span={2}>
        <Form.Item label="&nbsp;">
           { AmountDirection !== 'to' ? <ArrowRightOutlined /> : <ArrowLeftOutlined /> }
        </Form.Item>
</Col>
<Col span={6}>
        <Form.Item
          label="Amount&nbsp;[to]"
          name="amount_to"
          dependencies={["amount_from"]}
          rules={[{ required: true, message: "Please provide amount to" },
                 ({ getFieldValue }) => ({
                    validator(_, value) { return validatorAmount(value, 'to') }
                 }),
          ]}
        >
        <InputNumber
           disabled={!(companyId.to && currency.to)}
           placeholder="0,00"
           decimalSeparator=","
           step="0.01"
           style={{ width: 150 }, { backgroundColor: AmountDirection === "to" ? AMOUNT_FOCUS_COLOR : "" }}
           onChange={handleOnChangeAmountTo} 
           onFocus={(e) => handleOnAmountFocus('to', e) }
       />
        </Form.Item>
        {/* 
          AmountDirection === "to" && 
             <ReverseRate isExchangeRateCustom={isExchangeRateCustom} currencyRates={currencyRates} currencyRatesCustom={currencyRatesCustom} />
        */}
{/*// Amounts: end ////////////////////////////////////////////////////////////////*/}
</Col>
</Row>

{/*// Comission: begin ////////////////////////////////////////////////////////////*/}
<Space>
        { (companyId.from && companyId.to && companyId.from != companyId.to) &&
          (currency.from && currency.to && currency.from === currency.to) &&
        <Form.Item label="&nbsp;">
          <Checkbox 
             onChange={(e) => setUseComission(e.target.checked) }
             checked={useComission}>Comission
          </Checkbox>
        </Form.Item>
        }
        { useComission &&
        <>
        <Form.Item
          label="&nbsp;"
          name="comission"
          _rules={[{ required: true, message: "Please provide comission in percent" } ]}
          _todo="custom validation?"
        >
        <InputNumber
           placeholder="0,00"
           decimalSeparator=","
           addonAfter="%"
           step="0.01"
           controls={false}
           style={{ width: 100 }}
       />
        </Form.Item>{/*<Text> %</Text>*/}
       </>
       }
</Space>
{/*// Comission: end ////////////////////////////////////////////////////////////*/}

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please provide transaction description" },
                ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

/*
const INSERT_TRANSACTIONS = gql`
  mutation CreateTransaction($objects: [p_transactions_insert_input!]! $bot_queue: [bot_queue_insert_input!]!) {
    insert_p_transactions(objects: $objects) {
      affected_rows
    }
    insert_bot_queue (objects: $bot_queue) {
      affected_rows
    }
  }
`;

const UPDATE_TRANSACTIONS = gql`
  mutation UpdateCompany($id: Int!, $objects: p_transactions_set_input!) {
    update_p_transactions(_set: $objects, where: { id: { _eq: $id } }) {
      affected_rows
    }
    insert_bot_queue(objects: $objects) {
      affected_rows
    }
  }
`;
*/

/*
const GET_COMPANY = gql`
  query GetCompany($id: Int!) {
    company: companies_by_pk(id: $id) {
      id
      role
      email
      name
      short_name
      street
      house_no
      city
      post_code
      country_id
      vat_nr
      registration_nr
    }
  }
`;
*/
const GET_TRANSACTION = gql`
query get_transaction {
  p_transactions(order_by: {created_at: desc}) {
    id
    account_id
    amount
    currency
    description
    internal_reference
    type_transaction
    type_transaction_domain {
      comment
    }
    created_at
    initiator_company {
     id
     name
    }
    beneficiary_company {
     id
     name
    }
    remitter_company {
     id
     name
    }
    link_id
    linked_user
    linked_at
    synchronized_at
    comments
  }
}
`;

export default TransactionForm;
// <Radio.Group options={optionsCurrency} _onChange={onChange} _value={value3} optionType="button" size="small"/>
//                  { pattern: new RegExp(/^-?\d*(\.\d{0,2})?$/): '', message: "Should be a valid currency amount" }
/*
          <Input
             onChange={handleOnChangeAmount} 
             value={amount} 
             placeholder="0.00"
          />
*/
