export default (store) => {
  store.on("@init", () => ({
    ui: {
      visibleForm: {
        component: "", // "shipment", "order", etc
        action: "", // "add", "edit"
        model: "", // used in file uploader, "shimpent"
      },
      uploadForm: {
        visible: false,
        dataModel: "", // "order", "shipment"
        id: undefined, // data model id
      },
      shipmentFilterForm: {
        visible: false,
      },
    },
  }));

  store.on("showForm", ({ ui }, args) => {
    return {
      ui: {
        ...ui,
        visibleForm: {
          component: args.component, // "shipment", "order", etc
          action: args.action, // "add", "edit"
	  ...args,
        },
      },
    };
  });

  store.on("hideForm", ({ ui }) => {
    return {
      ui: {
        ...ui,
        visibleForm: {
          component: "", // "shipment", "order", etc
          action: "", // "add", "edit"
        },
      },
    };
  });

  store.on("showUploadForm", ({ ui }, args) => {
    return {
      ui: {
        ...ui,
        uploadForm: {
          visible: true,
          dataModel: args.dataModel,
          id: args.id,
        },
      },
    };
  });

  store.on("hideUploadForm", ({ ui }) => {
    return {
      ui: {
        ...ui,
        uploadForm: {
          visible: false,
          dataModel: "",
          id: undefined,
        },
      },
    };
  });

  store.on("showShipmentFilterForm", ({ ui }) => {
    return {
      ui: {
        ...ui,
        shipmentFilterForm: {
          visible: true,
        },
      },
    };
  });

  store.on("hideShipmentFilterForm", ({ ui }) => {
    return {
      ui: {
        ...ui,
        shipmentFilterForm: {
          visible: false,
        },
      },
    };
  });
};
