import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { message, Form, Input, Modal, Button, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import UserRoles from "../shared/UserRoles";
import PhoneInput from "react-phone-input-2";
import { isSupplier, isAdmin } from "../../auth/authHelper";

const UserForm = (props) => {
  const [form] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { action, component, role, driver_index, truck_id } = ui.visibleForm;
  const { userId, companyId } = selection;
  const { dummyId, setDummyId } = useState(null);
  const [ contactsLoaded, setContactsLoaded ] = useState();

  const closeForm = (d) => {
    //debugger;
    dispatch("hideForm");
  };

  const { loading, error, data } = useQuery(GET_USERS, {
    skip: !(component === "user" && action === "edit"),
    variables: {
      id: userId
    }
  });

  //const { loadin: d_loading, error: d_error, data: d_data } = useQuery(GET_DUMMY_USER, {
  const dummyResult = useQuery(GET_DUMMY_USER, {
    skip: !(component === "user" && action === "add"),
    fetchPolicy: "no-cache"
  });

  //if (d_data && d_data.id) {
  if (dummyResult && dummyResult.data) {
	//setDummyId( dummyResult.data.user[0].id);
  }

  useEffect(() => {
//debugger;
/*
console.log("userForm: driver_index="+driver_index);
console.log("userForm: role="+role);
console.log("userForm: props");
console.dir(props);
*/

    // console.log("useEffect: catched, action="+action);
    if (action === "edit" && data?.user) {
      console.log("useEffect: edit, set fields");
      form.setFieldsValue(data.user);
      setContactsLoaded(data.user.contacts);
    } else if (action === "add") {
      form.resetFields();
    }
  }, [action, data, dummyResult.data]);


  const [mutate] = useMutation(action === "edit" ? UPDATE_USER : INSERT_USER, {
    errorPolicy: 'none',
    refetchQueries: [ "Trucks", "Companies", "Drivers", "Users"], // Companies for tags (TODO: add only)
    onCompleted: (d) => closeForm(d),
    onError: (e) => processError(e) // onError or mutate.then/catch
  });

  const processError = (e) => {
	console.log("Got errors: ");
	e.graphQLErrors.map(error => {
	 console.log(error);
	 if (error.extensions.code === "constraint-violation") {
  	  console.log(`GOT IT! ${error.message}`);
	  message.error('User with same email already exists');
	 } else 
	  message.error('Server error, contact your administrator');
	});

  }

  const handleSubmit = () => {
debugger;
    form
      .validateFields()
      .then(values => {
        delete values.__typename;
        values.company_id = companyId;
        if (action === "edit") {
          let contacts = values.contacts, contacts_delete = [];

          contacts.forEach(v => {
            delete v.__typename;
            v.user_id = userId;
          });

          delete values.contacts;

          contactsLoaded.forEach((del) => {
           if (!contacts.filter((u) => u.id === del.id).length)
             contacts_delete.push(del.id);
          });

	  // 
          if (!isAdmin()) {
debugger;
            // due to permissions (see Companies.js)
            delete values.company_id;
            delete values.default_role;
            delete values.display_name;
            delete values.email;
            delete values.first_name;
            delete values.last_name;
          }

          mutate({
            variables: {
              id: userId,
              users_data: values,
              contacts_data: contacts,
              contacts_data_delete: contacts_delete,
            }
          })
          ;
        }
        if (action === "add") {
          if (values.contacts) {
            values = Object.assign(values, {
              contacts: { data: values.contacts }
            });

          }
console.log('*******************'+dummyId);
console.log('*******************'+dummyResult.data.user[0].id);
	  var v =  {
            objects: values,
            truck_id: 0, truck_data: {}
          };
console.dir(v);
	  if (role === "driver") {
            v.objects.id = dummyResult.data.user[0].id;
            v.truck_id = truck_id;
            v.truck_data["user_id_"+(1+driver_index)] = dummyResult.data.user[0].id;
          }
          mutate({ variables: v })
        }
        // form.resetFields();
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const perms = !isAdmin()? {disabled: true} : {};

  return (
    <Modal
      title="User form"
      visible={component === "user"}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={closeForm}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
	  // hidden=
	  initialValue={role} 
          label="User role"
          name="default_role"
          rules={[{ required: true, message: "Please select user role" }]}
        >
	{ !role &&
          <UserRoles {...perms}/>
          ||
          <Input readOnly {...perms}/>
        }
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please enter email"
            }
          ]}
        >
          <Input {...perms}/>
        </Form.Item>

        <Form.Item
          label="First name"
          name="first_name"
          rules={[{ required: true, pattern: new RegExp("^[a-zA-Z' -]*$"), message: "Please enter user first name" }]}
        >
          <Input {...perms}/>
        </Form.Item>
        <Form.Item
          label="Last name"
          name="last_name"
          rules={[{ required: true, pattern: new RegExp("^[a-zA-Z' -]*$"), message: "Please enter user last name" }]}
        >
          <Input {...perms}/>
        </Form.Item>
        <Form.Item
          label="Display name"
          name="display_name"
          rules={[{ required: true, message: "Please enter user display name" }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} {...perms} />
        </Form.Item>
        <Form.Item
          label="Job title"
          name="job_title"
          rules={[{ required: false, message: "Please enter job title" }]}
        >
          <Input />
        </Form.Item>
        <Form.List name="contacts">
          {(fields, { add, remove }) => (
            <div>
              {fields.map(field => {
                return (
                  <Row align="middle" justify="space-between" key={field.key}>
                    <Form.Item
                      name={[field.name, "contact"]}
                      fieldKey={[field.fieldKey, "contact"]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Add contact or delete field."
                        }
                      ]}
                    >
                      <PhoneInput placeholder="Enter phone number" />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "description"]}
                      fieldKey={[field.fieldKey, "description"]}
                    >
                      <Input placeholder="description" />
                    </Form.Item>

                    {/* {fields.length > 0 ? ( */}
                    <Form.Item>
                      <MinusCircleOutlined
                        style={{ margin: 10, color: "red" }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Form.Item>
                    {/* ) : null} */}
                  </Row>
                );
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                >
                  <PlusOutlined /> Add phone
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default UserForm;

const INSERT_USER = gql`
  mutation CreateUsers(
    $objects: [users_insert_input!]!
    $truck_id: Int!
    $truck_data: trucks_set_input!
  ) {
    insert_users(objects: $objects) {
      affected_rows
      returning { id }
    }
    update_trucks(_set: $truck_data, where: { id: { _eq: $truck_id } }) {
      affected_rows
    }
  }
`;

const GET_DUMMY_USER = gql`
query GetDummyUser {
  user: get_dummy_user {
    id
  }
}
`;

const UPDATE_TRUCK = gql`
  mutation UpdateTruck (
    $id: Int!
    $truck_data: trucks_set_input!
  ) {
    update_trucks_one(_set: $truck_data, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;


const GET_USERS = gql`
  query GetUsers($id: Int!) {
    user: users_by_pk(id: $id) {
      id
      default_role
      display_name
      first_name
      last_name
      contacts {
        id
        contact
        description
        user_id
      }
      email
      job_title
      company_id
    }
  }
`;

const DELETE_CONTACT = gql`
  mutation DeleteContact($id: Int!) {
    delete_contacts(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: Int!
    $users_data: users_set_input!
    $contacts_data: [contacts_insert_input!]!
    $contacts_data_delete: [Int!]!
  ) {
    update_users(_set: $users_data, where: { id: { _eq: $id } }) {
      affected_rows
    }
    insert_contacts(
      objects: $contacts_data
      on_conflict: {
        constraint: contacts_pkey
        update_columns: [contact, description]
        where: { user_id: { _eq: $id } }
      }
    ) {
      affected_rows
    }
    delete_contacts (where: { id: { _in: $contacts_data_delete } }) {
      affected_rows
    }
  }
`;
