import React, { useState } from "react";
import { message, Popconfirm, Button, Tooltip } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { FormOutlined } from "@ant-design/icons";
import { AUTH_TOKEN } from "../../../constant";

const ERROR_MESSAGE_DURATION = 5;

const LinkApproveBtn = props => {
  const { link, postProcessingHandler, ...otherProps } = props;
  const [state, setState] = useState({disabled: false, hide: null});

  // console.log("LinkApproveBtn: props:", props);

  const handleApproveLink = (link) => {
     const newWindow = window.open(link.link, '_blank', 'noopener,noreferrer')
     if (newWindow) newWindow.opener = null;
  };

  if (!link?.id) return (<></>);

  // <a style={{display: "table-cell"}} href={record.link} target="_blank">approve</a>


  // debug: add to title if you want to see url
  // "["+link.link+"]"
 
  return (
    <Popconfirm
      title={"Are you sure approve requisition?"}
      onConfirm={e => {
        e.preventDefault();
        console.log("approve: "+JSON.stringify(props));
        setState({...state, disabled: true});
        handleApproveLink (link);
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip placement="left" mouseLeaveDelay="0" title="Approve requisition" >
        <Button
           {...otherProps}
           type="link"
           icon={<FormOutlined />}
           disabled={state.disabled}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default LinkApproveBtn;
