import React from "react";
import { Popconfirm, Button, Tooltip } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";
//import { useStoreon } from "storeon/react";

const DriverDisconnectBtn = props => {
  //const { ui } = useStoreon("ui");
  //const { driver_index, truck_id } = ui.visibleForm;

  const handleCompleted = (d) => {
   debugger;
  }
  const [update_truck_driver] = useMutation(UPDATE_TRUCK_DRIVERS, {
    refetchQueries: ["Trucks", "Drivers", "Users"],
    onCompleted: (d) => handleCompleted(d)
  });

  return (
    <Popconfirm
      title="Are you sure disconnect this driver?"
      onConfirm={e => {
        e.preventDefault();
        var v = { id: props.truck_id, truck_data: {} };
        v.truck_data["user_id_"+(1+props.driver_index)] = null;
console.dir(v);
debugger;
        update_truck_driver({ variables: v });
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
<Tooltip placement="topLeft" title="Disconnect driver">
      <Button type="link" danger icon={<DeleteOutlined />} />
</Tooltip>
    </Popconfirm>
  );
};

const UPDATE_TRUCK_DRIVERS = gql`
  mutation UpdateTruck(
    $id: Int!
    $truck_data: trucks_set_input!
  ) {
    update_trucks(
      _set: $truck_data,
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export default DriverDisconnectBtn;
