import React, { Component } from "react";
import { Select } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const Option = Select.Option;

const Ibans = (props) =>  {

 console.log("props: ");
 console.dir(props);

  const { loading, error, data } = useQuery(GET_IBANS);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  console.log("return ...");
    return (
            <Select {...props}>
              {data.ibans.map((item, key) => {
                return (
                  <Option key={key} value={item.iban}>
                    {item.iban}
                  </Option>
                );
              })}
            </Select>
    );
}

const GET_IBANS = gql`
query Ibans {
  ibans: p_transactions(order_by: {iban_calc: asc}, distinct_on: iban_calc) {
    iban: iban_calc
  }
}`;

export default Ibans;
