import React, { Component, useState, useEffect } from "react";

import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { Table, Checkbox, Switch, Input, Typography } from "antd";

const { Text } = Typography;

const ExchangeSettings = (props) => {
  const PRECISION = 4; // after decimal point
  const PAGE_SIZE = 20;
  const [ edit, setEdit ] = useState();

const columns = [
  {
    title: "Pair",
    dataIndex: "pair",
    render: data => <span style={{whiteSpace: 'nowrap'}}>{ data.substr(0,3)+"-"+data.substr(3) }</span>,
    width: "1%",
  },
  {
    title: "Spread",
    align: 'right',
    dataIndex: "spread",
    render: (data, row) => <>{(edit && edit.data.pair == row.pair) ? 
      <Input maxLength="6" autoFocus={true} defaultValue={Number.parseFloat(data.toFixed(PRECISION))} onPressEnter={saveHandler} /> : Number.parseFloat(data.toFixed(PRECISION))+"%" }</>,
    width: "5%"
  },
/*
  {
    title: "Autoconversion",
    align: 'right',
    dataIndex: "type_exchange",
    _render: data => <>{data === 'AUTO'? 'Yes' : 'No'}</>,
//    __render: (e, record) => (< Switch onChange={() => handleSwitchChange(record)} defaultChecked={e} />),

    render: (data, row) => <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={data === 'AUTO'} 
          onChange={(a) => {
             const variable_data = {id: row.id, objects: {type_exchange: a?'AUTO':null}};

             if (a && !row.dir_exchange) variable_data.objects.dir_exchange = 'EUR-USD';

             update_company({variables: variable_data})
             .then(() => {
                console.log('company.type_exchange ... updated');
             })
             .catch((e) => {
                console.log('company.type_exchange ... not updated. '+e);
                alert('company not updated: error');
             });
          }}
    />,
    width: "1%"
  },
*/
  {
    dataIndex: "dummy",
    width: "95%"
  }
];


  const [update_spread_amount] = useMutation(UPDATE_PAIR_SPREAD, {
    refetchQueries: ["GetPairsSpreads"],
    onCompleted: (data) => {
      // alert('update_account: done');
    },
  });

  const clickHandler = (event, record, rowIndex) => {
    if (edit) return;
    setEdit({index: rowIndex, data: record });
  }

  const saveHandler = (event) => {

    debugger;

    const variable_data = {pair: edit.data.pair, objects: { spread: event.target.value }};
    if (isNaN(variable_data.objects.spread)) {
      alert('Bad number');
      // event.target.value = edit.data.spread.toFixed(0); // revert
      setEdit();       
      return;      
    }

    update_spread_amount({variables: variable_data})
    .then(() => {
       console.log('spread_amount ... updated');
       setEdit();
    })
    .catch((e) => {
       console.log('spread_amount ... not updated. '+e);
       alert('spread_amount: error');
    });
  }

  const { loading, error, data, refetch } = useQuery(
       GET_PAIRS_SPREADS, 
       { 
//          fetchPolicy: 'network-only',
       }); 

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  //return(JSON.stringify(data));  


  debugger;

  return (
    <>
      <Table
        rowKey="pair"
        pagination={data.p_exchange_spreads.length > PAGE_SIZE ? {pageSize: PAGE_SIZE} : false}
        columns={columns}
        dataSource={data.p_exchange_spreads}
//        rowClassName={(record, index) => (record.type != 'client' ? "bg-light-green" : "")}
        size="small"

        onRow={(record, rowIndex) => {
          return {
           onClick: event => { clickHandler(event, record, rowIndex); }, // click row
           onDoubleClick: event => {}, // double click row
           onContextMenu: event => {}, // right button click row
           onMouseEnter: event => {}, // mouse enter row
           onMouseLeave: event => {}, // mouse leave row
         };
        }}
      />
    </>
  );
};

const GET_PAIRS_SPREADS = gql`
query GetPairsSpreads { p_exchange_spreads (order_by: {pair: asc_nulls_last}) {
  pair, spread
}}
`;

const UPDATE_PAIR_SPREAD = gql`
mutation UpdatePairSpread ($pair: String!, $objects: p_exchange_spreads_set_input!) {
  update_p_exchange_spreads: update_p_exchange_spreads_by_pk (pk_columns: {pair: $pair}, _set: $objects)
  {pair}
}
`;

export default ExchangeSettings;
