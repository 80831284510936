import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useMutation } from "@apollo/client";
import { message, Form, Modal } from "antd";
import FormBtn from "../shared/FormBtn";
import TrailerSelect from "../shared/TrailerSelect";

const TrailerConnectForm = props => {
  const { ui, selection, dispatch } = useStoreon("ui", "selection");
  const { component } = ui.visibleForm;
  const [ form ] = Form.useForm();
  const { truckId, trailerId, new_trailerId } = selection;
/*
 useEffect(() => {
  debugger;
  });
*/

  const closeForm = () => {
    dispatch("hideForm");
    form.resetFields();
  };


  const [mutate] = useMutation(INSERT_TRUCK_TRAILER, {
    refetchQueries: ["Trucks", "Trailers"],
    onCompleted: () => dispatch("hideForm"),
    onError: (e) => processError(e) 
  });

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         // TODO: analyze? "truck_trailer_truck_id_trailer_id_key"
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
          message.error('This trailer already connected to curent truck');
         } else
          message.error('Server error, contact your administrator');
        });

  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        const trailerId = values.id;
        mutate({
          variables: { objects: { truck_id: truckId, trailer_id: trailerId } }
        });
        form.resetFields();
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      visible={component === "connect_trailer"}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={closeForm}
      title="Connect trailer to truck"
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Select trailer"
          name="id"
          rules={[{ required: true, message: "Please select trailer" }]}
        >
          <TrailerSelect />
        </Form.Item>
        <p> or add a new one </p>
        <FormBtn component="trailer" action="add" />
      </Form>
    </Modal>
  );
};

          // <TrailerSelect def_trailer_id={new_trailerId} />
const INSERT_TRUCK_TRAILER = gql`
  mutation InsertTruckTrailer($objects: [truck_trailer_insert_input!]!) {
    insert_truck_trailer(objects: $objects) {
      affected_rows
    }
  }
`;

export default TrailerConnectForm;
