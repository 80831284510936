// DEBUG ONLY

import React, { Component, useState } from "react";

import { useQuery } from "@apollo/client";
import { Table, Checkbox, Input, Col, Row } from "antd";

const columns = [
  {
    title: "id",
//    key: "id",
    dataIndex: "id",
  },
  {
    title: "amount",
//    key: "amount",
    dataIndex: "amount",
  },
  {
    title: "desc",
//    key: "amount",
    dataIndex: "desc",
  },
];

const showSelectedAmount = (props) => {
 const { data } = props;

alert(JSON.stringify(data));

 return <><p>EUR: {data.EUR||"0.00"} USD: {data.USD||"0.00"}</p></>
}

const TransactionsTableDebug = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState({USD:0, EUR:0, CNY:0});
  const [filter, setFilter] = useState({desc: '', linked: true});

  
  // selection ///////////////////////////////////////////////////////////////////////
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
//    test_data_1.filter((a) => newSelectedRowKeys.includesd(a.id)).reduce(r, t, i) =>  
//const spr_decode = data?.shipment_statuses.reduce((r, s, i) => {r[s.status] = {info: s.info, desc: s.description, pos: s.position }; return (r);}, []);

    //setTotalAmount(totalAmount+1);

/*    for (int i=0; i<newSelectedRowKeys.length; i++) {
         data?.shipment_statuses.reduce((r, s, i) => {r[s.status] = {info: s.info, desc: s.description, pos: s.position }; return (r);}, []);
    }
*/

    var selected_data = data.filter((t) => newSelectedRowKeys.find((id) => t.id == id));
    var selected_amount = selected_data.reduce((t, a) => { t[a.currency] += a.amount; return(t) }, {USD:0, EUR:0, CNY:0});
    setSelectedAmount(selected_amount);
    
  };
  ////////////////
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
/*
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
*/
        {
          key: 'data-all',
          text: 'Select All Data',
          onSelect: changableRowKeys => {
            onSelectChange(data.map((a) => a.id));
          },
        },
        {
          key: 'data-none',
          text: 'Clear All Data',
          onSelect: changableRowKeys => {
            onSelectChange([]);
            //setSelectedRowKeys([]);
          },
        },
    ],
    hideDefaultSelections: true,
  };
  ///////////////////////////////////////////////////////////////////////////////////

 const data_x =
[
{"id":"1","key":"1","account_id":"e8d32701-dc6b-45d6-9c09-b23194cb6b03","amount":-32,"currency":"EUR", "desc": "bal-bla 1" },
{"id":"2","key":"2","account_id":"e8d32701-dc6b-45d6-9c09-b23194cb6b03","amount":-22,"currency":"EUR", "desc": "bal-bla 2" },
{"id":"3","key":"3","account_id":"e8d32701-dc6b-45d6-9c09-b23194cb6b03","amount":-27,"currency":"USD", "desc": "bal-bla 3" },
{"id":"4","key":"4","account_id":"e8d32701-dc6b-45d6-9c09-b23194cb6b03","amount":-43,"currency":"CNY", "desc": "bal-bla 3" },
];

 const test_data_2 =
[
{"__typename":"p_transactions","id":"dd5e7175-527b-4b66-bd2f-453bd376629c","account_id":"e8d32701-dc6b-45d6-9c09-b23194cb6b03","amount":-32,"currency":"EUR","description":"KOSTEN  EM1602003481686I KOSTEN CORRESP   EUR       20,00 TRANSFERPROV.    EUR       12,00","internal_reference":"EM1602003481686I","type_transaction":null,"type_transaction_domain":null,"created_at":"2023-02-17T00:14:11.891+00:00","initiator_company":null,"beneficiary_company":null,"remitter_company":null,"link_id":null,"linked_user":null,"linked_at":null,"synchronized_at":"2023-02-17T00:14:12.199+00:00","comments":null,"remittance_information":"KOSTEN  EM1602003481686I KOSTEN CORRESP   EUR       20,00 TRANSFERPROV.    EUR       12,00","counterpart_name":"","counterpart_reference":"0000000000"},
{"__typename":"p_transactions","id":"100eb626-4a16-47c1-9e03-acd3b36443c7","account_id":"e8d32701-dc6b-45d6-9c09-b23194cb6b03","amount":-22,"currency":"EUR","description":"KOSTEN  EM1602003481688I KOSTEN CORRESP   EUR       10,00 TRANSFERPROV.    EUR       12,00","internal_reference":"EM1602003481688I","type_transaction":null,"type_transaction_domain":null,"created_at":"2023-02-17T00:14:11.279+00:00","initiator_company":null,"beneficiary_company":null,"remitter_company":null,"link_id":null,"linked_user":null,"linked_at":null,"synchronized_at":"2023-02-17T00:14:12.199+00:00","comments":null,"remittance_information":"KOSTEN  EM1602003481688I KOSTEN CORRESP   EUR       10,00 TRANSFERPROV.    EUR       12,00","counterpart_name":"","counterpart_reference":"0000000000"},
{"__typename":"p_transactions","id":"f8ea56b6-0288-4070-9cc2-6a80a3faf445","account_id":"e8d32701-dc6b-45d6-9c09-b23194cb6b03","amount":-27,"currency":"EUR","description":"KOSTEN  EM1602003481687I KOSTEN CORRESP   EUR       15,00 TRANSFERPROV.    EUR       12,00","internal_reference":"EM1602003481687I","type_transaction":null,"type_transaction_domain":null,"created_at":"2023-02-17T00:14:11.466+00:00","initiator_company":null,"beneficiary_company":null,"remitter_company":null,"link_id":null,"linked_user":null,"linked_at":null,"synchronized_at":"2023-02-17T00:14:12.199+00:00","comments":null,"remittance_information":"KOSTEN  EM1602003481687I KOSTEN CORRESP   EUR       15,00 TRANSFERPROV.    EUR       12,00","counterpart_name":"","counterpart_reference":"0000000000"},
{"__typename":"p_transactions","id":"e2fd81a3-470d-4c95-bfd5-353991716061","account_id":"e8d32701-dc6b-45d6-9c09-b23194cb6b03","amount":-22,"currency":"EUR","description":"KOSTEN  EM1602003481689I KOSTEN CORRESP   EUR       10,00 TRANSFERPROV.    EUR       12,00","internal_reference":"EM1602003481689I","type_transaction":null,"type_transaction_domain":null,"created_at":"2023-02-17T00:14:11.657+00:00","initiator_company":null,"beneficiary_company":null,"remitter_company":null,"link_id":null,"linked_user":null,"linked_at":null,"synchronized_at":"2023-02-17T00:14:12.199+00:00","comments":null,"remittance_information":"KOSTEN  EM1602003481689I KOSTEN CORRESP   EUR       10,00 TRANSFERPROV.    EUR       12,00","counterpart_name":"","counterpart_reference":"0000000000"},
];

  const data = (filter.desc||!filter.linked) ? 
     data_x.filter((t) => {
       var is_desc = filter.desc?t.desc.toLowerCase().includes(filter.desc.toLowerCase()):true;
       var is_link = !filter.linked?!t.initiator_company?.id:true;
       return is_desc & is_link;
     })
      :
     data_x;


  const handleChange = (pagination, filters, sorter, extra) => {
    console.log('handleChange: fired', pagination, filters, sorter);
/*
    setState((prev) => ({...prev,
      filteredInfo: filters,
      sortedInfo: sorter,
    }));
*/
    if (extra.action === "filter") {
debugger;
      const filteredRowKeys = extra.currentDataSource.map((r) => r.id);
      const intersection = selectedRowKeys.filter(x => filteredRowKeys.includes(x));
/*
      dispatch("selectRows", { model: "order", rowKeys: intersection });
      setState((prev) => ({...prev, selectedRowKeys: intersection }));
*/
    }
  };




 return (
    <>
      <Row type="flex" justify="start" gutter={8}>
        <Col span={2}><Input /></Col>
        <Col span={4}><Input placeholder="search in description ..."  onChange={(e) => setFilter({...filter, desc: e.target.value})} /></Col>
        <Col span={6}>EUR: <b>{(selectedAmount.EUR||0).toFixed(2)}</b> USD: <b>{(selectedAmount.USD||0).toFixed(2)}</b></Col>
        <Col span={12} />
      </Row>
      <Row>
        <Col span={24}>
<Table
            _rowKey="id"
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
            _pagination={{ pageSize: 2 }, {defaultPageSize: 2}, {showSizeChanger: false}}
            pagination={{ pageSize: 2 }}
            columns={columns}
            dataSource={data}
	    onChange={handleChange}
            title={() => <showSelectedAmount data={selectedAmount} /> }
            footer={(d)=>{
return d.reduce((t,a) => {
 return t+a.amount
 }, 0
)}
}
/>
        </Col>
      </Row>
    </>
  );
};


class TransactionsDebug extends Component {
  render() {
    return (
     <>
      <TransactionsTableDebug type="ALL" />
      <showSelectedAmount />
     </>
    );
  }
}

export default TransactionsDebug;
// DEBUG ONLY
