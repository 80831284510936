import React, { Component } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery } from "@apollo/client";
import { Table, Checkbox } from "antd";
import moment from "moment";

const columns = [
  {
    title: "Balance",
    dataIndex: "current_balance",
    width: "1%",
    align: 'right'
  },
  {
    title: "Currency",
    dataIndex: "currency",
    width: "1%"
  },
  {
    title: "Reference",
    dataIndex: "reference",
    width: "1%"
  },
  {
    title: "Type",
    dataIndex: "reference_type",
    width: "1%"
  },
  {
    title: "Sync at",
    dataIndex: "synchronized_at",
    render: date => <>{moment(date).format("DD.MM.YYYY")} </>,
    width: "1%"
  },
  {
    dataIndex: "synchronized_at",
    render: date => <>{moment(date).format("HH:mm:ss")} </>,
    align: 'right',
    width: "1%"
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Id",
    dataIndex: "id",
  }
];

const BankAccountsTable = (props) => {

  const { loading, error, data } = useQuery(GET_BANK_ACCOUNTS, { }); 
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

debugger;
  return (
    <>
      <Table
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={data.p_accounts}
        size="small"
      />
    </>
  );
};

// 2024.07.20 
// add: WHERE and ORDER
const GET_BANK_ACCOUNTS = gql`
query BankAccounts {
  p_accounts (where: {
      is_enabled: {_eq: true}
    },
    order_by: {position: asc}
   ){
    id
    currency
    current_balance
    description
    reference
    reference_type
    synchronized_at
  }
}
`;

/*
class BankAccountsTable extends Component {
  render() {
    return <BankAccountsTable />;
  }
}
*/

export default BankAccountsTable;
