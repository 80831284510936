import React from "react";
import { useStoreon } from "storeon/react";
import ShipmentList from "./ShipmentList";
import { Tabs } from 'antd';
import * as CONST from './Constants';

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const ShipmentListContainer = (props) => {

  const { dispatch } = useStoreon("selection");

  //const { selection } = useStoreon("selection");
  //const shipmentId = selection.shipmentId;
  //<Tabs defaultActiveKey="1" onChange={callback}>

  return(
  <Tabs defaultActiveKey={CONST.SHIPMENT_TYPE_TRUCK_KEY} onChange={() => dispatch("deselect", { model: "shipment" })}>
    <TabPane tab="Avia" key={CONST.SHIPMENT_TYPE_AVIA_KEY}>
     <ShipmentList type={CONST.SHIPMENT_TYPE_AVIA_KEY} {...props}/> 
    </TabPane>
    <TabPane tab="Truck" key={CONST.SHIPMENT_TYPE_TRUCK_KEY}>
     <ShipmentList type={CONST.SHIPMENT_TYPE_TRUCK_KEY} {...props}/> 
    </TabPane>
    <TabPane tab="Warehouse" key={CONST.SHIPMENT_TYPE_WAREHOUSE_KEY}>
     <ShipmentList type={CONST.SHIPMENT_TYPE_WAREHOUSE_KEY} {...props}/> 
    </TabPane>
  </Tabs>
  );

};

export default ShipmentListContainer;
