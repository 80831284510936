import React, { useState } from "react";
import { Row, Col } from "antd";
import { Layout } from 'antd';
import { useStoreon } from "storeon/react";
import ShipmentsTable from "../shipments/ShipmentsTable";

const { Header, Footer, Sider, Content } = Layout;

const ShipmentsPage = () => {
  const { selection } = useStoreon("selection");
  const shipmentId = selection.shipmentId;

  return (
    <Layout>
     <ShipmentsTable/>
    </Layout>
  );

};
export default ShipmentsPage;
