import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { message, Form, Popconfirm, Space, Table, Checkbox } from "antd";
import WarehouseModalBtn from "./WarehouseModalBtn";
import DeleteWarehouseBtn from "./DeleteWarehouseBtn";
import Address from "../shared/Address";
import EditableCell from "./EditableCell";
import { isSupplier, isAdmin } from "../../auth/authHelper";

const WarehousesTable = (props) => {
  const [form] = Form.useForm();
  //const { selection } = useStoreon("selection");
  //const { companyId } = selection;

  const companyId = props.company_id;

  const [mutate_door_code] = useMutation(UPDATE_WAREHOUSE_DOOR_CODE,
    {
      refetchQueries: ["Warehouses"],
    }
  );

  const { loading, error, data: _data } = useQuery(GET_WAREHOUSES, {
    skip: !companyId,
    variables: { id: companyId }
  });

  //const [originData, setOriginData] = useState(null);
  const [data, setData] = useState(null);
  const [state, setState ] = useState ({ dataSource: data, count: data?.length });

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;

  useEffect(() => {
     if (_data) {
       const data_array = JSON.parse(JSON.stringify(_data.warehouses)).map((e, i) => ({ ...e, index: i, key: i }));
       setData(data_array);
       setState({ dataSource: data_array, count: data?.length });
     }
  }, [_data]);

  const editDoorCode = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = (r) => {
    setEditingKey('');
  };

  const saveDoorCode = async (key) => {

    try {
      const row = await form.validateFields();
      const { dataSource } = state;
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);

      //for ref: newData.push({...row, position: newData.length, key: newData.length, index: newData.length});
      if (index === -1 ) {
        console.error('something goes wrong ...');
        setEditingKey('');
        return;
      }
      mutate_door_code(
	{ variables: {
                id: newData[index].id,
                door_code: row.door_code,
                }
        })
        .then((res) => {
          const item = newData[index];
          delete item.just_added;
          newData.splice(index, 1, { ...item, ...row });
          setData(newData);
          setEditingKey('');
        })
        .catch(info => {
          console.error("mutate_door_code: ", info);
          message.error("Can`t update wharehouse door code");
          setEditingKey('');
        });

    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }

  };
//////////////////////////////////////////////////////
const columns = [
  {
    title: "Name",
    dataIndex: "name"
  },
  {
    title: "Address",
    render: record => (
      <Address record={record} mode="full" />
    )
  },
  {
    title: "Door Code",
    dataIndex: "door_code",
    align: 'right',
    render: (text, record, index) => { 
//debugger;
       const editable = isEditing(record);
       return <EditableCell
                        editing={editable}
                        dataIndex="door_code"
                        title="Door_code"
                        record={record}
                        index={index}
                        children={record.door_code}
                      />
       },
  },
/*
            <Popconfirm title="Sure to cancel?" onConfirm={(e) => cancel(r)}>
              <a>Cancel</a>
            </Popconfirm>
*/
  {
    title: "",
    render: (r) => {
        const editable = isEditing(r);
        return editable ? (
          <span>
            <a
              _href="javascript:;"
              onClick={() => saveDoorCode(r.key)}
              style={{ marginRight: 8, }}
            >
              Save
            </a>
            <a
              _href="javascript:;"
              onClick={(e) => cancel(r)}
              style={{ marginRight: 8, }}
            >
              Cancel
            </a>
          </span>
        ) : (
          <Space>
          <a disabled={editingKey !== ''} onClick={(e) => editDoorCode(r) }>
            Edit
          </a>
          </Space>
        );
      },
  },
  {
    title: "Default",
    render: record => (
      <Checkbox disabled={true} checked={record.default_warehouse} />
    )
  },
  {
    title: "",
    render: record => (
      <div style={{ float: "right" }}>
        <WarehouseModalBtn id={record.id} company_id={record.company_id} action="edit" />
        <DeleteWarehouseBtn id={record.id} {...perms}/>
      </div>
    )
  }
];
////////////////////////////////////////////////////////

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const perms = !isAdmin()? {disabled: true} : {};

  return (
    <>
      <WarehouseModalBtn company_id={companyId} action="add" {...perms} />
      <Form form={form}>
      <Table
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={data}
        size="small"
      />
      </Form>
    </>
  );
};

const GET_WAREHOUSES = gql`
  query Warehouses($id: Int) {
    warehouses(
     where: { company_id: { _eq: $id } },
     order_by: [
	{ default_warehouse: desc },
	{ name: asc }
     ]
    )
    {
      id
      name
      street
      house_no
      post_code
      city
      door_code
      working_hours_from
      working_hours_till
      route_description
      default_warehouse
      company_id
      country {
        alpha3
      }
    }
  }
`;

const UPDATE_WAREHOUSE_DOOR_CODE = gql`
  mutation UpdateWarehouseDoorCode($id: Int!, $door_code: String!) {
    update_warehouses(
        _set: { door_code: $door_code } ,
        where: { id: { _eq: $id } }
        ) {
      affected_rows
    }
  }
`;

export default WarehousesTable;
