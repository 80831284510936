import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { message, Form, Input, Modal } from "antd";
import CompanyRoles from "../shared/CompanyRoles";
import Countries from "../shared/Countries";
import { isSupplier, isAdmin } from "../../auth/authHelper";
import moment from "moment";

const CompanyForm = () => {
  const [form] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { action, component } = ui.visibleForm;
  const { companyId } = selection;

  useEffect(() => {
    if (action === "edit") {
      form.setFieldsValue(data?.company);
    }
    else
    if (action === "add") {
      form.resetFields();
      form.setFieldsValue({ departure_date: moment().toDate() });
    }
  });

  const closeForm = () => {
    dispatch("hideForm");
  };

  const handleSubmit = () => {

    form
      .validateFields()
      .then(values => {
        delete values.__typename;
	// console.log("handleSubmit: values:", values);
        if (!isAdmin()) {
          // due to permissions
          // at current moment we can`t get all fields via form.getFieldInstance()
          // and can`t iterate all form iterms for `required` attribute
       
          //Object.keys(values).forEach((f) => console.log(f));
          //for (var field in values) if (!form.getFieldInstance([field]).props.disabled) delete values[field];
          delete values.role;
          delete values.name;
          delete values.country_id;
        }
        mutate({ variables: {
      		id: action === "edit" ? companyId : undefined,
		objects: values } });
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  const { loading, error, data } = useQuery(GET_COMPANY, {
    skip: !(action === "edit" && component === "company"),
    variables: {
      id: companyId
    }
  });

  const [mutate] = useMutation(
    action === "edit" ? UPDATE_COMPANY : INSERT_COMPANY,
    {
      variables: { id: action === "edit" ? companyId : undefined },
      refetchQueries: ["Companies"],
      onCompleted: () => dispatch("hideForm"),
      onError: (e) => processError(e) // onError or mutate.then/catch
  });

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
          message.error('Company already exists, try Edit');
         } else
          message.error('Server error, contact your administrator');
        });
  }

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const perms = !isAdmin()? {disabled: true} : {};

  return (
    <Modal
      title="Company form"
      name="company_form"
      visible={component === "company"}
      onOk={handleSubmit}
      onCancel={closeForm}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: "Please select role" }]}
        >
          <CompanyRoles {...perms}/>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please select name" }]}
        >
          <Input {...perms}/>
        </Form.Item>

        <Form.Item
          label="Short name"
          name="short_name"
          rules={[{ required: false, message: "Please select short name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Street"
          name="street"
          rules={[{ required: false, message: "Please select street" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="House"
          name="house_no"
          rules={[{ required: false, message: "Please provide house" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Postcode"
          name="post_code"
          rules={[{ required: false, message: "Please provide postcode" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="City"
          name="city"
          rules={[{ required: false, message: "Please provide city" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Country"
          name="country_id"
          rules={[{ required: true, message: "Please select country" }]}
        >
          <Countries {...perms}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const INSERT_COMPANY = gql`
  mutation CreateCompany($objects: [companies_insert_input!]!) {
    insert_companies(objects: $objects) {
      affected_rows
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: Int!, $objects: companies_set_input!) {
    update_companies(_set: $objects, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const GET_COMPANY = gql`
  query GetCompany($id: Int!) {
    company: companies_by_pk(id: $id) {
      id
      role
      email
      name
      short_name
      street
      house_no
      city
      post_code
      country_id
      vat_nr
      registration_nr
    }
  }
`;

export default CompanyForm;
