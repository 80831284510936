import React from "react";
import { Button } from "antd";
import { AUTH_TOKEN } from "../constant";

export const GetUserButton = () => {
  const callApi = () => {
    fetch(process.env.REACT_APP_SERVER_URL + "/auth/user", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN),
        "cache-control": "no-cache"
      },
      method: "GET"
    })
      .then(res => console.log(res))
      .catch(err => {
        console.log(err);
      });
  };

  return <Button onClick={callApi}> Get user </Button>;
};
