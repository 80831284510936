import React, { Component, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Form, Input, Modal, Drawer, Alert, Button } from "antd";
import { Col, Row, Statistic, Collapse, Table } from 'antd';

import useFetch from "../utils/utils";

import { AUTH_TOKEN } from "../../../constant";

const IFrame = ({ children, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;
  return (
    <iframe {...props} ref={setContentRef} style={{height: "100%", width: "100%", border: "0"}}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

const create_new_consent = async () => {

  console.log('create_new_consent: ... ');
  const api_url = process.env.REACT_APP_SERVER_URL + "/auth/raiffeisen/consent";
  console.log('create_new_consent: api: ', api_url);

  //alert('create_new_consent: begin: try to await');
  fetch(api_url,
        {headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN),
          Accept: "application/json",
          "cache-control": "no-cache"
        },
        method: "GET", 
        })
        .then(data => {
          //alert('create_new_consent: data ...');

          // for detecting text->json problerm
          // const json = data.json();
          return (data.text());
        })
        .then(text => {
          //alert('create_new_consent: try to parse json ...');
          try {
            const data = JSON.parse(text); // Try to parse the response as JSON
            //alert('create_new_consent: json ...');
            return (data);
            // The response was a JSON object
            // Do your JSON handling here
          } catch(err) {
            // The response wasn't a JSON object
            // Do your text handling here
            alert('create_new_consent: catch:' + err);
            throw new Error("Did not receive JSON, instead received: " + text)  
          }
        })
        .catch(err => {
          alert('create_new_consent: error ... '+err);
          return ({error: err});
        });

  //alert('create_new_consent: return: waiting ...');
  console.log('create_new_consent: done');     
};

const asyncFetch = async (url) => {
  console.log("asyncFetch: try url:", url);
  const data = await fetch(url,
    {headers: {
      "Content-Type": "application/json",
       Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN),
       Accept: "application/json",
      "cache-control": "no-cache"
      },
      method: "GET", 
    });
  console.log("asyncFetch: got data:", data);

  const json = await data.json();

  return json;
};

const asyncFetchWrapper = async (url) => {
  const data = await asyncFetch(url);
  return data;
};

const ConsentCreate = ({state, fn:setState, ...otherProps}) => {
  // nope, error, create, confirm, not_created

  const [consentData, setConsentData] = useState();

  console.log('ConsentCreate: props:', otherProps);
  const api_url = process.env.REACT_APP_SERVER_URL + "/auth/raiffeisen/consent";
  console.log('ConsentCreate: api: ', api_url);

  useEffect(() => {
    console.log('ConsentCreate: useEffect: state:', state?.status);
    if (state?.status === 'create') {
       //alert('useEffect: create_new_consent: begin');
       //debugger;
       asyncFetchWrapper(api_url)
       .then((res) => {
          console.log('ConsentCreate: create_new_consent() => ', res);  
	  // alert('useEffect: create_new_consent: end: '+JSON.stringify(res));
          if (res?.data?.location) { 
            setConsentData(res);
            setState({...state, status: 'confirm'});
          } else {
            console.log('ConsentCreate: bad data');  
            setConsentData(null);
            setState({...state, status: 'not_created'});
          }})
       .catch((err) => {
         console.log('ConsentCreate: catch err', err);  
         alert('ConsentCreate: catch err: '+err);  
         setConsentData();
         setState({...state, status: 'not_created'});
       });
    }
  }, [state]);

  if (state.status === 'create') return 'creating ...';
  // if (state.status === 'error') return `error ... [${consentError}]`;

/*
  const [consentLoading, consentError, consentData] = useFetch( api_url, "GET");

  useEffect(() => {
    if (consentData?.data?.location) {
      setState('confirm');
      console.log ('status: confirm');
    } else {
      setState('not_created');
      alert ('status: not_created');
    }
  }, [consentLoading, consentError, consentData]);
*/


// {"consentStatus":"received","consentId":"4ff4892f-7e5a-4b77-805d-e6c407e6ed98","scaMethods":[],"_links":{"scaRedirect":{"href":"https://hu-api-sandbox.raiffeisen.hu/rbhu/prod02/psd2-rbhu-bridge-api/bridge/authorize?consentId=4ff4892f-7e5a-4b77-805d-e6c407e6ed98"},"status":{"href":"https://hu-api-sandbox.raiffeisen.hu/rbhu/prod02/psd2-bgs-consent-api-1.3.2-rbhu/v1/consents/4ff4892f-7e5a-4b77-805d-e6c407e6ed98/status"}}}}

  const handleOk = () => {
    setState({...state, status: 'nope'});
  };
  const handleCancel = () => {
    setState({...state, status: 'nope'});
  };

  return (
     <>
      { (state.status === 'not_created') && 
      <Alert
        message={`Can't create new consent [${consentData?.data?.consentStatus}]`}
        type="error"
        closable
        onClose={() => setState({...state, status: 'nope'})}
      />
      }
      <Modal
        title="Confirmation"
        centered
        open={state.status === 'confirm'}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
        bodyStyle={{height: 700, maxHeight: 800}}
      >
        {/*<a target="_blank" href={consentData?.data?.location}>debug: link</a>*/}
        <IFrame src={consentData?.data?.location}>
          loading ...<br/>
          {/*<pre>{consentData?.data?.location}</pre>*/}
        </IFrame>
      </Modal>
     </>
  );
}

export default ConsentCreate;