import React from "react";
import { useStoreon } from "storeon/react";
import { Button } from "antd";
import { FilterOutlined, FilterFilled } from "@ant-design/icons";

const ShipmentFilterBtn = () => {
  //const { dispatch } = useStoreon();
  const { dispatch, filter } = useStoreon("filter");


  const handleClick = () => {
debugger;
    dispatch("showShipmentFilterForm");
  };

  return (
    <Button onClick={handleClick} type="link">
     { (filter.shipment.isOn &&
      <FilterFilled />) || <FilterOutlined />
     }
      Filter
    </Button>
  );
};

export default ShipmentFilterBtn;
