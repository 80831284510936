import React from "react";
import { message, Popconfirm, Button } from "antd";
import { gql, useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";

const CompanyDeleteBtn = ({disabled, ...props}) => {
  const [delete_company] = useMutation(DELETE_COMPANY, {
    refetchQueries: ["Companies"],
    //onCompleted: () => closeForm(),
    onError: (e) => processError(e)
  });
  const perms = {...(disabled && { disabled })};

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         // could analyze constrain name:
	 // "users_company_id_fkey"
	 // "warehouses_company_id_fkey"
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
	  if (error.message.includes("users_company_id_fkey"))
          	message.error('Company has attached users');
	  else if (error.message.includes("warehouses_company_id_fkey"))
          	message.error('Company has attached warehouses');
	  else
         	message.error('Deletion is impossible due to the availability of information about the company\'s actions');

         } else
         	message.error('Server error, contact your administrator');
        });
  }

  return (
    <Popconfirm
      title="Are you sure delete this company?"
      onConfirm={e => {
        e.preventDefault();
        delete_company({ variables: { id: props.id } });
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button type="danger" icon={<DeleteOutlined />} {...perms}/>
    </Popconfirm>
  );
};

const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: Int!) {
    delete_companies(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export default CompanyDeleteBtn;
