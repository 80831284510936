import React, { useState } from "react";
import { message, Tooltip, Popconfirm, Button, Modal } from "antd";
import { useStoreon, StoreContext } from "storeon/react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { DragOutlined } from "@ant-design/icons";
import ShipmentListContainer from "../shipments/ShipmentListContainer";
import { store_ex } from "../../state/store_ex";

const ShipmentListTo = ShipmentListContainer;

const ConfirmMoveBtn = (props) => {
  return (
    <Popconfirm
      title="Are you sure move selected orders?"
      onConfirm={e => {
        e.preventDefault();
        if  (props.onClick) props.onClick();
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip placement="topLeft" title="Move orders">
        <Button
           key="submit"
           type="primary" /*loading={loading}*/
           icon={<DragOutlined  />}
           disabled={props.disabled}
        > Move </Button>
      </Tooltip>
    </Popconfirm>
  );
}

const OrdersMoveBtn = (props) => {
  const [ visible, setVisible ] = useState(false);
  const { selection, dispatch } = useStoreon("selection");
  const { orderRows, shipmentId } = selection;

  const [ shipmentIdTo, setShipmentIdTo ] = useState();
  const [ deselectShipmentIdTo, setDeselectShipmentIdTo ] = useState();

  const handelShipmentSelect = (id, callback) => {
    // may be use this one without ok button
    //dispatch("select", { model: "shipment", id: id });
   debugger;
    console.log(id);
    setShipmentIdTo(id);
    if (callback) setDeselectShipmentIdTo({f: callback});
  }

  const handleCompleted = (d) => {
   debugger;
   if (d.update_orders.affected_rows === orderRows.length) {
	dispatch("selectRows", { model: "order", rowKeys: [] });
        setVisible(false);
   } else {
        console.log("Update error: selected: "+orderRows.length+", updated: "+d.update_orders.affected_rows);
        message.error('Something was wrong ... contact your administrator');
   }
   if (deselectShipmentIdTo) deselectShipmentIdTo.f();
  }

  const handleCancel = () => {
debugger;
   // TODO: deselect shipment?
   setShipmentIdTo(null);
   setVisible(false);
   if (deselectShipmentIdTo) deselectShipmentIdTo.f();
  }

  const handleMove = () => {
   debugger;
   console.log("execute update mutation");
   var v = { id: orderRows, objects: {shipment_id: shipmentIdTo } };
   console.dir(v);
   update_orders({ variables: v });
  }

  const [update_orders] = useMutation(UPDATE_ORDER, {
    refetchQueries: ["Shipments", "Orders"],
    onCompleted: (d) => handleCompleted(d)
  });

  return (
    <>
      <Tooltip title="Move selected orders" placement="bottomLeft">
      <Button
        key="open_move" 
        disabled={!orderRows.length > 0 || props.disabled}
        type=""
        icon={<DragOutlined />}
        onClick={() => setVisible(true)}
      >
        {orderRows.length}
      </Button>
      </Tooltip>
      <Modal
        closable={false}
        title="Move selected orders to to new shipment"
        zIndex={1000-10}
        visible={visible}
        onCancel={handleCancel}
 	_bodyStyle={{overflowY: "auto", height: "72vh"}}
 	_style={{ height: "22vh"}}

        footer={[
            <Button key="back" onClick={handleCancel}> Cancel </Button>,
            <ConfirmMoveBtn key="move" onClick={handleMove} disabled={!shipmentIdTo}/>
          ]}
      >
       { /* <SelectShipment/> */}
       <StoreContext.Provider value={store_ex}>
        <ShipmentListTo
           callback={handelShipmentSelect}
           excluded_id={shipmentId}
        />
       </StoreContext.Provider>

      </Modal>
    </>
  );
};

const UPDATE_ORDER = gql`
  mutation MoveOrder($id: [Int!], $objects: orders_set_input!) {
    update_orders(_set: $objects, where: { id: { _in: $id } }) {
      affected_rows
    }
  }
`;

export default OrdersMoveBtn;
