import React, { useEffect, useState, useRef, createRef, useCallback } from "react";
import moment from "moment";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { Tooltip, Table, Form, Input, Modal, Button, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { OrderedListOutlined } from "@ant-design/icons";

import { GET_ORDERS } from "./graphql/orders_graphql.js";
//import SortableTable from "../settings/SortableTable";
import OrdersSortableTable from "./OrdersSortableTable";

const OrdersReorderBtn = (props) => {
  return (
    <Tooltip placement="bottomLeft" title="Reorder orders">
      <Button key="submit" type="link" icon={<OrderedListOutlined/>} {...props} >Reorder</Button>
    </Tooltip>
  );
}

const columns = [
/*
  {
    title: 'Position',
    width: '3%',
    dataIndex: 'shipment_position',
    className: 'drag-visible',
  },
*/
  {
    title: "State",
    dataIndex: ["shipment"],
    render: r => !r?"-":r.shipment_status.info,
    key: "state",
    width: "3%",
    className: 'drag-visible',
  },
  {
    x_supplier: true,
    title: "Supplier",
    dataIndex: ["supplier", "name"],
    className: 'drag-visible',
  },
  {
    title: "Buyer",
    dataIndex: ["buyer", "name"],
    className: 'drag-visible',
  },
  {
    title: "Label",
    key: "label",
    dataIndex: "label",
    className: 'drag-visible',
  },
  {
    title: "Colli",
    dataIndex: "colli"
  },
  {
    title: "Cargo type",
    dataIndex: "cargo_type"
  },
  {
    title: "Packing type",
    dataIndex: ["packing_type", "type"]
  },
  {
    title: "Brutto",
    dataIndex: "brutto_weight"
  },
  {
    title: "Master AWB",
    dataIndex: "master_awb"
  },
  {
    title: "Origin",
    dataIndex: ["country", "alpha2"]
  },
  {
    title: "Departure date",
    dataIndex: "departure_date",
    render: date => <>{moment(date).format("DD-MM-YYYY")} </>
  },
];

export const OrdersReorderForm = (props) => {
  const [ visible, setVisible ] = useState(false);
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { orderRows, shipmentId } = selection;

  const [form] = Form.useForm();
  const { component } = ui.visibleForm;
  const [modalRef, setModalRef] = useState();
  const [origData, setOrigData] = useState([]);
  const [newData, setNewData] = useState([]);
  
  const [isModified, setIsModified] = useState(false);
  const [isFresh, setIsFresh] = useState(true);

  const measuredRef = useCallback(node => {
    console.log('measuredRef: useCallback: ', node);
    if (node !== null) setModalRef(node);
  }, []);

  useEffect(() => {
    if (data?.orders) {
      form.setFieldsValue(data);
    }
  });

  useEffect(() => {
  }, [modalRef]);

  const { loading, error, data } = useQuery(GET_ORDERS, {
    fetchPolicy: "cache-and-network",
    skip: !visible || !shipmentId,
    variables: { shipmentId: shipmentId },
  });

  useEffect(() => {
    if (isFresh && data) {
      setOrigData(JSON.parse(JSON.stringify(data.orders)).map((e, i) => ({ ...e, index: i, key: i })));
      setIsFresh(false); 
    }
  }, [data]);

  useEffect(() => {
    if (data && origData && newData) setIsModified(JSON.stringify(origData) != JSON.stringify(newData));
  }, [data, newData]);

  const [update] = useMutation(UPDATE_ORDERS_POSITIONS, {
    refetchQueries: ["Orders"],
  });

  ///////////////////////////////////////////////////////////////////////
  const closeForm = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    console.log("handleSubmit: newData=", newData);
    // todo: we can optimize => set and check flag for each modified row
    let diff = data?.orders.filter(x => !newData.some((z) => x.shipment_position == z.shipment_position ));

    newData.forEach((v) => {
      console.log('update: ', v);
      update({ variables: { id: v.id, position: v.position }, });
    });
    setIsFresh(true);
    setIsModified(false);
  };
  ///////////////////////////////////////////////////////////////////////

  // Show button only
  // if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <OrdersReorderBtn onClick={(e) => setVisible(true)} disabled={props.numOrders<2} />
      <Modal
        maskClosable={false}
        width="1300px"
        title="Reorder orders in current shipment"
        visible={visible}
        onOk={handleSubmit}
        okText="Submit"
        okButtonProps={{disabled: !isModified }}
        onCancel={closeForm}
        cancelButtonProps={{disabled: !isModified }}
      >
       <div ref={measuredRef}>
	{ loading? <>Loading...</>  
          :
        <Form layout="vertical" form={form} initialValues={data}>
          <OrdersSortableTable
            columns={columns}
            data={data?.orders}
            helperContainer={modalRef}
            form={form}
            getNewData={setNewData}
          />
        </Form>
        }
       </div>
      </Modal>
    </>
  );
};

const UPDATE_ORDERS_POSITIONS = gql`
  mutation UpdateOrders($id: Int!, $position: Int!) {
    update_orders_by_pk (
       pk_columns: {id: $id}
       _set: { shipment_position: $position }
    ) {
      id
      shipment_position
    }
  }
`;
