import React from "react";
import { Select, Button, Row, Col } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const { Option } = Select;

const TruckSelect = props => {
  const { value } = props;

  const { loading, error, data } = useQuery(GET_TRUCKS, {
    fetchPolicy: "network-only"
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Row>
      <Select
        {...props}
        value={value && data.trucks.filter(i => i.id === value)[0].reg_number}
        allowClear
        showSearch
        placeholder="Select truck"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {data.trucks.map((item, key) => {
          return (
            <Option key={key} value={item.id} style={item.t.aggregate.count>0?{color: "blue"}:{}} data-num-trailers={item.t.aggregate.count}>
              {item.reg_number}
            </Option>
          );
        })}
      </Select>
      {/* <Button title="Add" /> */}
    </Row>
  );
};

const GET_TRUCKS = gql`
  query GetTrucksList {
    trucks(order_by: [{ reg_number: asc }]) {
      id
      reg_number
      t: truck_trailers_aggregate {          
        aggregate { count }
      }
    }
  }
`;

export default TruckSelect;
