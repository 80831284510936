import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Button } from "antd";

export const OrderFileList = props => {
  const [rows, setRows] = useState();


  const parseFile = file => {
    const type = file.type;
    const urlPath = "/xlsx/link-to-json";
    if (
      type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      type === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
      type === "application/vnd.ms-excel"
    ) {
      fetch(process.env.REACT_APP_SERVER_URL + urlPath, {
        headers: {
          "Content-Type": "application/json",
          "cache-control": "no-cache"
        },
        body: JSON.stringify(file),
        method: "POST"
      })
        .then(res => res.json())
        .then(res => {
          setRows(res)
        })
        .catch(err => {
          console.log(err);
        });
      }
    };
    
    const { loading, error, data } = useQuery(GET_FILES, {
      variables: {
        id: props.id
      },
      refetchQueries: ["CountShipmentFiles", "CountOrderFiles"]
    });
    
    if (loading) return "Loading...";
    if (error) return `Error! ${error.message}`;
    
    console.log(rows?.data);

  return (
    <div>
      {data.files.map((file, key) => (
        <div key={key}>
          <Button type="link" onClick={() => parseFile(file)}>
            {file.name}
          </Button>
        </div>
      ))}
      <div>Table</div>
    </div>
  );
};

const GET_FILES = gql`
  query OrderFiles($id: Int!) {
    files(where: { _and: { order_id: { _eq: $id }, hidden: { _eq: false } } }) {
      id
      order_id
      shipment_id
      name
      size
      type
      key
      bucket
      uid
      created_at
      last_modified
    }
  }
`;
