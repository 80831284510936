import React, { useEffect, useState }  from "react";
import { Select } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const { Option } = Select;

const WarehouseSelect = props => {
  const { action, role, value = null, company_id, onChange, disabled, ...otherProps } = props;
  const [prevCompanyId, setPrevCompanyId] = useState();

  const { loading, error, data } = useQuery(GET_WAREHOUSES, {
    skip: !company_id,
// why?
//    fetchPolicy: "network-only",
    variables: {
      id: company_id
    }
  });

/*
  useEffect(() => {
    onChange(null);
  }, []);
*/

  useEffect(() => {
debugger;
    if (data) {
      if(prevCompanyId && prevCompanyId != company_id) {
         onChange(null);
      }
      setPrevCompanyId(company_id);

      if (data.warehouses?.length === 1) {
        onChange(data.warehouses[0].id);
      }
      else if (data.warehouses?.length === 0 || action === 'add')
         onChange(null);

    } else
       onChange(null);
  }, [data]);

  const handleChange = (value) => {
   console.log(`handleChange: selected ${value}`);
   if (onChange) {
      console.log(`call super.onChange ${value}`);
      onChange(value);
    }
  }

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  // due to missing of readonly 
/*
 * and more: 
className={props.readOnly ? 'readOnly' : undefined}
.ant-select.readOnly
{
	pointer-events: none;
}
*/

//  if (data?.warehouses?.length === 1 && onChange) onChange(data.warehouses[0].id);
//  else onChange('');

  const opts = data?.warehouses?.length === 1? {
//    value: data.warehouses[0].id,
    // open: false,
    allowClear: false,
    showSearch: false,
   } : {
    // open: true,
    allowClear: true,
    showSearch: true,
   };

  return (
    <Select
      disabled={disabled || !data?.warehouses?.length}
      {...otherProps}
      {...opts}
      _value={value && data && data.warehouses?.filter(i => i.id === value)[0]?.name}
      _onChange={onChange}
      value={value}
      onChange={handleChange}
      placeholder={"Select "+(role?role:"")+" warehouse"}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data && data.warehouses?.map((item, key) => {
        return (
          <Option key={key} value={item.id}>
            {item.name}
          </Option>
        );
      })}
    </Select>
  );
};

const GET_WAREHOUSES = gql`
  query Warehouses($id: Int) {
    warehouses(
     where: { company_id: { _eq: $id } },
     order_by: [
        { default_warehouse: desc },
        { name: asc }
     ]
    )
    {
      id
      name
    }
  }
`;

export default WarehouseSelect;
