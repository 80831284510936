import React from "react";
import { Select } from "antd";
import * as CONST from '../shipments/Constants';

const Option = Select.Option;

const ShipmentType = props => {
  return (
    <Select {...props}>
      <Option value={CONST.SHIPMENT_TYPE_AVIA_KEY}>Avia</Option>
      <Option value={CONST.SHIPMENT_TYPE_TRUCK_KEY}>Truck</Option>
      <Option value={CONST.SHIPMENT_TYPE_WAREHOUSE_KEY}>Warehouse</Option>
    </Select>
  );
};

export const ShipmentTypeDecode = (value) => {
  switch (value) {
  case CONST.SHIPMENT_TYPE_AVIA_KEY:
    return 'Avia';
  case CONST.SHIPMENT_TYPE_TRUCK_KEY:
    return 'Truck';
  case CONST.SHIPMENT_TYPE_WAREHOUSE_KEY:
    return 'Warehouse';
  default:
    return 'Unknown: '+value;
  }
};

export default ShipmentType;
