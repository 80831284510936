import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Space, Popconfirm, Input, InputNumber, Form, Button, Table } from 'antd';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  //const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  //<td {...restProps}>
  //</td>
  return (
    <>
      {editing ? (
        <Form.Item
          normalize={ (value) => dataIndex === "door_code"? (value || '').toUpperCase() : value }
          name={dataIndex}
          style={{ margin: 0, }}
          rules={[
            { required: false, message: `Please Input ${title}!`, },
            { pattern: dataIndex === "door_code"? new RegExp("^[0-9A-Z#]*$"): '', message: "Should be a valid code letter" },
          ]}
        >
          <Input style={{ width: 100 }} maxLength={8}/>
        </Form.Item>
      ) : (
        children
      )}
    </>
  );
};

export default EditableCell;
