import gql from "graphql-tag";
//import { FRAGMENT_TRANSACTIONS } from "./transactions_fragment_graphql";

export const INSERT_TRANSACTIONS = gql`
  mutation CreateTransaction($objects: [p_transactions_insert_input!]! $bot_queue: [bot_queue_insert_input!]!) {
    insert_p_transactions(objects: $objects) {
      affected_rows
    }
    insert_bot_queue (objects: $bot_queue) {
      affected_rows
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation UpdateTransaction($id: uuid! $objects: p_transactions_set_input! $bot_queue: [bot_queue_insert_input!]!) {
    update_p_transactions(_set: $objects, where: { id: { _eq: $id } }) {
      affected_rows
    }
    insert_bot_queue(objects: $bot_queue) {
      affected_rows
    }
  }
`;

export const UPDATE_TRANSACTIONS_AND_INSERTS = gql`
  mutation UpdateInsertTransaction($id: uuid! $obj_update: p_transactions_set_input! $objs_insert: [p_transactions_insert_input!]! $bot_queue: [bot_queue_insert_input!]!) {
    update_p_transactions(_set: $obj_update, where: { id: { _eq: $id } }) {
      affected_rows
    }
    insert_p_transactions(objects: $objs_insert) {
      affected_rows
    }
    insert_bot_queue(objects: $bot_queue) {
      affected_rows
    }
  }
`;

