import React from "react";
import { message, Popconfirm, Button } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";


const TruckDeleteBtn = (props) => {
  const [delete_truck] = useMutation(DELETE_TRUCK, {
    refetchQueries: ["Trucks"],
    onError: (e) => processError(e)
  });
  const is_disabled = props.trailers > 0;

console.log("TruckDeleteBtn ");
console.dir(props);
console.log("is_disabled="+is_disabled );

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
	// foreign key constraint "truck_trailer_truck_id_fkey" on table "truck_trailer
         if (error.extensions.code === "constraint-violation") {
                message.error('Can`t delete this truck due trailers connections');
         } else
                message.error('Server error, contact your administrator');
        });
  }

  return (
    <Popconfirm
      title="Are you sure delete this truck?"
      onConfirm={e => {
        e.preventDefault();
        delete_truck({ variables: { id: props.id } });
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button type="danger" icon={<DeleteOutlined />} disabled={is_disabled} />
    </Popconfirm>
  );
};

const DELETE_TRUCK = gql`
  mutation DeleteTruck($id: Int!) {
    delete_trucks(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export default TruckDeleteBtn;
