import gql from "graphql-tag";
//import { gql } from '@apollo/client';

export const FRAGMENT_ORDERS = gql`
fragment fr_company on companies {
      id
      name
      short_name
}
fragment orders_fragment on orders {
      id
      type
      creation_date
      departure_date
      shipment_id
      shipment_position
      shipment {
        status
        state
        shipment_status {
         info
        }
        shipper {
          name
        }
        truck {
          id
          reg_number
        }
      }
      supplier_id
      supplier { ...fr_company }
      buyer_id
      buyer { ...fr_company }
      loading_order
      label
      colli
      colli_controle
      colli_transit
      brutto_weight
      netto_weight
      chargeable_weight
      master_awb
      house_awb
      flight
      temperature_range
      transit_id
      transit_consignee
      transit_client
      transit_eta
      country {
        alpha2
        name
      }
      cargo_type
      packing_type {
        type
        code
      }
      order_units {
       id
       colli
       packing_type_id
       packing_type {
        type
        code
       }
       cargo_type
       order_id
      }

      files: files_aggregate(where: { hidden: { _eq: false } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
`;

