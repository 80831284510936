import React, { useState } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, NetworkStatus } from "@apollo/client";
import { Space, Tag, Input, Col, Row } from "antd";
import { Table, Tabs } from "antd";
import FormBtn from "../shared/FormBtn";

import TruckDeleteBtn from "./TruckDeleteBtn";
import TrailersTable from "../trailers/TrailersTable";
import DriversTable from "../users/DriversTable";
import { FilesIcon } from "../shared/FilesIcon";

const TabPane = Tabs.TabPane;

const columns = [
  {
    title: "Number",
    //dataIndex: "reg_number"
    //width: 5,
    render: (rec) =>
<>
<Space>
{rec.reg_number}
{/*
rec.company?.drivers_aggregate?.aggregate?.count > 0 &&
 <Tag color="#2db7f5">drivers {rec.company?.drivers_aggregate?.aggregate?.count}</Tag>
*/

 !!rec.user_id_1+!!rec.user_id_2 > 0 &&
 <Tag color="#2db7f5">drivers {!!rec.user_id_1+!!rec.user_id_2}</Tag>
}
{rec.truck_trailers_aggregate?.aggregate?.count > 0 &&
<Tag color="#87d068">trailers {rec.truck_trailers_aggregate?.aggregate?.count}</Tag>
}
</Space>
</>
  },
  {
    title: "Files",
    width: "3%",
    render: record => <FilesIcon id={record.id} dataModel="truck" count={record.files.aggregate.count}/>
  },
  {
    title: "Type",
    dataIndex: "type"
  },
  {
    title: "Info",
    dataIndex: "info"
  },
  {
    title: "Company",
    dataIndex: ["company", "name"]
  }
];

const TrucksTable = () => {
  const { dispatch } = useStoreon("selection");
  const [filter, setFilter] = useState('');

  //const { loading, error, data, refetch, networkStatus } =
  const { loading, error, data } =
	useQuery(GET_TRUCKS);
//	useQuery(GET_TRUCKS_BY_NUM, {
//	variables: {reg_number: "%"+filter+"%"},
//	variables: {reg_number: filter},
//	notifyOnNetworkStatusChange: true
///	});

//  if (networkStatus === NetworkStatus.refetch) return 'Refetching!';
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const filteredData = filter
     ? data.trucks.filter((truck) =>
	truck
	? truck.reg_number.toLowerCase().includes(filter.toLowerCase())
	: false,
     )
     :data.trucks;

  const doSearch = (value) => {
	setFilter(value);
	// refetch();
  };

  // <Input.Search
  //    _enterButton
  //    _onSearch={doSearch}
  return (
    <>
      <Row type="flex" justify="start" gutter={8}>
        <Col span={2}>
          <FormBtn component="truck" action="add"/>
        </Col>
        <Col span={3}>
          <Input
            placeholder="search by number ..."
            value={filter}
            onChange={(event) => doSearch(event.target.value)} 
          />
        </Col>
        <Col span={19} />
      </Row>
      <Row>
        <Col span={24}>
          <Table
            rowKey="id"
            columns={columns}
 //           dataSource={data && data.trucks}
            dataSource={filteredData}
            size="small"
            onExpand={(_, record) => {
              dispatch("select", { model: "truck", id: record.id });
            }}
            expandedRowRender={record => (
              <Tabs defaultActiveKey="1" tabPosition="left">
                <TabPane tab="Truck" key="1">
                  <h2>Truck info</h2>
                  <p>Reg. number: {record.reg_number} </p>
                  <p>Company: {record.company && record.company.name} </p>
                  <FormBtn component="truck" action="edit" />
                  <TruckDeleteBtn id={record.id} trailers={record.truck_trailers_aggregate?.aggregate?.count} />
                </TabPane>
                <TabPane tab="Trailers" key="2">
                  <TrailersTable truckId={record.id} />
                </TabPane>
                <TabPane tab="Drivers" key="3">
                  <DriversTable truckId={record.id} companyId={record.company_id}/>
                </TabPane>
              </Tabs>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

// _ilike -> _like & filter.toUpperCase() if DB store data in upper case only
//const GET_TRUCKS_BY_NUM = gql`
//query Trucks ($reg_number: String!) {
//        where: {  reg_number: {_ilike: $reg_number} },
const GET_TRUCKS = gql`
query Trucks {
    trucks(
        order_by: [{ reg_number: asc }] 
    ) {
      id
      reg_number
      type
      info
      user_id_1
      user_id_2
      company_id
      company {
        name
        drivers_aggregate: users_aggregate (where: { default_role: { _eq: "driver" } }) {  aggregate { count } }
      }
      truck_trailers_aggregate {
        aggregate { count }
      }
      files: files_aggregate(where: { hidden: { _eq: false } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  }
`;

export default TrucksTable;
