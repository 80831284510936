import React from "react";
import { useStoreon, StoreContext } from "storeon/react";

import FormBtn from "../shared/FormBtn";
import ShipmentFilterBtn from "../shipments/ShipmentFilterBtn";
import ShipmentDeleteBtn from "../shipments/ShipmentDeleteBtn";
import ShipmentExportBtn from "../shipments/ShipmentExportBtn";

import { Button } from "antd";
import {
  EditOutlined,
  UploadOutlined,
  DeleteOutlined,
  InfoOutlined,
} from "@ant-design/icons";
import { store } from "../../state/index";

export const ShipmentToolbar = (props) => {
  const { dispatch } = useStoreon();
  const { selection } = useStoreon("selection");
  const shipmentId = selection.shipmentId;

  const getStyle = () => {
   return {
    color: shipmentId ? 'grey' : 'red'
   }
  };

  //  switch to origin store
  return (
    <>
      <StoreContext.Provider value={store}>
       <FormBtn component="shipment" action="add" />
      </StoreContext.Provider>
      <ShipmentFilterBtn />
      {props.mode !== "select" &&
        // mode select - used for selection of second shipment for move to
        <>
          {props.single ?
            // list or table => id or rows[]
            <>
             <FormBtn component="shipment" action="edit" disabled={!shipmentId} />
             <Button type="link" disabled={!shipmentId || !props.single} >
               <ShipmentDeleteBtn type="menuItem" style={shipmentId? {color: 'red'} : {}} />
             </Button>
            </>
          :
            <ShipmentExportBtn />
          }
        </>
      }
    </>
  );
};

/*
        <FormBtn component="user" action="edit" id={record.id} company_id={record.company_id}/>
        <ChangePasswordBtn id={record.id} />
        <UserDeleteBtn id={record.id} type="danger" icon="delete" />
        <DeleteOutlined style={shipmentId? {color: 'red'} : {}} />
*/
