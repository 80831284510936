import React from "react";
import { message, Popconfirm, Button, Tooltip } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";

const UserDeleteBtn = props => {
  const [delete_user] = useMutation(DELETE_USER, {
    refetchQueries: ["Companies", "Users"], // Companies - for tags
    onError: (e) => processError(e) 
  });

  // Foreign key violation. update or delete on table "users"
  // violates foreign key constraint "trucks_user_id_2_fkey" on table "trucks"
  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
	  if (error.message.includes("trucks"))
            message.error('Can`t delete this user because he is assigned as the driver of one of the trucks');
          else
            message.error('Can`t delete this user due constraint violation, contact your administrator');
         } else
          message.error('Server error, contact your administrator');
        });
  }

  return (
    <Popconfirm
      title="Are you sure delete this user?"
      onConfirm={e => {
        e.preventDefault();
        delete_user({ variables: { id: props.id } });
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip placement="topLeft" title="Delete user">
        <Button type="link" danger icon={<DeleteOutlined />} {...props} />
      </Tooltip>
    </Popconfirm>
  );
};

const DELETE_USER = gql`
  mutation DeleteUser($id: Int!) {
    delete_users(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export default UserDeleteBtn;
