import gql from "graphql-tag";
import { FRAGMENT_ORDERS } from "./orders_fragment_graphql";

// order_by: { creation_date: desc, supplier: { name: asc } }
export const GET_ORDERS = gql`
  ${FRAGMENT_ORDERS}
  query Orders($shipmentId: Int) {
    orders(
      order_by: { shipment_position: asc, creation_date: desc }
      where: { shipment_id: { _eq: $shipmentId } }
    ) {
       ...orders_fragment
    }
  }
`;

// same as GET_ORDERS except WHERE
// due new HASURA behavior
export const GET_ALL_ORDERS = gql`
  ${FRAGMENT_ORDERS}
  query Orders($shipmentId: Int) {
    orders(
      order_by: { creation_date: desc }
    ) {
       ...orders_fragment
    }
  }
`;

export const GET_ORDERS_BY_SELECTION = gql`
  ${FRAGMENT_ORDERS}
  query Orders($ordersId: [Int!]) {
    orders(
      order_by: { shipment_position: asc, creation_date: desc }
      where: { id: { _in: $ordersId } }
    ) {
       ...orders_fragment
    }
  }
`;
