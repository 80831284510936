export default (store) => {

  store.on("@init", () => ({
    selection: {
      orderId: undefined,
      orderRows: [],
      shipmentId: undefined,
      shipmentRows: [],
      companyId: undefined,
      companyRows: [],
      trailerId: undefined,
      trailerRows: [],
      new_trailerId: undefined,
      transactionId: undefined,
      transactionRows: [],
    },
  }));

  store.on("select", ({ selection }, args) => {
    return {
      selection: { ...selection, [args.model + "Id"]: args.id },
    };
  });

  store.on("selectRows", ({ selection }, args) => {
    return {
      selection: { ...selection, [args.model + "Rows"]: args.rowKeys },
    };
  });

  store.on("deselect", ({ selection }, args) => {
    return {
      selection: { ...selection, [args.model + "Id"]: undefined },
    };
  });

  store.on("deselectRows", ({ selection }, args) => {
    return {
      selection: { ...selection, [args.model + "Rows"]: [] },
    };
  });

};
