import React, { useState, useEffect } from "react";
import { Input, Select, version } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useStoreon } from "storeon/react";

const Option = Select.Option;

const TrailerSelect = ({ value , onChange }) => {
  const [state, setState] = useState(false);
  const { selection, dispatch } = useStoreon("selection");
  const { new_trailerId } = selection;

  useEffect(() => {
    if (new_trailerId) {
      const tId = new_trailerId;
      dispatch("deselect", { model: "new_trailer" });
      handleChange(tId);
    }
  });

  const { added_id, setAddedId } = useState();
  const { loading, error, data } = useQuery(GET_TRAILERS, {
    fetchPolicy: "network-only"
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange( changedValue,);
    }
  };
  const handleChange = (value) => {
    setState({ selected: value });
    triggerChange( value );
  }

  return (
    <Select
      /*{...props}*/
      onChange={handleChange}
      allowClear
      showSearch
      placeholder="Select a trailer"
      optionFilterProp="children"
      value={state.selected}
      filterOption={(input, option) => { 
        console.dir(option);
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
       }
      }
    >
      {data.trailers.map((item, key) => {
        return (
          <Option key={key} value={""+item.id}>{item.reg_number}</Option>
        );
      })}

    </Select>
  );
};

const GET_TRAILERS = gql`
  query TrailerSelect {
    trailers(order_by: { reg_number: asc }) {
      id
      reg_number
    }
  }
`;

export default TrailerSelect;
