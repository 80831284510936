import React, { useState } from "react";
import { message, Popconfirm, Button, Tooltip } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";
import { AUTH_TOKEN } from "../../../constant";

const ERROR_MESSAGE_DURATION = 5;

const LinkDeleteBtn = props => {
  const { id, stored, removeHandler, ...otherProps } = props;
  const [state, setState] = useState({disabled: false, hide: null});

  // console.log("LinkDeleteBtn: props:", props);

  const handleDeleteLink = (d) => {
    const hide = message.loading('Action in progress ...', 0, () => {
       // message.success('Done');
    });

// debug mode
/*
hide();
if (removeHandler) removeHandler(id);
return;
*/
    setState({disabled: true, hide: hide});

    const url = process.env.REACT_APP_SERVER_URL + "/auth/gocardless/remove_link?id="+id;
    fetch (url,
        {
          headers: {
           "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN),
            Accept: "application/json",
           "cache-control": "no-cache"
        },
        method: 'GET', 
    })
     .then(data => data.json())
     .then(data => {

        if (data.error) throw new Error(data.error);
        //
        if (stored) {
          console.log("LinkDeleteBtn: try to delete form db ...");
          delete_link({ variables: { id: id } });
        }

        hide();
        if (removeHandler) { 
          // wtf? after removing at removeHandler() next link will be disabled
          // try to reset state before delete row
          setState({disabled: false, hide: null});

          // may be trigger refresh table with links instead deleting?
          removeHandler(id);
        }
     })
     .catch(err => {
        setState({disabled: false});
        hide();
        message.error('Can`t remove link: '+err, ERROR_MESSAGE_DURATION);
     });
  }

  // delete from db
  // may be move this part to server side into remove_links() too 
  const [delete_link] = useMutation(DELETE_LINK, {
    skip: !id,
// we must refresh whole page (https api data and db data )
//    refetchQueries: ["Gocardless_parameters"],
    onCompleted: (d) => handleCompleted(d),
    onError: (e) => processError(e) 
  });

  const handleCompleted = (d) => {
    // debugger;
    if (state?.hide) state.hide();

    console.log("LinkDeleteBtn: delete completed ...");
    if (d.delete_bank_parameters.affected_rows !== 1)
      message.warning('Gocardless link deleted, but in db no one link with specified id deleted.');
    else
      message.success('Link with specified id deleted successfully.');
  }

  // Foreign key violation. update or delete on table "users"
  // violates foreign key constraint "trucks_user_id_2_fkey" on table "trucks"
  const processError = (e) => {
        if (state?.hide) state.hide();

        console.log("LinkDeleteBtn: Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
            message.error('Can`t delete this link due constraint violation, contact your administrator', ERROR_MESSAGE_DURATION);
         } else
          message.error('Server error, contact your administrator', ERROR_MESSAGE_DURATION);
        });
  }

  if (!id) return (<></>);

  return (
    <Popconfirm
      title="Are you sure delete requisition?"
      onConfirm={e => {
        e.preventDefault();
        console.log("delete: "+JSON.stringify(props));
        handleDeleteLink (id);
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip placement="left" mouseLeaveDelay="0" title="Delete requisition" >
        <Button
           {...otherProps}
           type="link"
           danger
           icon={<DeleteOutlined />}
           disabled={state.disabled}
        />
      </Tooltip>
    </Popconfirm>
  );
};

const DELETE_LINK = gql`
mutation DeleteTransactions(
  $id: String!
 ) {
 delete_bank_parameters(
      where: { _and: [
       {par_name: {_eq: "req_id"}}
       {par_value: {_eq: $id}},
      ]}
   ) {
   affected_rows
 }  
}
`;

export default LinkDeleteBtn;
