import React, { Component } from "react";
import OrdersTable from "./OrdersTable";

class Orders extends Component {
  render() {
    return <OrdersTable mode="all" />;
  }
}

export default Orders;
