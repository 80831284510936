import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { message, Form, Input, Modal } from "antd";
import TruckType from "../shared/TruckType";
import RegNumInput from "../shared/RegNumInput";
import CompanySelect from "../shared/CompanySelect";

const RegNumItem = RegNumInput;

const TruckForm = (props) => {
  const [ form ] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { action, component } = ui.visibleForm;
  const { truckId } = selection;
  const [ companyId, setCompanyId ] = useState(null);

  const closeForm = () => {
    dispatch("hideForm");
    form.resetFields();
    setCompanyId(null);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        delete values.__typename;
        mutate({
          variables: { id: truckId && action !== "add" ? truckId : undefined, objects: values }
        });
        form.resetFields();
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  const { loading, error, data } = useQuery(GET_TRUCKS, {
    skip: !(action === "edit" && component === "truck"),
    variables: { id: truckId }
  });

  useEffect(() => {
    if (action === "edit" && data?.truck) {
      form.setFieldsValue(data.truck);
    }
    else if (action == "add" && component === "truck") {
      form.resetFields();
      setCompanyId(null);
    }
  }, [data]);

  const [mutate] = useMutation(
    action === "edit" ? UPDATE_TRUCKS : INSERT_TRUCKS,
    {
      variables: { id: action === "edit" ? truckId : undefined },
      refetchQueries: ["Trucks"],
      onCompleted: () => dispatch("hideForm"),
      onError: (e) => processError(e) 
  });

  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
          message.error('This truck already exists, try Edit');
         } else
          message.error('Server error, contact your administrator');
        });
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  // async
  const handleOnChange = async (v) => {
     setCompanyId(v);
     console.log("handleOnChange:", v);
     //form.validateFields(['reg_number']);
//debugger;
/*
     var v = form.getFieldValue('reg_number');
     if (false && v) {
       v = '.'+v;
       form.setFieldsValue({reg_number: v});
       v = v.substr(1);
       form.setFieldsValue({reg_number: v});
     }
*/
  };

  return (
    <Modal
      title="Truck form"
      visible={component === "truck"}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={closeForm}
    >
      <Form
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Company"
          name="company_id"
          rules={[{ required: false, message: "Please select company" }]}
        >
          <CompanySelect role="carrier" onChange={(v) => { handleOnChange(v); /*setCompanyId(v);*/ }} />
        </Form.Item>

        <RegNumItem
          form={form}
          label="Reg number"
          name="reg_number"
          dependencies={["company_id"]}
          _rules={[{ required: true, message: "Please select truck number" }]}
          company_id={companyId}
          _shouldUpdate={(prevValues, currentValues) => prevValues.company_id !== currentValues.company_id }
        />
{/*
          <Input />
        </RegNumItem>
*/}
        <Form.Item
          label="Truck type"
          name="type"
          rules={[{ required: true, message: "Please select truck type" }]}
        >
          <TruckType />
        </Form.Item>

        <Form.Item
          label="Info"
          name="info"
          rules={[{ required: false, message: "Please enter additional information about truck" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TruckForm;

const INSERT_TRUCKS = gql`
  mutation CreateTruck($objects: [trucks_insert_input!]!) {
    insert_trucks(objects: $objects) {
      affected_rows
    }
  }
`;

const UPDATE_TRUCKS = gql`
  mutation UpdateTruckById($id: Int!, $objects: trucks_set_input!) {
    update_trucks(_set: $objects, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const GET_TRUCKS = gql`
  query TruckById($id: Int!) {
    truck: trucks_by_pk(id: $id) {
      id
      reg_number
      type
      info
      company_id
    }
  }
`;
