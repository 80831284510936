export default (store) => {
  store.on("@init", () => ({
    auth: {
      authenticated: false,
      user_id: "",
      user_role: "",
      company_id: "",
      company_role: "",
      login: "",
    },
  }));

  store.on("setAuthData", ({ auth }, args) => {
    return {
      auth: {
        ...auth,
        authenticated: args.authenticated,
        user_id: args.user_id,
        user_role: args.user_role,
        company_id: args.company_id,
        company_role: args.company_role,
        login: args.login,
      },
    };
  });
};
