import React, { Component } from "react";

import TransactionsTable from "./TransactionsTable";

class TransactionsOut extends Component {
  render() {
    return (
      <TransactionsTable type="OUT" />
    );
  }
}

export default TransactionsOut;

