import React, { Component } from "react";
import { Select } from "antd";
import gql from "graphql-tag";
//import { Query } from "@apollo/react-components";
//import { useQuery } from "@apollo/react-hooks";
import { useQuery } from "@apollo/client";


const Option = Select.Option;

const UserRoles = (props) =>  {

 console.log("UserRoles: ");
 console.log("props: ");
 console.dir(props);

  const { loading, error, data } = useQuery(GET_ROLES);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  console.log("return ...");

  return (
            <Select {...props}>
              {data.user_roles.map((item, key) => {
                return (
                  <Option key={key} value={item.role}>
                    {item.role}
                  </Option>
                );
              })}
            </Select>
  );
}

const GET_ROLES = gql`
  query UserRoles {
    user_roles {
      role
    }
  }
`;

export default UserRoles;
