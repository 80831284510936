import decode from "jwt-decode";
import { AUTH_TOKEN, REFRESH_TOKEN } from "../constant";
import { useApolloClient } from "@apollo/client";
import { useStoreon } from "storeon/react";

/*
export const isAuth = () => {
 return (localStorage.getItem(AUTH_TOKEN));
}
*/

export const setUserRole = (role) => { 
 return (localStorage.setItem('user_role', role));
};
export const getUserRole = () => { 
// const { auth } = useStoreon("auth");
// return auth.user_role;
 return (localStorage.getItem('user_role'));
};

export const isAdmin    = () => { return (getUserRole() === 'admin') };
export const isSupplier = () => { return (getUserRole() === 'supplier') };
export const isShipper  = () => { return (getUserRole() === 'shipper') };

/*
// use only storeon tab mock
export const setUserTenant = (tenant) => { 
 return (tenant?sessionStorage.setItem('user_tenant', tenant):sessionStorage.removeItem('user_tenant'));
};
*/
export const getUserTenant = () => { 
 // use key form storeon tab mock
 return (sessionStorage.getItem('tenant'));
};

export const register = values => {
  fetch(process.env.REACT_APP_SERVER_URL + "/register", {
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache"
    },
    body: JSON.stringify(values),
    method: "POST"
  })
    .then(res => console.log(res))
    .catch(err => {
      console.log(err);
    });
};

export const logout = (client, mode) => {

  const suffix = mode? "-"+mode : '';

  const refresh_token = localStorage.getItem(REFRESH_TOKEN);
  fetch(process.env.REACT_APP_SERVER_URL + "/auth/logout"+suffix, {
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache"
    },
    body: JSON.stringify({ refresh_token: refresh_token }),
    method: "POST"
  })
    .then(res => {
      if (res.error) {
        console.log("logout"+suffix+": fetch ok, but with error", res.error);
      }
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);

debugger;
/*
      client.clearStore().then(() => {
              console.log("logout: clear cache");
              //
              // client.resetStore().then(() => console.log("logout: restore cache"));
      });
*/
/*
      client.cache.reset().then(() => {
            console.log("logout: cache reset");
            // history.push('/login')
      });
*/
    })
    .catch(err => {
      console.log("logout"+suffix+": fetch error", err);
    });
};

export const logout_all = (client) => logout(client, "all");

export function getTokenExpirationDate(token) {
  console.log("getTokenExpirationDat: fired");
  if (!token) return null;

  const decoded = decode(token);
  if (!decoded.exp) {
    return null;
  }
  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp);
  return date;
}

export function isTokenExpired(token) {
  console.log("isTokenExpired: token=", pretty_print_token(token));
  if (token === null) {
    console.log("isTokenExpired: token is null");
    return true;
    // origin
    // return false;
  } else {
    const date = getTokenExpirationDate(token);
    const offsetSeconds = 0;
    if (date === null) {
      console.log("isTokenExpired: token exp date is null");
      return true;
      // origin
      // return false;
    }
    console.log("isTokenExpired: token.exp_date:", date);
    console.log("isTokenExpired: tok.date:", date.valueOf(), " > curr.date:", (new Date().valueOf() + offsetSeconds * 1000));
    const ret = !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
    console.log("isTokenExpired: ret=", ret);
    return ret;
  }
}

export function getUserInfo(token) {
  console.log("getUserRoles: fired");
  if (!token) return null;

  const decoded = decode(token);
  if (decoded?.hasura?.claims) return decoded.hasura.claims;

  return null;
}

export const pretty_print_token = (token)  => token ?  token.substr(0,20)+'...' : 'none' ;

