import React, { Component, useState, useEffect } from "react";
import { Select } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const Option = Select.Option;

const Countries = ({ value, onChange, alpha, region, ...otherProps }) =>  {

/*
 * TODO: add query conditions ... 
 * for example: Gocardless: for banking select only europe and United Arab Emirates (ARE)
 * 
const cond = {
      condition: {
        _or: [
          { region: { _in: [] }},
          { sub_region: { _in: [] }},
          { alpha3: { _in: [] }}
        ]
      }
};
*/
  const cond = (!region || !Array.isArray(region)) ? {} : { region: { _in: region }};

  const { loading, error, data } = useQuery(GET_COUNTRIES, {variables: { condition: cond }});

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const handleChange = (value) => {
   if (onChange) {
      onChange(value);
    }
  }

  // option.props.children => option.children (due to v3.x => v4.x)
  return (
           <Select
              {...otherProps}
              value={value}
              onChange={handleChange}
              allowClear
              showSearch
              placeholder="Select a country"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {data.countries.map((item, key) => {
                return (
                  <Option key={key} value={alpha? item[alpha] : item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
  );
}

const GET_COUNTRIES = gql`
  query Countries ($condition: countries_bool_exp!) {
    countries(
      order_by: { name: asc }
      where: $condition
    ) {
      id
      name
      alpha2
      alpha3      
    }
  }
`;

export default Countries;
