import React from "react";
import { useStoreon } from "storeon/react";
import { Badge } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { UploadOutlined, FileOutlined, PaperClipOutlined } from "@ant-design/icons";

const Attachments = (props) => {
 const { count, onClick, ...otherProps} = props;
 return (
  <a href="#" onClick={onClick} >
  <Badge size="small" count={count} style={{ float: "right" }}>
   <PaperClipOutlined {...otherProps}/>
  </Badge>
  </a>
 );
}

export const FilesIcon = props => {
  const { dispatch } = useStoreon("selection");

  const { id, dataModel } = props; // aggregate amount of files per id

  const handleClick = () => {
    dispatch("showUploadForm", { dataModel: dataModel, id: id });
  };
/*
 * 
 * speed up: use counts at parent level
 *   if need => rewrite to use `where: $condition`
 *
  const { loading, error, data } = useQuery(
    COUNT_FILES,
    {
      skip: props.count,
      variables: {
        orderId: dataModel === "order"?id:null,
        shipmentId: dataModel === "shipment"?id:null,
        userId: dataModel === "user"?id:null,
        companyId: dataModel === "company"?id:null,
        truckId: dataModel === "truck"?id:null,
        trailerId: dataModel === "trailer"?id:null,
      },
    }
  );

  if (loading) return "Loading...";
  if (error) return `Error!`;
*/
  const count = typeof props.count === "number"? props.count : -1;

  //return <Attachments count={count || data?.files.aggregate.count} onClick={handleClick} />;
  return <Attachments count={count} onClick={handleClick} />;

};

const COUNT_FILES = gql`
  query CountFiles(
    $orderId: Int,
    $shipmentId: Int,
    $userId: Int,
    $companyId: Int,
    $truckId: Int,
    $trailerId: Int,
  ) {
    files: files_aggregate(
      where: {
        _and: [
          { hidden: { _eq: false }}
          { order_id: { _eq: $orderId }}
          { shipment_id: { _eq: $shipmentId}}
          { user_id: { _eq: $userId}}
          { company_id: { _eq: $companyId}}
          { truck_id: { _eq: $truckId}}
          { trailer_id: { _eq: $trailerId}}
        ]
      })
    {
      aggregate {
        count
      }
    }
  }
`;

