import React, { Component } from "react";
import { Select } from "antd";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";


const Option = Select.Option;

const TruckType = (props) =>  {

 console.log("TruckType: ");
 console.log("props: ");
 console.dir(props);

  const { loading, error, data } = useQuery(GET_TRUCK_TYPES);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  console.log("return ...");

  return (
            <Select {...props}>
              {data.truck_types.map((item, key) => {
                return (
                  <Option key={key} value={item.type}>
                    {item.type}
                  </Option>
                );
              })}
            </Select>
  );
}

const GET_TRUCK_TYPES = gql`
  query TruckTypes {
    truck_types  {
      type
    }
  }
`;

export default TruckType;
