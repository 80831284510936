import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { message, Timeline, Form, Input, Modal } from "antd";
import moment from "moment";
import ShipmentStatuses from "../shared/ShipmentStatuses";

const ShipmentStatusForm = (props) => {
  const [form] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { component } = ui.visibleForm;

  const { shipmentId } = selection;

  //const date = moment(departure_date).format("DD-MM-YYYY");

  useEffect(() => {
// debugger;
/*
    if (action === "edit" && data?.trailer) {
      form.setFieldsValue(data.trailer);
    } else {
      form.resetFields();
    }
*/
  });

  const handleCompleted = (r) => {
    console.log("handleCompleted: ");
    dispatch("hideForm");
  };

  const handleClose = (e) => {
    console.log("handleClose: ");
    dispatch("hideForm");
  };

  const handleSubmit = () => {
    console.log("handleSubmit: ");
    form
      .validateFields()
      .then(values => {
        delete values.__typename;
        console.log("handleSubmit: mutate: values=", values);
        mutate({
          variables: {
            id: shipmentId,
            objects: values
          }
        });
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  const { loading, error, data } = useQuery(GET_SHIPMENT_HISTORY, {
    skip: !shipmentId,
    variables: {
      id: shipmentId
    }
  });

  useEffect(() => {
    if (data) console.log("useEffect: shipments.history: ", data.shipments?.history);
    //data?.shipments.history.map((v, k) => ({ console.log(v); return 0; }) ); 
  }, [data]);


  const [mutate] = useMutation(UPDATE_SHIPMENT_STATUS,
    {
      refetchQueries: ["ShipmentHistory", "Shipments"],
      onCompleted: (r) => handleCompleted(r),
      onError: (e) => processError(e)
  });

  const processError = (e) => {
	if (!e) { alert("WTF?!"); debugger; return; }
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
          message.error('Trailer with same registration number already exists');
         } else
          message.error('Server error, contact your administrator');
        });
  }

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const spr = data?.shipment_statuses;
  const spr_decode = data?.shipment_statuses.reduce((r, s, i) => {r[s.status] = {info: s.info, desc: s.description, pos: s.position }; return (r);}, []);

  if (data) {
    console.log("spr: ", spr);
    console.log("spr_decode: ", spr_decode);
  }
/*
 <Tooltip title={props.spr[props.spr_decode[item?.state]?.pos-1]?.info}>
           <Button size="small" onClick={(e) => changeState(e, item, -1)}><CaretLeftOutlined/></Button>
          </Tooltip>
          <Tooltip title={props.spr_decode[item?.state]?.info}>
*/

  return (
    <Modal
      title="Shipment status"
      visible={component === "shipment_status"}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={handleClose}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Status"
          name="state"
          rules={[{ required: true, message: "Please enter status" }]}
        >
          <ShipmentStatuses current={data?.shipments.state} />
        </Form.Item>
      </Form>
      <Timeline mode="left">
        {data?.shipments.history.map((v, k) => (
          <Timeline.Item key={k} color={k==0?"green":"blue"} label={moment(v.created_at).format("DD.MM.YYYY HH:mm")}>
           {spr_decode[v.status]?.info}
          </Timeline.Item> )) }
      </Timeline>
    </Modal>
  );
};

const GET_SHIPMENT_HISTORY = gql`
  query ShipmentHistory($id: Int!) {
    shipments: shipments_by_pk(id: $id) {
      id
      state
      history: shipments_histories ( order_by: { created_at: asc }){
        status
        created_at
      }
    }
    shipment_statuses ( order_by: { position: asc }) {
      status
      position
      info
      description
    }
  }
`;

const UPDATE_SHIPMENT_STATUS = gql`
  mutation UpdateShipmentStatus($id: Int!, $objects: shipments_set_input!) {
    update_shipments(_set: $objects, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;


export default ShipmentStatusForm;
