import React from "react";
import { Button, Tooltip } from "antd";
import { ImportOutlined } from "@ant-design/icons";
import { useStoreon } from "storeon/react";
import FormBtn from "../shared/FormBtn";
import OrdersDeleteBtn from "./OrdersDeleteBtn";
import OrderExportBtn from "./OrderExportBtn";
import OrderMoveBtn from "./OrdersMove";
import { OrdersReorderForm } from "./OrdersReorder";
import { OrdersMapForm } from "./OrdersMapForm";
import { isSupplier, isAdmin, isShipper } from "../../auth/authHelper";;

export const Toolbar = (props) => {
  const { selection, dispatch } = useStoreon("selection");
  const { shipmentId } = props.mode === "all"? {} : selection;
  const { auth } = useStoreon("auth");

  if (selection.orderRows.length > 0) {
    return (
      <>
        <OrderExportBtn />
        <OrderMoveBtn disabled={isAdmin()||isShipper()?false:true}/>
        <OrdersDeleteBtn />
      </>
    );
  }
  return (
    <>
      <FormBtn component="order" action="add" mode={props.mode}/>
      <Tooltip title="Import orders" placement="bottomLeft">
      <Button
        type="link"
        onClick={() => dispatch("showForm", { component: "import_orders" })}
        icon={<ImportOutlined />}
      >
        Import
      </Button>
      </Tooltip>
      { shipmentId?
        <>
          <OrdersReorderForm numOrders={props.numOrders} />
          <OrdersMapForm numOrders={props.numOrders} />
        </>
        : <></>
      }
    </>
  );
};
