import React, { Component } from "react";
import TrucksTable from "./TrucksTable";

class Trucks extends Component {
  render() {
    return (
      <>
        <TrucksTable />
      </>
    );
  }
}

export default Trucks;
