import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { message, Form, Input, Modal, Button, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";

import Countries from "../../shared/Countries";
import GetGocardlessBank from "./GetGocardlessBank";

import { AUTH_TOKEN } from "../../../constant";
const ERROR_MESSAGE_DURATION = 5;

const GetInstitution = (props) => {
  const [form] = Form.useForm();
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { action, component, role, driver_index, truck_id } = ui.visibleForm;
  const [ countryId, setCountryId ] = useState(null);
  const [ bankId, setBankId ] = useState();
  const [ creating, setCreating ] = useState(false);
  const [ approveLink, setApproveLink ] = useState();

  const closeForm = (d) => {
    dispatch("hideForm");
    setCountryId (null);
    if (props.onClose) props.onClose();
  };

  const handleSubmit = () => {
    approveRequisition(approveLink);
    closeForm();
  };


/*
 {
  id: '2c80810d-8d60-4d85-a8db-919249857393',
  created: '2024-01-30T10:50:33.459521Z',
  redirect: 'http://www.yourwebpage.com',
  status: 'CR',
  institution_id: 'HANDELSBANKEN_CORPORATE_HANDNL2A',
  agreement: '',
  reference: '2c80810d-8d60-4d85-a8db-919249857393',
  accounts: [],
  link: 'https://bankaccountdata.gocardless.com/psd2/start/2c80810d-8d60-4d85-a8db-919249857393/HANDELSBANKEN_CORPORATE_HANDNL2A',
  ssn: null,
  account_selection: false,
  redirect_immediate: false
}

*/
  const createRequisition = () => {
    setCreating(true);
    const url = process.env.REACT_APP_SERVER_URL + "/auth/gocardless/create_link?"+
          new URLSearchParams({ id: bankId, bank_name: form.getFieldValue('bank_name') }).toString();

    fetch (url,
        {
          headers: {
           "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN),
            Accept: "application/json",
           "cache-control": "no-cache"
        },
        method: 'GET', 
    })
     .then(data => data.json())
     .then(data => {
        if (data.error) throw new Error(data.error);
        // alert(JSON.stringify(data));

         
        //closeForm();
        // leave form for next button ->approve
        message.success('Link created ...');
        setApproveLink (data.link);
     })
     .catch(err => {
        message.error(`Can't create requisition for ${bankId}: `+err, ERROR_MESSAGE_DURATION);
     })
     .finally(() => {
        setCreating(false);
     });
  };

  const approveRequisition = (approveLink) => {
     const newWindow = window.open(approveLink, '_blank', 'noopener,noreferrer')
     if (newWindow) newWindow.opener = null;
  };
 
  //////////////////////////////////
  return (
    <Modal
      title="Select Institution"
      visible={component === "select_institution"}
      maskClosable={false}
      closable={false}
      okText="Approve Requisition"
      onOk={handleSubmit}
      onCancel={closeForm}
      okButtonProps={{ disabled: !approveLink }}
      cancelButtonProps={{ disabled: false }}
    >
      <Form 
        layout="vertical" 
        form={form}
        disabled={approveLink}
      >
        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: "Please select country" }]}
        >
          <Countries alpha="alpha2" onChange={(v) => setCountryId(v)} />
        </Form.Item>

        <Form.Item
          label="Bank"
          name="bank"          
          rules={[{ required: true, message: "Please select bank" }]}       
        >
          <GetGocardlessBank country={countryId} value={bankId} onChange={(v) => setBankId(v)} forceDisable={approveLink} />
        </Form.Item>

        <Form.Item
          label="Bank name [ebury, raif]"
          name="bank_name"          
          rules={[{ required: true, message: "Please input bank name" }]}
        >
          <Input disabled={!bankId || approveLink} />
        </Form.Item>
      </Form>

      {/* ------- move to ouside?--------------------- */}      
       <Button disabled={!bankId || approveLink} loading={creating} onClick={createRequisition}>Create Requisition</Button>
      {/* <Button disabled={!approveLink} onClick={approveRequisition}>Approve Requisition</Button> */}
    </Modal>
  );
};

export default GetInstitution;
