import React from "react";
import { Tooltip, Popconfirm, Button } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";

const TrailerDisconnectBtn = props => {
  const [delete_trailer] = useMutation(DELETE_TRUCK_TRAILER, {
    refetchQueries: ["Trucks", "Trailers"]
  });
  return (
    <Popconfirm
      title="Are you sure to disconnect truck from trailer ?"
      onConfirm={e => {
        e.preventDefault();
        delete_trailer({ variables: { id: props.id } });
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
     <Tooltip placement="topLeft" title="Disconnect trailer">
      <Button type="danger" icon={<DeleteOutlined />} />
     </Tooltip>
    </Popconfirm>
  );
};

const DELETE_TRUCK_TRAILER = gql`
  mutation DeleteTruckTrailer($id: Int!) {
    delete_truck_trailer(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export default TrailerDisconnectBtn;
