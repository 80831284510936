import React from "react";

const OrderUnit = props => {
  const { record, mode, index } = props;

  //const sep = index>0?"; ":"";
  const sep = "";
  return (<span>{sep}{record.colli}/{record.cargo_type}/{record.packing_type?.type}</span>);

};

export default OrderUnit;
