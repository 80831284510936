import React from "react";
import { v4 as uuidv4 } from "uuid";
import { message, Popconfirm, Button, Tooltip } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";

const TransactionDeleteBtn = props => {
  const { id, link_id, type_transaction, ...otherProps } = props;

  const handleCompleted = (d) => {
   debugger;
  }

// in some cases link_id is null and we got error for t2
// generate fake uuid for correct transaction processing
// rewrite late?
 
  const [delete_transaction] = useMutation(DELETE_TRANSACTION, {
    skip: !id,
    refetchQueries: ["Transactions", "Accounts"],
    onCompleted: (d) => handleCompleted(d),
    onError: (e) => processError(e) 
  });

  // Foreign key violation. update or delete on table "users"
  // violates foreign key constraint "trucks_user_id_2_fkey" on table "trucks"
  const processError = (e) => {
        console.log("Got errors: ");
        e.graphQLErrors.map(error => {
         console.log(error);
         if (error.extensions.code === "constraint-violation") {
          console.log(`GOT IT! ${error.message}`);
	  if (error.message.includes("trucks"))
            message.error('Can`t delete this user because he is assigned as the driver of one of the trucks');
          else
            message.error('Can`t delete this user due constraint violation, contact your administrator');
         } else
          message.error('Server error, contact your administrator');
        });
  }

  if (!id ) return (<></>);

  const transaction_type_message = type_transaction == 'ADM'?"manual transfer": "local transaction";

  return (
    <Popconfirm
      title={"Are you sure delete "+transaction_type_message+"?"}
      onConfirm={e => {
        e.preventDefault();
        console.log("delete: "+JSON.stringify(props));
        // may be move `deselect` to onCompleted?
        props.onBeforeDelete(id, link_id);
        delete_transaction({ variables: { id: id, link_id: link_id||uuidv4() } });
      }}
      onCancel={e => {
        e.preventDefault();
      }}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip placement="left" mouseLeaveDelay="0" title={"Delete "+transaction_type_message} >
        <Button
           {...otherProps}
           type="link"
           danger
           icon={<DeleteOutlined />}
        />
      </Tooltip>
    </Popconfirm>
  );
};

const DELETE_TRANSACTION = gql`
mutation DeleteTransactions(
  $id: uuid!
  $link_id: uuid
 ) {
# _by_pk - problem with uuid, single or array
#  delete_p_transactions_by_pk (id: {_eq: $id}) { id }
 t1: delete_p_transactions (where: {id: {_eq: $id}}) {
   affected_rows
 }
 t2: delete_p_transactions(where: {id: {_eq: $link_id}}) {
   affected_rows
 }  
 t3: delete_p_transactions(
      where: { _or: [
       {link_id: {_eq: $id}},
       {link_id: {_eq: $link_id}}
      ]}
   ) {
   affected_rows
 }  
}
`;


export default TransactionDeleteBtn;
