// TODO:
// 1. ALL:
//   - remove currency ALL and make total text
//   - try to modify legend if currenct available
// 2. MIX: try to add currency name in legend or at sum
//

import React, { useState, useEffect } from "react";
import { Column, DualAxes } from "@ant-design/plots";
import { each, groupBy } from "@antv/util";
import { customStartEndFormat, dateFormat, pickerDateFormat } from "./ChartParams";

const month_idx = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

const get_top5_color = (top, obj) => {
  const pos = top.findIndex((a) => a.client_name == obj.client_name);
  switch (pos) {
    case 0:  return 'rgb(52,  186, 235)';
    case 1:  return 'rgb(52,  235, 113)';
    case 2:  return 'rgb(226, 235, 52)';
    case 3:  return 'rgb(212, 105, 78)';
    case 4:  return 'rgb(52,  235, 52)';
    default: return '#8805A8';
  }
}

const Chart = ({
  data: p_data,
  data_trend: data_trend_,
  params,
  onChange,
  value,
  ...otherProps
}) => {

  // or use Chart options
  const ref = React.useRef();
  ref.current = params;

  useEffect(() => {
    console.log("Chart.useEffect: fired");
  }, [params]);

  if (!p_data) return "no data ...";

  const data_ = p_data.data;
  const top_  = p_data.top;

  console.log("got data: ", data_.length);
  console.log("got top: ",  top_.length);

  // sum: parseFloat or use .crt as int?
  const top_index = top_.sort((a,b) => b.sum-a.sum).map((v) => v.client_name);
  console.log("got sorted top_index: ", top_index);

  const data_crt = [];
  const data_crt_top_other = []; // for xAxis text

debugger;
  const key = 'client_name';
  each(groupBy(data_, "step"), (values, k) => {
      console.log("tops.1: ", k, values);
      const values_top = values.filter((v) => top_index.includes(v.client_name));
      const values_top_other = values.filter((v) => !top_index.includes(v.client_name));

      const values_top_other_sum = values_top_other.reduce((a, b) => ({...a, 
            cnt: a.cnt + b.cnt,
            sum: a.sum + b.sum 
         }), { client_name: 'other', cnt: 0, sum: 0, step: k, details: values_top_other });
      console.log("tops.2: ", values_top);
      console.log("tops.2: ", values_top_other_sum);
      data_crt.splice(data_crt.length, 0, ...values_top, values_top_other_sum);
      data_crt_top_other.splice(data_crt_top_other.length, 0, ...values_top_other);
  });
  console.log("all prepares done: data_crt:", data_crt);

////////

  const data = data_crt
    .filter((v) => {
      // remove empty
      if (!params.is_fill_empty && v.sum === 0) return false;

      // skip filter for SINGLE
      return true;
    })
    .sort((a, b) => params.picker === "year" ? 
      month_idx.indexOf(a.step) - month_idx.indexOf(b.step)
      :
      parseInt(a.step, 10) - parseInt(b.step, 10)
    );
  console.log("got data: filted: ", data.length);

  //
  const data_trend =
    data_trend_ 
      ? data_trend_
          .filter((v) => {
            // remove empty
            if (!params.is_fill_empty && v.sum === 0) return false;
            // skip filter for SINGLE
            return true;
          })
          .sort((a, b) => params.picker === "year" ? 
            month_idx.indexOf(a.step) - month_idx.indexOf(b.step)
            :
            parseInt(a.step, 10) - parseInt(b.step, 10)
           )
      : data;
  console.log("data_trend", data_trend);

  //
  const extr = data.reduce(
    (accumulator, currentValue) => {
      const c = parseFloat(currentValue.sum, 10);
      // skip empty values
      if (c === 0) return accumulator;

      accumulator.min =
        !accumulator.min || accumulator.min > c ? c : accumulator.min;
      accumulator.max =
        !accumulator.max || accumulator.max < c ? c : accumulator.max;
      return accumulator;
    },
    { min: null, max: null }
  );
  console.log("extremums", extr);

  const c_data   = params.is_show_trend ? [data, data_trend] : data;
  const c_yField = params.is_show_trend ? ["sum", "sum"] : "sum";
  const c_color  = params.is_show_trend
      ? [(p) => get_top5_color(top_, p), (p) => get_top5_color(top_, p)]
      : (p) => get_top5_color(top_, p)

  const c_yAxis_ = {
    label: {
      formatter: (value) => {
        // return Math.floor(value / 1000) + "K";
        return value;
      }
    }
  };
  const c_yAxis = params.is_show_trend ? [c_yAxis_, c_yAxis_] : c_yAxis_;

  // let total = 0, total_arr = [];
  let total = [];
  ////////////////
  const annotations = [];

  // add values rounded to kilo on top of bars 
  !params.is_show_grouped &&
    each(groupBy(data, "step"), (values, k) => {
      //console.log("annotations.1: ", k, values);
      const value = values.reduce((a, b) => a + b.sum, 0);
      const value_cnt = values.reduce((a, b) => a + b.cnt, 0);
      const display_val =
        value >= 1000 ? parseFloat(value / 1000).toFixed(0) + "K" : parseFloat(value).toFixed(2);
      value &&
        annotations.push({
          type: "text",
          position: [k, value],
          content: `${value_cnt} - ${display_val} EUR`,
          style: {
            textAlign: "center",
            fontSize: 12,
            fill: "rgba(0,0,0,0.55)"
          },
          // put max columnt annon below ... in some cases max value not fittexd in canvas
          offsetY: extr.max && extr.max === value? (12+5) : -10,
          // debug: offsetX: +100
        });
    });

  // for xAxis label text
  each(groupBy(data, "client_name"), (values, k) => {
      // console.log("each.currency: k=", k, "values:", values);
      const value = values.reduce((a, b) => a + b.sum, 0);
      total[k] = total[k]||0 + value;
  });
  const grand_total = Object.keys(total).reduce((a, b, i) => a + total[b], 0);
  console.log('grand total:', grand_total);

  // for xAxis label text: other details
  const total_other = [];
  each(groupBy(data_crt_top_other, "client_name"), (values, k) => {
      // console.log("each.currency: k=", k, "values:", values);
      const value = values.reduce((a, b) => a + b.sum, 0);
      total_other[k] = total_other[k]||0 + value;
  });

  console.log("annotations: length=", annotations.length);
  // for trend c_data = [data, data_trend] ... use annotations for one data set only
  // const c_annotations = params.is_show_trend ? [annotations, annotations] : annotations;
  const c_annotations = params.is_show_trend ? [[], annotations] : annotations;

  console.log("annotations: ", JSON.stringify(annotations));
  ////////////////

  const chart_params = {
    isGroup: params.is_show_grouped,
    isStack: true,
    seriesField: "client_name",
    colorField: 'client_name',
    //color: (p) => get_top5_color(top_, p),
  };

  //const x_axis_text = params.picker.charAt(0).toUpperCase() + params.picker.slice(1) + ": " +
  const x_axis_text = "\nRange: " +
      customStartEndFormat(params, "from") + ", Total CRT per "+params.picker+ 
      ':\n'+Object.keys(total).reduce((prev, cur, idx) => prev + (idx>0?', ':'') + 
      cur +' '+  parseFloat(total[cur]).toFixed(2) + 
      ' EUR', '')+'\n'+
      'Other details: '+Object.keys(total_other).reduce((prev, cur, idx) => prev + (idx>0?', ':'') + 
      cur +' '+  parseFloat(total_other[cur]).toFixed(2) + 
      ' EUR', '')+'\n'+

      '\nGrand total: '+parseFloat(grand_total).toFixed(2) +' EUR';

// If we want to format currency
// Intl.NumberFormat("en").format();
// Intl.NumberFormat("en", {style: "currency", currency: "CNY", minimumFractionDigits: 2}).format();

   // 4 coma as sep: return idx == 0 ? currVal : prevVal + ', ' + currVal;

  const config = {
    data: c_data,
    xField: "step",
    yField: c_yField,
    ...chart_params,
//    color: c_color,

//    colorField: 'currency',
//    color: ({ currency }) => {
/*
    color: (o) => {
      switch (o.currency) {
        case 'EUR': return 'rgb(52, 186, 235)';
        case 'USD': return 'rgb(52, 235, 113)';
        case 'CNY': return 'rgb(226, 235, 52)';
        case 'AED': return 'rgb(212, 105, 78)';
      }
      return 'black';
    },
*/
    label: false && {
      content: (originData) => {
        return (
          // persents:
          // ((parseFloat(originData.sum) / extr.max) * 100).toFixed(1) + "%"
          parseFloat(originData.sum).toFixed(2)
        );
      },
      offset: -10
    },
    legend: { position: "top" },
    title: { text: "CRT chart", position: "center" },
    yAxis: c_yAxis,
    xAxis: {      
      title: { 
        text: x_axis_text, 
        spacing	: 30, 
      },
      label: {
        autoHide: true,
        autoRotate: false
      }
    },
    // geometryOptions: for DualAxes
    geometryOptions: [
      {
        geometry: "column",
        ...chart_params
      },
      {
        geometry: "line",
        ...chart_params,
        lineStyle: {
          lineWidth: 2,
/*
  shadowColor: 'grey',
  shadowBlur: 5,
  shadowOffsetX: 3,
  shadowOffsetY: 3,
*/
        },
        smooth: true
      }
    ],
    tooltip_1x: {
      formatter: (datum) => {
        // console.log("tooltip.formatter:", JSON.stringify(datum));

        const index = data.findIndex((d) => d.client_name == datum.client_name && d.sum == datum.sum && d.step == datum.step);
        const addon = index >= 0 ? `${data[index]?.cnt} - ` : '';

        return {
          name: datum.client_name,
          value: addon + (datum.sum === null? 0 : datum.sum).toFixed(2) + ' EUR',
        }
      }
    },

  // from 2.x version
  tooltip_2x: (
    d, 
    index, 
    data, 
    column, 
  ) => {
    console.log("tooltip.formatter:", data);
    return ({value: `AAA: ${column.y.value[index]} - ${column.y1.value[index]}`});
  },

    ////////////////////////////////////////
    tooltip: {
      showMarkers: false,
      enterable: true,
      domStyles: {
        'g2-tooltip': {
          'min-width': '250px',
          padding: 0,
          //border: '5px solid red',
          //
          position: 'absolute',
          visibility: 'visible',
          "background-color": 'rgb(255, 255, 255)',
          "box-shadow": 'rgb(174, 174, 174) 0px 0px 10px',
          "border-radius": '3px',
          color: 'rgb(89, 89, 89)', 
          "font-size": '12px',
          "line-height": '12px', 
          padding: '0px 12px',
          opacity: '0.95',
          "pointer-events": 'none',
         },
      },
      customContent: (title, items) => {
        const tooltip_list_start = '<ul class="g2-tooltip-list" style="margin: 0px; list-style-type: none; padding: 0px;">';
        //console.log(JSON.stringify(items));
        if (!items.length) return null;

        const i_data = items[0]?.data || {};

        // mappingData.shape => skip duplicated info for splines (trend)
        let shown_top = 0;
        const titleDom = `<div class="g2-tooltip-title" style="margin-bottom: 12px; margin-top: 12px;">${i_data.step}</div>`;
        const valuesDom = items.reduce((a, i) => (a + (i.mappingData.shape !== undefined || i.data.cnt === 0 ? '' : (shown_top++,`<li class="g2-tooltip-list-item" data-index="" style="list-style-type: none; padding: 0px; margin: 12px 0px;">
          <span class="g2-tooltip-marker" style="background: ${i.color}; width: 8px; height: 8px; border-radius: 50%; display: inline-block; margin-right: 8px;"></span>
          <span class="g2-tooltip-name">${i.data.client_name}</span>:
          <span class="g2-tooltip-value" style="display: inline-block; float: right; margin-left: 30px;">${i.data.cnt} - ${i.data.sum} EUR</span></li>`))), 
          tooltip_list_start)+'</ul>';

        // the "others" section should always be last
        const other_index = items.length-1;
        const other = items[other_index].data.details;          

        // skip zero info
        const otherDom = items[other_index].data.sum > 0 ? other.reduce((a, i) => (a+( i.cnt === 0 ? '' : `<li class="g2-tooltip-list-item" data-index="" style="list-style-type: none; padding: 0px; margin: 12px 0px;">
          <span class="g2-tooltip-marker" style="background: rgba(0, 0, 0, 0); width: 8px; height: 8px; border-radius: 50%; display: inline-block; margin-right: 8px;"></span>
          <span class="g2-tooltip-name">${i.client_name}</span>:
          <span class="g2-tooltip-value" style="display: inline-block; float: right; margin-left: 30px;">${i.cnt} - ${i.sum} EUR</span></li>`)), 
          '<hr size="1" style="border-bottom:1px #DDD;"/>'+tooltip_list_start)+'</ul>' : '';

        return ((shown_top || otherDom.length > 0)?`<div>${titleDom}${valuesDom}${otherDom}</div>`:null);
      },
    },

    ////////////////////////////////////////
    annotations: c_annotations,
    //interactions: [{ type: "element-selected" }, { type: "element-active" }],
    onReady: (plot) => {
      console.log("Char.onReady: fired");

      plot.on("axis-title:click", (event, ...args) => {
        console.log("axis-title:click ... reserved for future\n"+plot.options.xAxis.title.text);
      });
      plot.on("element:click", (event, chartElements, ...args) => {
        //...args
        console.log("click: begin");
        // console.log("click: event:", event);
        const data = event.data.data;
        console.log("click.target:", data.currency, data.step);
        console.log("picker:", ref.current.picker);
        if (
          data.step &&
          (ref.current.picker === "year" ||
            (ref.current.picker === "month" &&
              ref.current.sub_picker === "week"))
        ) {
          /*
 * move it to data.js
 *        
          console.log(
            "picker.2:",
            get_picker(),
            "sub_picker:",
            get_sub_picker(),
            "currency:",
            get_currency()
          );

          let step_next;
          switch (picker) {
            case "year":
              step_next = "month";
              break;
            case "month":
              step_next = "week";
              break;
            default:
              step_next = null;
          }
          console.log("step_next:", step_next);
*/
          if (onChange) onChange({ data: data });
        }
        /*
        console.log("click: got " + arguments.length + " args");
        for (var i = 0; i < arguments.length; i++) {
          console.log("args:", arguments[i]);
        }

        if (chartElements) {
          console.log("chartElements:", chartElements[0].label);
        }
        */
      });
    }
  };

  console.log("chart: config ready");
  console.log("config:", JSON.stringify(config));
  debugger;

  if (!params.is_show_trend)
    return (
      <>
{/*
        {params.currency} : {params.picker} : {params.sub_picker}
        config: {JSON.stringify(config)}
*/}
        <Column {...config} />
      </>
    );
    return (
      <>
{/*
        {params.currency} : {params.picker} : {params.sub_picker}
        config: {JSON.stringify(config)}
*/}
        <DualAxes {...config} />
      </>
    );

};
export default Chart;
