import React, { useState } from "react";
import { Row, Col } from "antd";
import { Layout } from 'antd';
import { useStoreon } from "storeon/react";
import OrdersTable from "../orders/OrdersTable";
import ShipmentListContainer from "../shipments/ShipmentListContainer";

const { Header, Footer, Sider, Content } = Layout;

const PlanningPage = () => {
  const { selection } = useStoreon("selection");
  const shipmentId = selection.shipmentId;
/*
  return (
    <Row>
      <Col span={4}>
        <ShipmentList />
      </Col>
      {shipmentId && (
        <Col span={20}>
          <OrdersTable />
        </Col>
      )}
    </Row>
  );
*/
  // <Sider theme="light" width={350} _className="sider-layout"><ShipmentListContainer /></Sider>
  return (
    <Layout>
      <Sider theme="light" width={350}><ShipmentListContainer /></Sider>
      <Layout>
        <Content>
         { shipmentId && <OrdersTable /> }
        </Content>
      </Layout>
    </Layout>
  );

};
export default PlanningPage;
